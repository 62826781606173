import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_LOCAL
  ? "http://localhost:4000"
  : "https://kkaada.com";
const api = axios.create({
  baseURL: `${apiUrl}/api`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = Cookies.get("jwt");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
