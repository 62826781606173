import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAddNewItem: false,
  showFilter: false,
  showForNewUserItem: false,
  showFilterUserAddingItemToTheirWardrobe: false,
};

const popupButtonSlice = createSlice({
  name: "popupButton",
  initialState,
  reducers: {
    setShowAddNewItem: (state, action) => {
      state.showAddNewItem = action.payload;
    },
    setShowFilter: (state, action) => {
      state.showFilter = action.payload;
    },
    setShowForNewUserItem: (state, action) => {
      state.showForNewUserItem = action.payload;
    },
    setShowFilterUserAddingItemToTheirWardrobe: (state, action) => {
      state.showFilterUserAddingItemToTheirWardrobe = action.payload;
    },
  },
});

export const {
  setShowAddNewItem,
  setShowFilter,
  setShowForNewUserItem,
  setShowFilterUserAddingItemToTheirWardrobe,
} = popupButtonSlice.actions;

export default popupButtonSlice.reducer;
