import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchUsers = createAsyncThunk("login/fetchUsers", async () => {
  try {
    const res = await api.get("/users");
    return res.data;
  } catch (err) {
    throw err;
  }
});

const loginSlice = createSlice({
  name: "login",
  initialState: {
    usersList: [],
    email: "",
    password: "",
    errorMessage: "",
    resetPopup: false,
  },
  reducers: {
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setResetPopup: (state, action) => {
      state.resetPopup = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    loginActionRejected: (state, action) => {
      console.log("Rejected payload:", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.usersList = action.payload;
    });
  },
});

export const {
  setEmail,
  setPassword,
  setResetPopup,
  setErrorMessage,
  resetErrorMessage,
  setUsersList,
  loginActionRejected,
} = loginSlice.actions;

export default loginSlice.reducer;
