import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setPassword,
  setRePassword,
  setErrorMessage,
  resetErrorMessage,
  fetchUsers,
} from "./resetSlice";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import api from "../../api/api";

let emailCheck = false;

function Reset() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const usersList = useSelector((state) => state.signup.usersList);
  const email = useSelector((state) => state.signup.email);
  const password = useSelector((state) => state.signup.password);
  const rePassword = useSelector((state) => state.signup.rePassword);
  const errorMessage = useSelector((state) => state.signup.errorMessage);

  const isPasswordValid = { valid: false };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // * Email validation with regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      dispatch(setErrorMessage("Please enter a valid email address."));
      return;
    }

    // * Remove spaces and dots and "+" sigen (if "gamil.com" after "@") before the "@" sign
    const emailParts = email.split("@");
    let username = "";
    if (emailParts[1] === "gmail.com") {
      const usernameParts = emailParts[0].split("+");
      username = usernameParts[0].replace(/\s/g, "").replace(/\./g, "");
    } else {
      username = emailParts[0].replace(/\s/g, "");
    }

    // * Lowercase the email address
    const formattedEmail = username + "@" + emailParts[1];
    const formattedEmailLowerCase = formattedEmail.toLowerCase();

    // * Check if email already exists in the system
    const existingUser = usersList.find(
      (user) => user.email === formattedEmailLowerCase
    );
    if (existingUser) {
      emailCheck = true;
    } else {
      dispatch(setErrorMessage("This email address is not registered."));
      emailCheck = false;
    }

    // * Check if password is valid
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
    if (!passwordRegex.test(password)) {
      dispatch(
        setErrorMessage(
          "Password must contain at least one letter, one symbol, and be at least 6 characters long."
        )
      );
      isPasswordValid.valid = false;
      return;
    }

    // * Check if passwords match
    if (password !== rePassword) {
      dispatch(setErrorMessage("Passwords don't match."));
      isPasswordValid.valid = false;
      return;
    } else isPasswordValid.valid = true;

    // * Update new password
    if (isPasswordValid.valid && emailCheck) {
      try {
        await api.post("/user/forgot-password", {
          email: formattedEmailLowerCase,
          password: password,
        });
      } catch (error) {
        console.error("Failed to update new password:", error);
      }

      // * Clear form and error message
      dispatch(setEmail(""));
      dispatch(setPassword(""));
      dispatch(setRePassword(""));
      dispatch(resetErrorMessage());
      alert(
        "Password reset must be confirmed using the link sent to the email"
      );
      return;
    } else {
      alert(
        "One or more of the details you entered is invalid, please try again."
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => dispatch(setEmail(e.target.value))}
        />
        <Form.Text className="text-muted">
          Confirmation for resetting the password will be sent to the email
          address
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formPassword">
        <Form.Label>Choose new password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => dispatch(setPassword(e.target.value))}
        />
        <Form.Text className="text-muted">
          One letter, one symbol, and at least 6 characters.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formRePassword">
        <Form.Label>Repeat new password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={rePassword}
          onChange={(e) => dispatch(setRePassword(e.target.value))}
        />
        {password !== rePassword && (
          <p style={{ color: "red" }}>Passwords don't match.</p>
        )}
      </Form.Group>

      <hr />
      <Button style={{ float: "right" }} variant="success">
        {/* Must be replaced when using a production version */}
        {/* <Button style={{ float: "right" }} variant="success" type="submit"> */}
        Submit
      </Button>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </Form>
  );
}

export default Reset;
