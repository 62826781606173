import "./App.css";
import DisplayContainer from "./utils/DisplayContainer/DisplayContainer";
import PopupDisplay from "./utils/PopupDisplay/PopupDisplay";
import SetUser from "./utils/SetUser/SetUser";
import Admin from "./utils/Admin/Admin";
import Page404 from "./utils/Page404";
import { Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import Wardrobe from "./comps/Wardrobe/Wardrobe";
import StoreWardrobe from "./comps/StoreWardrobe/StoreWardrobe";
import PopupButton from "./comps/header/PopupButton/PopupButton";
import { FilterProvider } from "./comps/FilterContext/FilterContext";

const jwtCookie = Cookies.get("jwt");
let decodedToken = null;
if (jwtCookie) {
  try {
    decodedToken = jwtDecode(jwtCookie);
  } catch (error) {
    console.error("JWT verification failed:", error);
  }
}
let isAdmin = decodedToken ? decodedToken.account === "admin" : false;
let isLogedin = decodedToken
  ? decodedToken.account === "free" || decodedToken.account === "paid"
  : false;

function App() {
  return (
    <div className="App">
      <span style={{ display: "none" }}>
        <FilterProvider>
          <PopupButton />
          <StoreWardrobe userEmail={decodedToken?.user} isAdmin={isAdmin} />
          <Wardrobe userEmail={decodedToken?.user} />
        </FilterProvider>
      </span>
      <Routes>
        {isLogedin ? (
          <Route path="/user" element={<PopupDisplay />} />
        ) : (
          <Route path="/user" element={<Page404 />} />
        )}
        {isAdmin ? (
          ((<Route path="/user" element={<Admin />} />),
          (<Route path="/" element={<Admin />} />))
        ) : (
          <Route path="/user" element={<PopupDisplay />} />
        )}
        {isAdmin ? (
          <Route path="/admin" element={<Admin />} />
        ) : (
          <Route path="/admin" element={<Page404 />} />
        )}
        <Route path="/" element={<DisplayContainer />} />
        <Route path="/api/confirm/*" element={<DisplayContainer />} />
        <Route path="/api/user/resetPass/*" element={<DisplayContainer />} />
        <Route path="/setUser" element={<SetUser />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;
