import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { resetTargetValues, resetColorValues } from "../../Filter/filterSlice";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import "./loginButton.css";
import Filter from "../../Filter/Filter2";
import SignInUpContainer from "../../../utils/SignInUpContainer/SignInUpContainer";
import { NavLink } from "react-router-dom";
import {
  setDoneUserMessageEnd,
  setShowFilter,
  setShowLogin,
  setSignup,
  setStylesCopy,
  setSeasonsCopy,
  setLoaderVar,
} from "./loginButtonSlice";
import {
  copyToCheckedList,
  copyToStylesList,
  copyToSeasonsList,
} from "../../Filter/filter2Slice";
import { setResetPopup } from "../../Login/loginSlice";
import {
  setOutfitsListPush,
  updatOutfitsList,
  setCounter,
  setItemToShow,
  setOutfitToShow,
  setMaxAttempts,
  setImagesLoaded,
} from "../../../utils/DisplayContainer/displayContainerSlice";
import axios from "axios";
import dayjs from "dayjs";

// * For converting birth date to age
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function LoginButton(props) {
  const dispatch = useDispatch();
  const showFilter = useSelector((state) => state.loginButton.showFilter);
  const showLogin = useSelector((state) => state.loginButton.showLogin);
  const signup = useSelector((state) => state.loginButton.signup);
  const resetPopup = useSelector((state) => state.login.resetPopup);
  const handleClose = () => {
    dispatch(copyToSeasonsList());
    dispatch(copyToStylesList());
    dispatch(copyToCheckedList());
    dispatch(setShowFilter(false));
  };
  const handleShow = () => {
    dispatch(copyToSeasonsList());
    dispatch(copyToStylesList());
    dispatch(copyToCheckedList());
    dispatch(setShowFilter(true));
  };
  const handleCloseLogin = () => {
    dispatch(setResetPopup(false));
    dispatch(setShowLogin(false));
  };
  const handleShowLogin = () => dispatch(setShowLogin(true));

  const currentUserFetched = useSelector(
    (state) => state.displayContainer.fetchUser
  );

  const sqlStyle2 = useSelector((state) => state.displayContainer.sqlStyle);
  let sqlUser = useSelector((state) => state.displayContainer.sqlUser);
  let sqlSeason2 = useSelector((state) => state.displayContainer.sqlSeason);

  // * For outfits list
  const maxAttempts = useSelector(
    (state) => state.displayContainer.maxAttempts
  );
  // * For the limit of attempts
  let limitAttempts = 0;
  const outfitToShow = useSelector(
    (state) => state.displayContainer.outfitToShow
  );
  const outfitsList = useSelector(
    (state) => state.displayContainer.outfitsList
  );
  const counter = useSelector((state) => state.displayContainer.counter);
  const itemToShow = useSelector((state) => state.displayContainer.itemToShow);
  const displayList = useSelector(
    (state) => state.displayContainer.displayList
  );
  useEffect(() => {
    try {
      {
        const foundItem = displayList.find(
          (item) =>
            JSON.stringify(item.scenario) ===
            JSON.stringify(Object.keys(outfitsList?.[itemToShow]))
        );

        if (!foundItem) {
          console.log("Item not found");
        }
      }
    } catch (error) {}
  }, [outfitsList]);
  const nextFun = async () => {
    try {
      if (outfitToShow.length === 0) {
        alert("No outfit To Show");
        return;
      }

      if (counter - itemToShow < 1) {
        if (!maxAttempts) {
          const randomIndex = Math.floor(Math.random() * outfitToShow.length);
          const randomValue = outfitToShow[randomIndex];
          const nonNullKeys = {};
          for (const key in randomValue) {
            if (key !== "accnumber" && randomValue[key] !== null) {
              nonNullKeys[key] = randomValue[key];
            }
          }
          const nonNullValues = Object.values(nonNullKeys);
          if (
            outfitsList?.length === 0 ||
            !outfitsList?.some((outfit) => {
              const outfitValues = Object.values(outfit);
              return nonNullValues.every((value) =>
                outfitValues.includes(value)
              );
            })
          ) {
            if (
              JSON.stringify(nonNullKeys) !== "[{}]" &&
              JSON.stringify(nonNullKeys) !== "{}"
            ) {
              dispatch(setOutfitsListPush(nonNullKeys));
              dispatch(setCounter(counter + 1));
              dispatch(setItemToShow(itemToShow + 1));
              limitAttempts = 0;
            }
          } else {
            try {
              // * In case there are not enough variations
              await nextFun();
            } catch (error) {
              dispatch(setMaxAttempts(true));
            }
          }
        }
      } else {
        dispatch(setItemToShow(itemToShow + 1));
      }
    } catch (error) {
      console.error("Error in nextFun: ", error);
    }
  };

  function prevFun() {
    if (itemToShow > 0) {
      dispatch(setItemToShow(itemToShow - 1));
    }
  }
  // * For sending clothing items associated with the user to be generated into outfits
  const allItemsUser = useSelector(
    (state) => state.displayContainer.allItemsUser
  );
  // * For loader animation
  let loader = false;
  const loaderVar = useSelector((state) => state.loginButton.loaderVar);
  if (
    outfitsList?.length === 0 ||
    maxAttempts ||
    Cookies.get("sessionCookie") === "false"
  ) {
    if (
      props.logedin &&
      (allItemsUser?.accessories.length !== 0 ||
        allItemsUser?.lower.length !== 0 ||
        allItemsUser?.shoes.length !== 0 ||
        allItemsUser?.tops.length !== 0 ||
        allItemsUser?.whole.length !== 0)
    ) {
      loader = true;
    }
  } else loader = false;
  // * For sending Styles & Seasons associated with user's filter
  let Styles = useSelector((state) => state.filter2.Styles);
  let Seasons = useSelector((state) => state.filter2.Seasons);
  async function moreOutfits(email, noOutfitsListPushVar) {
    const noOutfitsListPush = noOutfitsListPushVar;
    const userEmail = email;
    const sqlSeason = Seasons ? Seasons : sqlSeason2;
    const sqlStyle = Styles ? Styles : sqlStyle2;
    // const allFirstResults = [];
    // * Essential for mysql requests not to collide because the requests at the same time
    let checkIfDone = false;
    const allResults = [];
    const user =
      currentUserFetched.appearance.estimated.gender !== ""
        ? {
            skin: sqlUser.skin,
            bodyFat: sqlUser.bodyFat,
            waist: sqlUser.waist,
            hips: sqlUser.hips,
            height: sqlUser.height,
            season: sqlSeason,
            style: sqlStyle,
            items: allItemsUser,
          }
        : {
            email: userEmail,
            skin: sqlUser.skin,
            bust: parseInt(sqlUser.bust),
            waist: parseInt(sqlUser.waist),
            hips: parseInt(sqlUser.hips),
            height: parseInt(sqlUser.height),
            wrist: parseInt(sqlUser.wrist || 0),
            weight: parseInt(sqlUser.weight || 0),
            gender: sqlUser.gender,
            age: parseInt(dayjs().from(dayjs(sqlUser.age), true).split(" ")[0]),
            season: sqlSeason,
            style: sqlStyle,
            items: allItemsUser,
          };

    let firstResponse = "";
    try {
      if (currentUserFetched.appearance.estimated.gender !== "") {
        if (!process.env.REACT_APP_LOCAL) {
          firstResponse = await axios.post(`/python/estifirst`, user);
        } else
          firstResponse = await axios.post(
            "http://127.0.0.1:8000/estifirst",
            user
          );
      } else if (!process.env.REACT_APP_LOCAL) {
        firstResponse = await axios.post(`/python/first`, user);
      } else
        firstResponse = await axios.post("http://127.0.0.1:8000/first", user);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    if (!firstResponse.data) {
      alert(
        "There was an error, please try again later (if the error persists, contact customer support)."
      );
    } else if (Array.isArray(firstResponse.data)) {
      firstResponse.data.forEach((item) => {
        if (Array.isArray(item)) {
          const result = item;
          // allFirstResults.push(...result);
          allResults.push(...result);
        }
      });
      // dispatch(setOutfitToShow(allFirstResults));
      dispatch(setMaxAttempts(false));
      checkIfDone = true;
    }

    let restResponse = "";
    if (checkIfDone) {
      try {
        if (currentUserFetched.appearance.estimated.gender !== "") {
          if (!process.env.REACT_APP_LOCAL) {
            restResponse = await axios.post(`/python/estirest`, user);
          } else
            restResponse = await axios.post(
              "http://127.0.0.1:8000/estirest",
              user
            );
        } else if (!process.env.REACT_APP_LOCAL) {
          restResponse = await axios.post(`/python/rest`, user);
        } else
          restResponse = await axios.post("http://127.0.0.1:8000/rest", user);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      if (!restResponse.data && checkIfDone) {
        alert(
          "There was an error, please try again later (if the error persists, contact customer support)."
        );
      } else if (Array.isArray(restResponse.data)) {
        restResponse.data.forEach((item) => {
          if (Array.isArray(item)) {
            const result = item;
            allResults.push(...result);
          }
        });
        dispatch(setOutfitToShow(allResults));
        if (!maxAttempts) {
          if (limitAttempts > 49) {
            limitAttempts = 0;
            return;
          }
          const randomIndex = Math.floor(Math.random() * allResults.length);
          const randomValue = allResults[randomIndex];
          const nonNullKeys = {};
          for (const key in randomValue) {
            if (key !== "accnumber" && randomValue[key] !== null) {
              nonNullKeys[key] = randomValue[key];
            }
          }
          const nonNullValues = Object.values(nonNullKeys);
          if (
            outfitsList?.length === 0 ||
            !outfitsList?.some((outfit) => {
              const outfitValues = Object.values(outfit);
              return nonNullValues.every((value) =>
                outfitValues.includes(value)
              );
            })
          ) {
            if (
              JSON.stringify(nonNullKeys) !== "[{}]" &&
              JSON.stringify(nonNullKeys) !== "{}"
            ) {
              if (!noOutfitsListPush) {
                dispatch(setOutfitsListPush(nonNullKeys));
                dispatch(setCounter(counter + 1));
              } else {
                dispatch(
                  updatOutfitsList({
                    index: outfitsList?.length - 1,
                    value: nonNullKeys,
                  })
                );
              }
            }
          } else {
            try {
              moreOutfits(email, noOutfitsListPush);
              limitAttempts++;
              if (limitAttempts > 49) {
                return;
              }
            } catch (error) {
              // * In case there are not enough variations
              alert(
                "All outfit combinations have run out. Adding more items or changing the user settings and/or events and styles may help"
              );
            }
          }
        }
      }
    }
  }
  if (maxAttempts) {
    limitAttempts = 0;
    moreOutfits(currentUserFetched?.email, false);
  }
  // * For new user's filter req
  const SeasonsCopy = useSelector((state) => state.loginButton.SeasonsCopy);
  const StylesCopy = useSelector((state) => state.loginButton.StylesCopy);
  useEffect(() => {
    // * SeasonsCopy and StylesCopy are essential for not generating outfits with each return to the page
    if (Seasons !== SeasonsCopy || Styles !== StylesCopy) {
      dispatch(setSeasonsCopy(Seasons));
      dispatch(setStylesCopy(Styles));
      dispatch(setMaxAttempts(true));
      moreOutfits(currentUserFetched?.email, true);
    }
  }, [Seasons, Styles]);

  const doneUserMessageEnd = useSelector(
    (state) => state.loginButton.doneUserMessageEnd
  );
  const [doneUserMessage, setdoneUserMessage] = useState(false);
  const user = () => {
    try {
      if (!doneUserMessage) {
        setdoneUserMessage("@" + currentUserFetched?.email.split("@")[0]);
      }
      return "@" + currentUserFetched?.email.split("@")[0];
    } catch (error) {
      console.error("Failed to find user:", error);
      return "User";
    }
  };
  if (
    !doneUserMessageEnd &&
    doneUserMessage === "@" + currentUserFetched?.email.split("@")[0]
  ) {
    if (
      !doneUserMessageEnd &&
      (allItemsUser?.accessories.length !== 0 ||
        allItemsUser?.lower.length !== 0 ||
        allItemsUser?.shoes.length !== 0 ||
        allItemsUser?.tops.length !== 0 ||
        allItemsUser?.whole.length !== 0)
    ) {
      dispatch(setDoneUserMessageEnd(true));
    } else {
      alert(
        "There are no outfits available to display.\nTo receive outfit suggestions, items must be added to the personal wardrobe"
      );
      dispatch(setDoneUserMessageEnd(true));
    }
  }
  let btnCont =
    !props.logedin || !currentUserFetched ? (
      <span className="font-size ">Login / Sign-up</span>
    ) : (
      <span className="font-size">
        <span className="responsiveDisplayNone">{user()} &nbsp;</span>
        <i className="fa-solid fa-user-gear"></i>
      </span>
    );

  // * Transition between Login/Signup pages
  const handleSignMeUp = () => dispatch(setSignup(true));
  const handleLogMeIn = () => {
    dispatch(setResetPopup(false));
    dispatch(setSignup(false));
  };

  function resetFilter() {
    dispatch(resetTargetValues());
    dispatch(resetColorValues());
  }

  const [showDemo, setShowDemo] = useState(false);
  function handleDemo() {
    dispatch(setShowDemo(!showDemo));
    // alert("Email address: demo@kkaada.com \n Password: demo");
  }

  // * For Resete popup responsiveness
  let reseteProp = "";
  if (resetPopup) {
    reseteProp = "resetResponsive";
  }

  return (
    <div className="btn-pos btnResponsive">
      {/* // * Animation for loader */}
      {loader ? (
        <div
          className="spinner-container"
          style={{ position: "fixed", top: "90vh", left: "50vw" }}
        >
          <div className="spinner">
            <div className="spinner">
              <div className="spinner">
                <div className="spinner">
                  <div className="spinner">
                    <div className="spinner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* // * Popup for "Login button" */}
      <Modal
        show={showLogin}
        onHide={handleCloseLogin}
        dialogClassName={reseteProp}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {signup ? "Signup" : resetPopup ? "Reset Password" : "Login"}
          </Modal.Title>
          <div>
            <br />
            {signup ? (
              <h6
                style={{
                  position: "absolute",
                  left: "0",
                  fontSize: "0.75rem",
                  padding: "5px",
                  paddingLeft: "17px",
                }}
                className="text-muted"
              >
                Already have an account?{" "}
                <Alert.Link onClick={handleLogMeIn}>Login</Alert.Link>
              </h6>
            ) : resetPopup ? (
              <h6
                style={{
                  position: "absolute",
                  left: "0",
                  fontSize: "0.75rem",
                  padding: "5px",
                  paddingLeft: "17px",
                }}
                className="text-muted"
              >
                Already have an account?{" "}
                <Alert.Link onClick={handleLogMeIn}>Login</Alert.Link>
              </h6>
            ) : (
              <>
                <h6
                  style={{
                    position: "absolute",
                    left: "0",
                    fontSize: "0.75rem",
                    padding: "5px",
                    paddingLeft: "17px",
                  }}
                  className="text-muted"
                >
                  Don't have an account?{" "}
                  <Alert.Link onClick={handleSignMeUp}>Signup</Alert.Link>
                </h6>
                <Alert.Link className="demo-txt" onClick={handleDemo}>
                  Demo account login
                </Alert.Link>
                <Modal
                  show={showDemo}
                  onHide={handleDemo}
                  dialogClassName={reseteProp}
                >
                  <Modal.Header closeButton>
                    <h4>Demo user info</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <b>Email address:</b> demo@kkaada.com
                    <br />
                    <b>Password:</b> demo
                  </Modal.Body>
                </Modal>
              </>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <SignInUpContainer signup={signup} />
        </Modal.Body>
      </Modal>
      {props.logedin ? (
        <NavLink to="/user">
          {" "}
          <Button
            size="lg"
            variant="primary"
            style={{ float: "right" }}
            onClick={resetFilter}
            className="userIcon"
          >
            {btnCont}
          </Button>
        </NavLink>
      ) : (
        <Button
          size="lg"
          variant="primary"
          style={{ float: "right", top: "285px" }}
          onClick={handleShowLogin}
          className="userIcon2"
        >
          {btnCont}
        </Button>
      )}{" "}
      {props.logedin ? (
        <Button
          size="lg"
          onClick={itemToShow > 0 ? prevFun : () => false}
          variant={itemToShow > 0 ? "success" : "secondary"}
          className="font-size2"
        >
          Previous
        </Button>
      ) : (
        ""
      )}{" "}
      {props.logedin ? (
        <Button
          onClick={
            !loader
              ? () => {
                  // First, dispatch to show loader
                  dispatch(setImagesLoaded(0));
                  dispatch(setLoaderVar(true));

                  // Delay the nextFun execution slightly to let React update the loader state first
                  setTimeout(() => {
                    nextFun();
                  }, 0);
                }
              : () => false
          }
          size="lg"
          variant={!loader ? "success" : "secondary"}
          className="font-size2"
        >
          Next
        </Button>
      ) : (
        ""
      )}{" "}
      {props.logedin ? (
        <Button
          size="lg"
          variant="light"
          className="font-size2 marginLeft marginResponsive"
          style={{ border: "black solid 1px" }}
          onClick={handleShow}
        >
          Events and Styles
        </Button>
      ) : (
        ""
      )}
      {props.logedin ? (
        <Offcanvas
          show={showFilter}
          onHide={handleClose}
          scroll={true}
          backdrop={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Events and Styles</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Filter />
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        ""
      )}
    </div>
  );
}

export default LoginButton;
