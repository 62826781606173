import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "../comps/Filter/filterSlice";
import filter2Reducer from "../comps/Filter/filter2Slice";
import filterContextReducer from "../comps/FilterContext/filterContextSlice";
import addItemToStoreWardrobeReducer from "../comps/Filter/addItemToStoreWardrobeSlice";
import loginButtonReducer from "../comps/header/LoginButton/loginButtonSlice";
import popupButtonReducer from "../comps/header/PopupButton/popupButtonSlice";
import loginReducer from "../comps/Login/loginSlice";
import signupReducer from "../comps/Signup/signupSlice";
import storeWardrobeReducer from "../comps/StoreWardrobe/storeWardrobeSlice";
import wardrobeReducer from "../comps/Wardrobe/wardrobeSlice";
import adminReducer from "../utils/Admin/adminSlice";
import displayContainerReducer from "../utils/DisplayContainer/displayContainerSlice";
import popupDisplayReducer from "../utils/PopupDisplay/popupDisplaySlice";

export const store = configureStore({
  reducer: {
    filter: filterReducer,
    filter2: filter2Reducer,
    filterContext: filterContextReducer,
    addItemToStoreWardrobe: addItemToStoreWardrobeReducer,
    loginButton: loginButtonReducer,
    popupButton: popupButtonReducer,
    login: loginReducer,
    signup: signupReducer,
    storeWardrobe: storeWardrobeReducer,
    wardrobe: wardrobeReducer,
    admin: adminReducer,
    displayContainer: displayContainerReducer,
    popupDisplay: popupDisplayReducer,
  },
});
