import { useSelector, useDispatch } from "react-redux";
import {
  updateTargetValue,
  updateColorValue,
  updateFileValue,
} from "./addItemToStoreWardrobeSlice";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// * Unusable at the moment ------------------------------------------------------------
const initialValuesForItemTarget = [
  { name: "upperBody", value: false, title: "Upper body" },
  { name: "lowerBody", value: false, title: "Lower body" },
  { name: "wholeBody", value: false, title: "Whole body" },
  { name: "shoes", value: false, title: "Shoes" },
  { name: "accessories", value: false, title: "Accessories" },
];

const initialValuesForItemColor = [
  { name: "black", value: false, title: "Black" },
  { name: "white", value: false, title: "White" },
  { name: "red", value: false, title: "Red" },
  { name: "blue", value: false, title: "Blue" },
  { name: "green", value: false, title: "Green" },
  { name: "yellow", value: false, title: "Yellow" },
  { name: "gray", value: false, title: "Gray" },
  { name: "beige", value: false, title: "Beige" },
  { name: "pink", value: false, title: "Pink" },
  { name: "purple", value: false, title: "Purple" },
  { name: "azure", value: false, title: "Azure" },
  { name: "turquoise", value: false, title: "Turquoise" },
  { name: "orange", value: false, title: "Orange" },
  { name: "brown", value: false, title: "Brown" },
  { name: "gold", value: false, title: "Gold" },
  { name: "silver", value: false, title: "Silver" },
  { name: "nude", value: false, title: "Nude" },
  { name: "transparent", value: false, title: "Transparent" },
];

function AddItemToStoreWardrobe() {
  const dispatch = useDispatch();

  const targetValues = useSelector(
    (state) => state.addItemToStoreWardrobe.targetValues
  );
  const colorValues = useSelector(
    (state) => state.addItemToStoreWardrobe.colorValues
  );

  const handleTargetInputChange = (e) => {
    const { name, checked } = e.target;
    dispatch(updateTargetValue({ name, checked }));
  };

  const handleColorInputChange = (e) => {
    const { name, checked } = e.target;
    dispatch(updateColorValue({ name, checked }));
  };

  const targetMap = initialValuesForItemTarget.map((item) => (
    <div key={"target:" + item.title} className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={targetValues.find((obj) => obj.name === item.name).value}
        name={item.name}
        id={item.name}
        onChange={handleTargetInputChange}
      />
      <label className="form-check-label" htmlFor={item.name}>
        {item.title}
      </label>
    </div>
  ));

  const colorMap = initialValuesForItemColor.map((item, index) => (
    <div key={"color:" + item.title} className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={colorValues.find((obj) => obj.name === item.name).value}
        name={item.name}
        // * בגלל ששמות צבעים הן מילים שמורות, יש הוספה של מספר ל- ID
        id={item.name + index}
        onChange={handleColorInputChange}
      />
      <label className="form-check-label" htmlFor={item.name + index}>
        {item.title}
      </label>
    </div>
  ));

  const file = useSelector((state) => state.addItemToStoreWardrobe.file);
  const handleUpload = (e) => {
    const selectedFile = URL.createObjectURL(e.target.files[0]);
    dispatch(updateFileValue(selectedFile));
  };

  function handleSubmit(event) {
    event.preventDefault();
    let newTargetList = [];
    targetValues.forEach((target) => {
      if (target.value) {
        newTargetList.push(target.name);
      }
    });
    let newColorList = [];
    colorValues.forEach((color) => {
      if (color.value) {
        newColorList.push(color.name);
      }
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <h6>Item target</h6>
      {targetMap} <br />
      <hr />
      <br />
      <h6>Item color</h6>
      {colorMap}
      <br />
      <hr />
      <br />
      <div>
        <div
          style={{
            display: "flex",
            padding: "50px",
            justifyContent: " center",
            flexDirection: "column",
          }}
        >
          {file === "" ? (
            <i
              style={{ alignSelf: "center", fontSize: "3rem" }}
              className="fa-regular fa-file-image"
            ></i>
          ) : (
            <img alt="" src={file} />
          )}
        </div>{" "}
        <div>
          <Form.Group controlId="formFileForItem" className="mb-3">
            <div style={{ padding: "0 36px" }}>
              <Form.Label>Upload item image</Form.Label>
              <Form.Control type="file" onChange={handleUpload} />
            </div>
          </Form.Group>
        </div>
      </div>
      <div>
        <br />
        <Button variant="success" type="submit">
          Save changes
        </Button>
      </div>
    </form>
  );
}
export default AddItemToStoreWardrobe;
