import { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { setFilterParams } from "./filterContextSlice";

const FilterContext = createContext();

export function FilterProvider({ children }) {
  const filterParams = useSelector((state) => state.filterContext.filterParams);

  return (
    <FilterContext.Provider value={{ filterParams, setFilterParams }}>
      {children}
    </FilterContext.Provider>
  );
}

export function useFilter() {
  return useContext(FilterContext);
}
