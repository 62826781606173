import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkedList: [
    { id: "workSchool", name: "Work/School day", checked: false },
    { id: "ceremonial", name: "Ceremonial event", checked: false },
    { id: "out", name: "Going/Night out", checked: false },
  ],
  stylesList: [
    { id: "casual", name: "Casual", checked: false },
    { id: "hipster", name: "Hipster", checked: false },
    { id: "rock", name: "Rock", checked: false },
    { id: "elegant", name: "Elegant", checked: false },
    { id: "sporty", name: "Sporty", checked: false },
  ],
  seasonsList: [
    { id: "summer", name: "Summer", checked: false },
    { id: "spring", name: "Spring", checked: false },
    { id: "autumn", name: "Autumn", checked: false },
    { id: "winter", name: "Winter", checked: false },
  ],
  checkedListCopy: [
    { id: "workSchool", name: "Work/School day", checked: false },
    { id: "ceremonial", name: "Ceremonial event", checked: false },
    { id: "out", name: "Going/Night out", checked: false },
  ],
  stylesListCopy: [
    { id: "casual", name: "Casual", checked: false },
    { id: "hipster", name: "Hipster", checked: false },
    { id: "rock", name: "Rock", checked: false },
    { id: "elegant", name: "Elegant", checked: false },
    { id: "sporty", name: "Sporty", checked: false },
  ],
  seasonsListCopy: [
    { id: "summer", name: "Summer", checked: false },
    { id: "spring", name: "Spring", checked: false },
    { id: "autumn", name: "Autumn", checked: false },
    { id: "winter", name: "Winter", checked: false },
  ],
  errorMessage: "",
  Seasons: "",
  Styles: "",
};

export const filter2Slice = createSlice({
  name: "filter2",
  initialState,
  reducers: {
    setCheckedList: (state, action) => {
      state.checkedList = action.payload;
    },
    setStylesList: (state, action) => {
      state.stylesList = action.payload;
    },
    setSeasonsList: (state, action) => {
      state.seasonsList = action.payload;
    },
    setCheckedListCopy: (state) => {
      state.checkedListCopy = state.checkedList.map((item) => ({
        ...item,
      }));
    },
    setStylesListCopy: (state) => {
      state.stylesListCopy = state.stylesList.map((item) => ({
        ...item,
      }));
    },
    setSeasonsListCopy: (state) => {
      state.seasonsListCopy = state.seasonsList.map((item) => ({
        ...item,
      }));
    },
    copyToCheckedList: (state) => {
      state.checkedList = state.checkedListCopy.map((item) => ({
        ...item,
      }));
    },
    copyToStylesList: (state) => {
      state.stylesList = state.stylesListCopy.map((item) => ({
        ...item,
      }));
    },
    copyToSeasonsList: (state) => {
      state.seasonsList = state.seasonsListCopy.map((item) => ({
        ...item,
      }));
    },
    resetCheckedList: (state) => {
      state.checkedList = [...initialState.checkedList];
    },
    resetStylesList: (state) => {
      state.stylesList = [...initialState.stylesList];
    },
    resetSeasonsList: (state) => {
      state.seasonsList = [...initialState.seasonsList];
    },
    resetCheckedListCopy: (state) => {
      state.checkedListCopy = [...initialState.checkedListCopy];
    },
    resetStylesListCopy: (state) => {
      state.stylesListCopy = [...initialState.stylesListCopy];
    },
    resetSeasonsListCopy: (state) => {
      state.seasonsListCopy = [...initialState.seasonsListCopy];
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setSeasons: (state, action) => {
      state.Seasons = action.payload;
    },
    setStyles: (state, action) => {
      state.Styles = action.payload;
    },
  },
});

export const {
  setCheckedList,
  setStylesList,
  setSeasonsList,
  setCheckedListCopy,
  setStylesListCopy,
  setSeasonsListCopy,
  copyToCheckedList,
  copyToStylesList,
  copyToSeasonsList,
  resetCheckedList,
  resetStylesList,
  resetSeasonsList,
  resetCheckedListCopy,
  resetStylesListCopy,
  resetSeasonsListCopy,
  setErrorMessage,
  setSeasons,
  setStyles,
} = filter2Slice.actions;
export default filter2Slice.reducer;
