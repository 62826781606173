import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

const initialState = {
  showLogout: false,
  showUsers: false,
  showNewUser: false,
  email: "",
  password: "",
  userHeight: "137",
  userBust: "56",
  userWaist: "38",
  userHips: "67",
  userSkin: "1",
  account: "free",
  errorMessage: "",
  showTrashcan: false,
  usersList: [],
  changes: [],
};

export const fetchUsers = createAsyncThunk("admin/fetchUsers", async () => {
  try {
    const res = await api.get("/users");
    return res.data;
  } catch (err) {
    throw err;
  }
});

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setShowLogout: (state, action) => {
      state.showLogout = action.payload;
    },
    setShowUsers: (state, action) => {
      state.showUsers = action.payload;
    },
    setShowNewUser: (state, action) => {
      state.showNewUser = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setUserHeight: (state, action) => {
      state.userHeight = action.payload;
    },
    setUserBust: (state, action) => {
      state.userBust = action.payload;
    },
    setUserWaist: (state, action) => {
      state.userWaist = action.payload;
    },
    setUserHips: (state, action) => {
      state.userHips = action.payload;
    },
    setUserSkin: (state, action) => {
      state.userSkin = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setShowTrashcan: (state, action) => {
      state.showTrashcan = action.payload;
    },
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },
    setChanges: (state, action) => {
      state.changes = action.payload;
    },
    addChange: (state, action) => {
      state.changes.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.usersList = action.payload;
    });
  },
});

export const {
  setShowLogout,
  setShowUsers,
  setShowNewUser,
  setEmail,
  setPassword,
  setUserHeight,
  setUserBust,
  setUserWaist,
  setUserHips,
  setUserSkin,
  setAccount,
  setErrorMessage,
  setShowTrashcan,
  setUsersList,
  addChange,
  setChanges,
} = adminSlice.actions;

export default adminSlice.reducer;
