import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";

function Page404() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        scale: "0.5",
      }}
    >
      <span>
        <NavLink to="/">
          <Button
            style={{
              position: "absolute",
              top: "-20vh",
              scale: "4",
              left: "50%",
              width: "15%",
            }}
            variant="outline-dark"
          >
            GO BACK &nbsp;
            <i className="fa-solid fa-home"></i>
          </Button>
        </NavLink>
      </span>
      <img
        alt="404"
        src="https://upload.wikimedia.org/wikipedia/commons/2/28/404UnicornNotFound.svg"
      />
    </div>
  );
}
export default Page404;
