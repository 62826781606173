import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  file: "",
  fileName: "",
  showLookAlike: false,
  showTrashcan: false,
  showTrashcanLookAlike: false,
  mainImageRenderView: false,
  userItemsArray: [],
  copyUserItemsArray: [],
  addedItem: 0,
};

const wardrobeSlice = createSlice({
  name: "wardrobe",
  initialState,
  reducers: {
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setShowLookAlike: (state, action) => {
      state.showLookAlike = action.payload;
    },
    setShowTrashcan: (state, action) => {
      state.showTrashcan = action.payload;
    },
    setshowTrashcanLookAlike: (state, action) => {
      state.showTrashcanLookAlike = action.payload;
    },
    setUserItemsArray: (state, action) => {
      state.userItemsArray = action.payload;
    },
    setCopyUserItemsArray: (state, action) => {
      state.copyUserItemsArray = action.payload;
    },
    setAddedItem: (state, action) => {
      state.addedItem = action.payload;
    },
    setMainImageRenderView: (state, action) => {
      state.mainImageRenderView = action.payload;
    },
  },
});

export const {
  setShowTrashcan,
  setShowLookAlike,
  setshowTrashcanLookAlike,
  setFile,
  setFileName,
  setUserItemsArray,
  setCopyUserItemsArray,
  setAddedItem,
  setMainImageRenderView,
} = wardrobeSlice.actions;

export default wardrobeSlice.reducer;
