import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setPassword,
  setErrorMessage,
  resetErrorMessage,
  setResetPopup,
  fetchUsers,
  loginActionRejected,
} from "./loginSlice";
import SignInUpContainer from "../../utils/SignInUpContainer/SignInUpContainer";
import { setLogedin } from "../../utils/DisplayContainer/displayContainerSlice";
import { setShowLogin } from "../header/LoginButton/loginButtonSlice";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { loginAction } from "./saveCookie";

function Login() {
  const dispatch = useDispatch();

  const handleCloseLogin = () => dispatch(setShowLogin(false));

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const usersList = useSelector((state) => state.login.usersList);
  const email = useSelector((state) => state.login.email);
  const password = useSelector((state) => state.login.password);
  const errorMessage = useSelector((state) => state.login.errorMessage);
  const resetPopup = useSelector((state) => state.login.resetPopup);

  const handleLogin = () => {
    dispatch(setLogedin(true));
    handleCloseLogin();
    window.location = "/";
  };

  const handleResetPass = () => {
    dispatch(setResetPopup(true));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // * Email validation with regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      dispatch(setErrorMessage("Please enter a valid email address."));
      return;
    }

    // * Remove spaces and dots(if "gamil.com" after "@") before the "@" sign
    const emailParts = email.split("@");
    let username = "";
    if (emailParts[1] === "gmail.com") {
      username = emailParts[0].replace(/\s/g, "").replace(/\./g, "");
    } else {
      username = emailParts[0].replace(/\s/g, "");
    }

    // * Lowercase the email address
    const formattedEmail = username + "@" + emailParts[1];
    const formattedEmailLowerCase = formattedEmail.toLowerCase();

    const formData = {
      email: "",
      password: password,
    };

    // * Check if email already exists in the system
    const existingUser = usersList.find(
      (user) => user.email === formattedEmailLowerCase && user.activated
    );

    if (existingUser) {
      formData.email = formattedEmailLowerCase;
      formData.password = password;
      JSON.stringify(formData);
      dispatch(resetErrorMessage());
      const checkUser = async () => {
        try {
          await dispatch(loginAction(formData))
            .unwrap()
            .catch((error) => {
              dispatch(loginActionRejected(error));
            });
          if (Cookies.get("jwt")) {
            handleLogin();
          } else {
            dispatch(
              setErrorMessage(
                "One or more of the details you entered is incorrect"
              )
            );
          }
        } catch (error) {
          console.error("Failed to authenticate user: ", error);
        }
      };
      checkUser();
      return;
    } else {
      dispatch(setErrorMessage("User not found"));
    }
  };

  if (resetPopup) {
    return <SignInUpContainer reset={resetPopup} />;
  } else
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => dispatch(setEmail(e.target.value))}
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => dispatch(setPassword(e.target.value))}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formResetPassword">
          Forgot password?{" "}
          <Alert.Link onClick={handleResetPass}>Click to Reset</Alert.Link>
        </Form.Group>
        <hr></hr>
        <Button style={{ float: "right" }} variant="success" type="submit">
          Submit
        </Button>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </Form>
    );
}

export default Login;
