import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setTargetValue,
  setColorValue,
  // setFile,
  setShowLookAlike,
  setShowLookAlike2,
  setShowTrashcan,
} from "./storeWardrobeSlice";
import {
  setItemNames,
  fetchItemsJson,
} from "../../utils/DisplayContainer/displayContainerSlice";
import { setAddedItem } from "../Wardrobe/wardrobeSlice";
import { resetTargetValues, resetColorValues } from "../Filter/filterSlice";
import { setItemToHandle } from "../../utils/PopupDisplay/popupDisplaySlice";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import DisplayBox from "../DisplayBox/DisplayBox";
import "./storeWardrobe.css";
import api from "../../api/api";

// import itemPath2 from "../../assets/images/items/161.0.jpg";

import axios from "axios";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_LOCAL
  ? "http://localhost:4000"
  : process.env.REACT_APP_STORAGE;
// * For converting birth date to age
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function StoreWardrobe(props) {
  const dispatch = useDispatch();
  const targetValues = useSelector((state) => state.storeWardrobe.targetValues);
  const colorValues = useSelector((state) => state.storeWardrobe.colorValues);
  const itemNames = useSelector((state) => state.displayContainer.itemNames);
  // * Necessary to filter the display of filtered items
  const copyItemNames = useSelector(
    (state) => state.displayContainer.copyItemNames
  );

  const handleTargetInputChange = (e) => {
    const { name, checked } = e.target;
    dispatch(setTargetValue({ name, checked }));
  };

  const handleColorInputChange = (e) => {
    const { name, checked } = e.target;
    dispatch(setColorValue({ name, checked }));
  };

  const targetMap = targetValues.map((item) => (
    <div key={"target:" + item.title} className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={item.value}
        name={item.name}
        id={item.name}
        onChange={handleTargetInputChange}
      />
      <label className="form-check-label" htmlFor={item.name}>
        {item.title}
      </label>
    </div>
  ));

  const colorMap = colorValues.map((item, index) => (
    <div key={"color:" + item.title} className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={item.value}
        name={item.name}
        // * Because color names are reserved words, a number is added to the ID
        id={item.name + index}
        onChange={handleColorInputChange}
      />
      <label className="form-check-label" htmlFor={item.name + index}>
        {item.title}
      </label>
    </div>
  ));

  // * Saving an uploaded image as a variable
  // const file = useSelector((state) => state.storeWardrobe.file);
  // const handleUpload = (e) => {
  //   dispatch(setFile(URL.createObjectURL(e.target.files[0])));
  // };

  // * For a look-alike item image
  const [fitItem, setFitItem] = useState(false);
  function getOutfits2(itemToHandle) {
    handleCloseForLookAlike2();
    togglePopup();
    handleClose();
    handleShow(itemToHandle);
    getOutfits(itemToHandle, true);
  }
  const showLookAlike = useSelector(
    (state) => state.storeWardrobe.showLookAlike
  );
  const showLookAlike2 = useSelector(
    (state) => state.storeWardrobe.showLookAlike2
  );
  const handleCloseForLookAlike = () => {
    dispatch(setShowLookAlike(false));
  };
  const handleShowForLookAlike = () => {
    dispatch(setShowLookAlike(true));
  };
  const handleCloseForLookAlike2 = () => {
    dispatch(setShowLookAlike2(false));
    setFitItem(false);
  };
  const handleShowForLookAlike2 = (item) => {
    dispatch(setShowLookAlike2(true));
    setFitItem(item);
  };

  // * For displaying a relevant image after clicking on an item to save/delete
  const itemToHandle = useSelector((state) => state.popupDisplay.itemToHandle);

  // * For deleting an item
  const showTrashcan = useSelector((state) => state.storeWardrobe.showTrashcan);
  const handleClose = () => {
    dispatch(setShowTrashcan(false));
  };

  // * For saving an item in a user virtual wardrobe
  let addedItem = useSelector((state) => state.wardrobe.addedItem);
  const handleAddToWardrobe = async () => {
    const foundItem = extractedItems.find(
      (item) =>
        item.name ===
        itemToHandle.substring(itemToHandle.lastIndexOf("/") + 1).split(".")[0]
    );
    const newItemToAdd = foundItem
      ? {
          path: itemToHandle,
          name: foundItem.name,
          target: foundItem.target,
          colors: foundItem.colors,
          lookAlike: { filePath: "", name: "", mainImage: false },
        }
      : null;
    let userEmail = props.userEmail;
    if (newItemToAdd) {
      addedItem++;
      try {
        const res = await api.post("updateUserWardrobe", {
          newItemToAdd,
          userEmail,
        });
        if (res.data.itemExists) {
          alert("Item already exists in the wardrobe");
          handleClose();
          return;
        }
        dispatch(setAddedItem(addedItem));
        dispatch(resetTargetValues());
        dispatch(resetColorValues());
        alert("Item successfully added!");
        // * For the user and the list of outfits on the home page to be updated according to the user's change
        Cookies.set("sessionCookie", false);
        handleClose();
      } catch (error) {
        console.error("Error adding item to wardrobe:", error);
        alert("Error adding item to wardrobe. Please try again later.");
      }
    }
  };

  const handleShow = (imagePath) => {
    dispatch(setItemToHandle(imagePath));
    dispatch(setShowTrashcan(true));
  };

  function handleSubmit(event) {
    event.preventDefault();
    let newTargetList = [];
    targetValues.forEach((target) => {
      if (target.value) {
        newTargetList.push(target.name);
      }
    });
    let newColorList = [];
    colorValues.forEach((color) => {
      if (color.value) {
        newColorList.push(color.name);
      }
    });
  }

  // * For extracting item details from json items
  const { allItemsStoreString, status } = useSelector(
    (state) => state.displayContainer
  );
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchItemsJson());
    }
  }, [status, dispatch]);
  const [storeItems, setStoreitems] = useState([]);
  const itemStrings = allItemsStoreString.split("),");
  const colorArray = [
    "white",
    "black",
    "red",
    "blue",
    "green",
    "yellow",
    "gray",
    "beige",
    "pink",
    "purple",
    "azure",
    "turquoise",
    "orange",
    "brown",
    "gold",
    "silver",
    "nude",
    "transparent",
  ];
  const extractedItems = itemStrings.map((itemString) => {
    const itemValues = itemString.replace("(", "").replace(`"`, "").split(",");
    if (storeItems.length === 0 && itemValues.length > 1) {
      setStoreitems((prevOutfits) => [...prevOutfits, itemValues]);
    }
    const colorString = itemValues[32];
    const foundColors = colorArray.filter((color) =>
      colorString?.includes(color)
    );
    let [name, target] = itemValues;
    if (itemValues[13] !== "''") {
      target = "'accessories'";
    }
    if (foundColors) {
      return {
        name,
        target,
        colors: foundColors,
      };
    }
  });

  // * Saving the display, for a user wardrobe, according to the filter
  const filterParams = useSelector((state) => state.filterContext.filterParams);
  useEffect(() => {
    if (Cookies.get("storeFilter")) {
      let Filter = JSON.parse(Cookies.get("storeFilter"));
      if (Filter.colors.length > 0 || Filter.targets.length > 0) {
        let filteredItems = [];
        const uniqueItems = new Set();
        extractedItems.forEach((item) => {
          const colorMatch =
            Filter.colors.length === 0 ||
            Filter.colors.some((color) => item.colors.includes(color));

          const targetMatch =
            Filter.targets.length === 0 || Filter.targets.includes(item.target);

          if (
            (Filter.colors.length > 0 &&
              Filter.targets.length > 0 &&
              colorMatch &&
              targetMatch) ||
            (Filter.colors.length > 0 &&
              Filter.targets.length === 0 &&
              colorMatch) ||
            (Filter.colors.length === 0 &&
              Filter.targets.length > 0 &&
              targetMatch)
          ) {
            if (!uniqueItems.has(item)) {
              filteredItems.push(item.name + ".0.jpg");
              uniqueItems.add(item);
            }
          }
        });
        dispatch(setItemNames(filteredItems));
      } else dispatch(setItemNames(copyItemNames));
    } else dispatch(setItemNames(copyItemNames));
  }, [filterParams]);

  // * For creating a display of clothing items from the store to add to the user's wardrobe
  const APP_API_URL = "https://kkaada.com";
  function renderDisplayBoxes() {
    const displayBoxes = [];
    const itemsPerRow = 4;
    const numRows = Math.ceil(itemNames.length / itemsPerRow);

    for (let i = 0; i < numRows; i++) {
      const row = [];
      let Number = 0;
      for (let j = i * itemsPerRow; j < itemNames.length; j++) {
        let imagePath;
        try {
          imagePath = `${apiUrl}/images/${itemNames[j]}`;
        } catch (error) {
          console.log(error);
        }
        row.push(
          <div
            style={{ maxWidth: "25%" }}
            className="d-flex flex-row"
            key={`row-${i}-item-${j}`}
          >
            <Button
              variant="outline-success"
              style={{
                position: "absolute",
                fontSize: "50%",
                marginTop: "2px",
                width: "0.5rem",
                zIndex: "1",
              }}
              className="responsiveDisplayNone"
              onClick={() => handleShow(imagePath)}
            >
              <i
                className="fa-solid fa-plus"
                style={{
                  margin: "0px 0px 0px 1px",
                  fontSize: "1rem",
                  justifyContent: "center",
                  display: "flex",
                }}
              ></i>
            </Button>
            <DisplayBox
              image={imagePath}
              hover="img2"
              handleShowForLookAlike={() => handleShow(imagePath)}
            />
          </div>
        );
        Number++;
        if (Number === itemsPerRow) {
          break;
        }
      }

      if (Number !== itemsPerRow) {
        for (let k = 0; k < itemsPerRow - Number; k++) {
          row.push(
            <div className="empty-placeholder" key={`empty-${i}-${k}`}></div>
          );
        }
      }

      displayBoxes.push(
        <div className="d-flex flex-row" key={`row-${i}`}>
          {row}
        </div>
      );
    }

    return displayBoxes;
  }

  useEffect(() => {
    renderDisplayBoxes();
  }, [itemNames]);

  // * For sending an item from the store to be generated to an outfit
  function findMatchingValue(Y, Z) {
    for (let arr of Z) {
      if (arr[0] === Y) {
        return arr;
      }
    }
  }
  // * For a user wardrobe item compatibility check feature
  const wardrobeIconPath = require(`../../assets/images/others/wardrobe.png`);
  const currentUserFetched = useSelector(
    (state) => state.displayContainer.fetchUser
  );
  const [maxAttempts, setMaxAttempts] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const displayList = useSelector(
    (state) => state.displayContainer.displayList2
  );
  const [effect, setEffect] = useState(0);
  const [outfitToShow, setOutfitToShow] = useState(null);
  const [outfitsList, setOutfitsListPush] = useState([]);
  const [counter, setCounter] = useState(0);
  const [itemToShow, setItemToShow] = useState(-1);
  const togglePopup = () => setIsOpen(!isOpen);
  const [column1With2Boxes, setColumn1With2Boxes] = useState(null);
  const [column2With4Boxes, setColumn2With4Boxes] = useState(null);
  const [column3With4Boxes, setColumn3With4Boxes] = useState(null);
  const [column4With4Boxes, setColumn4With4Boxes] = useState(null);
  // * For sending clothing items associated with the user to be generated into outfits
  const allItemsUser = useSelector(
    (state) => state.displayContainer.allItemsUser
  );
  let sqlUser = useSelector((state) => state.displayContainer.sqlUser);
  // * For the limit of attempts
  let limitAttempts = 0;
  useEffect(() => {
    try {
      let foundItem = displayList.find(
        (item) =>
          JSON.stringify(item.scenario) ===
          JSON.stringify(Object.keys(outfitsList?.[itemToShow] ?? {}))
      );

      if (foundItem) {
        const resultKeys = Object.keys(foundItem.result);

        const generateColumnContent = async (keys) => {
          return Promise.all(
            keys.map(async (key, index) => {
              const classs = foundItem.result[key][0];
              const imageKey = foundItem.result[key][1];

              try {
                if (outfitsList && outfitsList[itemToShow]) {
                  let fileName = outfitsList[itemToShow][imageKey];

                  if (fileName) {
                    let imagePathResponse;
                    try {
                      // * If user marked "Choose a look-alike image for this clothing item" rubric
                      imagePathResponse = await api.get(
                        `updateUserWardrobe/lookalikefilepath/${currentUserFetched?.email}/${fileName}`
                      );
                      // * Necessary because there's a delay in receiving the updated value from the database when the view has to show the item after changes
                      await new Promise((resolve) => setTimeout(resolve, 10));
                      imagePathResponse = await api.get(
                        `updateUserWardrobe/lookalikefilepath/${currentUserFetched?.email}/${fileName}`
                      );
                    } catch (error) {
                      console.error("Error fetching image path:", error);
                    }

                    return (
                      <div className="d-flex flex-row" key={index}>
                        <DisplayBox
                          classs={classs}
                          hover="img2"
                          image={
                            imagePathResponse.data.mainImage
                              ? `${apiUrl}/uploads${
                                  imagePathResponse.data.filePath.split(
                                    "upload"
                                  )[1]
                                }`
                              : `${apiUrl}/images/${fileName}.0.jpg`
                          }
                          handleShowForLookAlike={() =>
                            handleShowForLookAlike2(
                              imagePathResponse.data.mainImage
                                ? `${apiUrl}/uploads${
                                    imagePathResponse.data.filePath.split(
                                      "upload"
                                    )[1]
                                  }`
                                : `${apiUrl}/images/${fileName}.0.jpg`
                            )
                          }
                        />
                      </div>
                    );
                  }
                }
              } catch (error) {
                console.log("Error fetching file path:", error);
              }
            })
          );
        };

        generateColumnContent(resultKeys.slice(0, 2)).then((content) =>
          setColumn1With2Boxes(content)
        );
        generateColumnContent(resultKeys.slice(2, 6)).then((content) =>
          setColumn2With4Boxes(content)
        );
        generateColumnContent(resultKeys.slice(6, 10)).then((content) =>
          setColumn3With4Boxes(content)
        );
        generateColumnContent(resultKeys.slice(10, 14)).then((content) =>
          setColumn4With4Boxes(content)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [effect]);

  async function getOutfits(itemToHandle, newList) {
    let fixedItem = itemToHandle
      .substring(itemToHandle.lastIndexOf("/") + 1)
      .split(".")[0];
    fixedItem = findMatchingValue(fixedItem, storeItems);
    let OK = true;
    const allResults = [];
    const user = {
      skin: sqlUser.skin,
      items: allItemsUser,
      fixedItem: fixedItem,
    };

    let firstResponse = "";
    try {
      if (!process.env.REACT_APP_LOCAL) {
        firstResponse = await axios.post(`/python/fixeditems`, user);
      } else
        firstResponse = await axios.post(
          "http://127.0.0.1:8000/fixeditems",
          user
        );
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    if (!firstResponse.data) {
      alert(
        "There was an error, please try again later (if the error persists, contact customer support)."
      );
      OK = false;
    } else if (
      Array.isArray(firstResponse.data) &&
      firstResponse.data.length > 0
    ) {
      firstResponse.data.forEach((item) => {
        if (Array.isArray(item)) {
          const result = item;
          allResults.push(...result);
        }
      });
      setMaxAttempts(false);
      setOutfitToShow(allResults);
      if (!maxAttempts) {
        if (limitAttempts > 49) {
          limitAttempts = 0;
          return;
        }
        const randomIndex = Math.floor(Math.random() * allResults.length);
        const randomValue = allResults[randomIndex];
        const nonNullKeys = {};
        for (const key in randomValue) {
          if (key !== "accnumber" && randomValue[key] !== null) {
            nonNullKeys[key] = randomValue[key];
          }
        }
        const nonNullValues = Object.values(nonNullKeys);
        if (
          outfitsList?.length === 0 ||
          !outfitsList?.some((outfit) => {
            const outfitValues = Object.values(outfit);
            return nonNullValues.every((value) => outfitValues.includes(value));
          })
        ) {
          if (
            JSON.stringify(nonNullKeys) !== "[{}]" &&
            JSON.stringify(nonNullKeys) !== "{}"
          ) {
            if (newList) {
              setOutfitsListPush([nonNullKeys]);
              setCounter(1);
              setItemToShow(0);
              setEffect(effect + 1);
            } else {
              setOutfitsListPush((prevOutfits) => [
                ...prevOutfits,
                nonNullKeys,
              ]);
              setCounter(counter + 1);
              setItemToShow(itemToShow + 1);
              setEffect(effect + 1);
            }
          }
        } else {
          try {
            getOutfits(itemToHandle, newList);
            limitAttempts++;
            if (limitAttempts > 49) {
              return;
            }
          } catch (error) {
            // * In case there are not enough variations
            alert(
              "All outfit combinations have run out. Adding more items or changing the user settings and/or events and styles may help"
            );
          }
        }
      }
    } else OK = false;
    if (OK) {
      setIsOpen(true);
    } else alert("No matches found for your current wardrobe");
  }
  if (maxAttempts) {
    limitAttempts = 0;
    getOutfits(itemToHandle, false);
  }

  const nextFun = () => {
    if (counter - itemToShow <= 1) {
      if (!maxAttempts) {
        const randomIndex = Math.floor(Math.random() * outfitToShow.length);
        const randomValue = outfitToShow[randomIndex];
        const nonNullKeys = {};
        for (const key in randomValue) {
          if (key !== "accnumber" && randomValue[key] !== null) {
            nonNullKeys[key] = randomValue[key];
          }
        }
        const nonNullValues = Object.values(nonNullKeys);
        if (
          outfitsList?.length === 0 ||
          !outfitsList?.some((outfit) => {
            const outfitValues = Object.values(outfit);
            return nonNullValues.every((value) => outfitValues.includes(value));
          })
        ) {
          if (
            JSON.stringify(nonNullKeys) !== "[{}]" &&
            JSON.stringify(nonNullKeys) !== "{}"
          ) {
            setOutfitsListPush((prevOutfits) => [...prevOutfits, nonNullKeys]);
            setCounter(counter + 1);
            setItemToShow(itemToShow + 1);
            limitAttempts = 0;
          }
        } else {
          try {
            nextFun();
          } catch (error) {
            // * In case there are not enough variations
            setMaxAttempts(true);
          }
        }
      }
    } else setItemToShow(itemToShow + 1);
    setEffect(effect + 1);
  };
  function prevFun() {
    if (itemToShow > 0) {
      setItemToShow(itemToShow - 1);
      setEffect(effect + 1);
    }
  }
  useEffect(() => {
    if (!isOpen) {
      setOutfitToShow(null);
      setOutfitsListPush([]);
      setCounter(0);
      setItemToShow(-1);
    }
  }, [isOpen]);

  return (
    <div>
      {/* // * popup for adding/removing an item from the wardrobe */}
      {props.add ? (
        <>
          <Modal
            show={showTrashcan}
            onHide={handleClose}
            dialogClassName="modalResponsive"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add the item to your wardrobe?</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "contents" }}>
              <img
                alt="Item to be added"
                style={{ alignSelf: "center", width: "95%" }}
                src={itemToHandle}
              />
            </Modal.Body>
            <Modal.Footer
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="primary"
                onClick={() => getOutfits(itemToHandle, false)}
              >
                Wardrobe fit
                <img
                  alt="Wardrobe-icon"
                  src={wardrobeIconPath}
                  style={{ padding: "0 1px 0 8px" }}
                />
                ?
              </Button>
              <span style={{ display: "flex", gap: "10px" }}>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleAddToWardrobe}>
                  Add
                </Button>
              </span>
            </Modal.Footer>
          </Modal>
          {/* View item compatibility for a user's wardrobe */}
          <Modal
            show={isOpen}
            onHide={togglePopup}
            dialogClassName="modalResponsive"
          >
            <Modal.Header closeButton>
              <Button
                onClick={prevFun}
                variant={itemToShow > 0 ? "success" : "secondary"}
              >
                Previous
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                variant="success"
                onClick={nextFun}
              >
                Next
              </Button>
            </Modal.Header>
            <Modal.Body style={{ display: "flex" }}>
              <div className="col-3 no-margin-no-padding">
                <div>{column1With2Boxes}</div>
              </div>
              <div className="col col-3 no-margin-no-padding">
                <div>{column2With4Boxes}</div>
              </div>
              <div className="col col-3 no-margin-no-padding">
                <div>{column3With4Boxes}</div>
              </div>
              <div className="col col-3 no-margin-no-padding">
                <div>{column4With4Boxes}</div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <Modal
            show={showTrashcan}
            onHide={handleClose}
            dialogClassName="modalResponsive"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete the item from your wardrobe?</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "contents" }}>
              <img
                alt="Item to be deleted"
                style={{ alignSelf: "center", width: "95%" }}
                // src={itemPath2}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleClose}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {/* // * popup for item details */}
      <Modal
        show={showLookAlike}
        onHide={handleCloseForLookAlike}
        backdrop="static"
        keyboard={false}
        dialogClassName="modalResponsive2"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Item details</Modal.Title>
            <div>
              <br />
              <h6
                style={{
                  position: "absolute",
                  left: "0",
                  fontSize: "0.75rem",
                  padding: "5px",
                  paddingLeft: "17px",
                }}
                className="text-muted"
              >
                Change item characterization
              </h6>
            </div>
          </Modal.Header>
          <Modal.Body>
            <h6>Item target</h6>
            {targetMap} <br />
            <hr />
            <br />
            <h6>Item color</h6>
            {colorMap}
            <br />
            <hr />
            <br />
            <div>
              <div
                style={{
                  display: "flex",
                  padding: "50px",
                  justifyContent: " center",
                  flexDirection: "column",
                }}
              >
                {
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {/* <img alt="item image" src={file} /> */}
                  </div>
                }{" "}
                <Form.Group controlId="formFile" className="mb-3">
                  <div style={{ padding: "0 36px" }}>
                    <Form.Label>Change item image</Form.Label>
                    <Form.Control
                      type="file"
                      // onChange={handleUpload}
                      className="inputResponsive"
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseForLookAlike}>
              Cancel
            </Button>
            <Button variant="success" type="submit">
              Save changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={showLookAlike2}
        onHide={handleCloseForLookAlike2}
        dialogClassName="modalResponsive2"
      >
        <Modal.Header closeButton />
        <Modal.Body style={{ display: "contents" }}>
          <img
            alt="Item checked for compatibility"
            style={{ alignSelf: "center", width: "95%" }}
            src={fitItem}
          />
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button variant="primary" onClick={() => getOutfits2(fitItem)}>
            Wardrobe fit
            <img
              alt="Wardrobe-icon"
              src={wardrobeIconPath}
              style={{ padding: "0 1px 0 8px" }}
            />
            ?
          </Button>
          <span style={{ display: "flex", gap: "10px" }}>
            <Button variant="secondary" onClick={handleCloseForLookAlike2}>
              Cancel
            </Button>
            <Button variant="success" onClick={handleAddToWardrobe}>
              Add
            </Button>
          </span>
        </Modal.Footer>
      </Modal>
      {props.isAdmin ? (
        <span style={{ display: "flex" }}>
          <div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
          </div>
          <div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
          </div>
          <div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
          </div>
          <div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
            <div className="d-flex flex-row">
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                }}
                className="responsiveDisplayNone"
                onClick={handleShow}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                // image={itemPath2}
                hover="img2"
                handleShowForLookAlike={handleShowForLookAlike}
              />
            </div>
          </div>
        </span>
      ) : (
        // * if user is not an admin and wants to add an item to his wardrobe */}
        <>{renderDisplayBoxes()}</>
      )}
    </div>
  );
}

export default StoreWardrobe;
