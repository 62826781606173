import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  targetValues: [
    { name: "upperBody", value: false, title: "Upper body" },
    { name: "lowerBody", value: false, title: "Lower body" },
    { name: "wholeBody", value: false, title: "Whole body" },
    { name: "shoes", value: false, title: "Shoes" },
    { name: "accessories", value: false, title: "Accessories" },
  ],
  colorValues: [
    { name: "black", value: false, title: "Black" },
    { name: "white", value: false, title: "White" },
    { name: "red", value: false, title: "Red" },
    { name: "blue", value: false, title: "Blue" },
    { name: "green", value: false, title: "Green" },
    { name: "yellow", value: false, title: "Yellow" },
    { name: "gray", value: false, title: "Gray" },
    { name: "beige", value: false, title: "Beige" },
    { name: "pink", value: false, title: "Pink" },
    { name: "purple", value: false, title: "Purple" },
    { name: "azure", value: false, title: "Azure" },
    { name: "turquoise", value: false, title: "Turquoise" },
    { name: "orange", value: false, title: "Orange" },
    { name: "brown", value: false, title: "Brown" },
    { name: "gold", value: false, title: "Gold" },
    { name: "silver", value: false, title: "Silver" },
    { name: "nude", value: false, title: "Nude" },
    { name: "transparent", value: false, title: "Transparent" },
  ],
  // file: itemPath2,
  showLookAlike: false,
  showLookAlike2: false,
  showTrashcan: false,
};

const storeWardrobeSlice = createSlice({
  name: "storeWardrobe",
  initialState,
  reducers: {
    setTargetValue: (state, action) => {
      const { name, checked } = action.payload;
      state.targetValues = state.targetValues.map((item) =>
        item.name === name ? { ...item, value: checked } : item
      );
    },
    setColorValue: (state, action) => {
      const { name, checked } = action.payload;
      state.colorValues = state.colorValues.map((item) =>
        item.name === name ? { ...item, value: checked } : item
      );
    },
    // setFile: (state, action) => {
    //   state.file = action.payload;
    // },
    setShowLookAlike: (state, action) => {
      state.showLookAlike = action.payload;
    },
    setShowLookAlike2: (state, action) => {
      state.showLookAlike2 = action.payload;
    },
    setShowTrashcan: (state, action) => {
      state.showTrashcan = action.payload;
    },
  },
});

export const {
  setTargetValue,
  setColorValue,
  // setFile,
  setShowLookAlike,
  setShowLookAlike2,
  setShowTrashcan,
} = storeWardrobeSlice.actions;

export default storeWardrobeSlice.reducer;
