import "./popupDisplay.css";
import Cookies from "js-cookie";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Wardrobe from "../../comps/Wardrobe/Wardrobe";
import PopupButton from "../../comps/header/PopupButton/PopupButton";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStylesImages,
  setShowLogout,
  setShowPreferences,
  setShowAppearance,
  setMetric,
  setAccurateAge,
  setAccurateGender,
  setAccurateWeight,
  setAccurateWrist,
  setAccurateHeight,
  setAccurateBust,
  setAccurateWaist,
  setAccurateHips,
  setAccurateSkin,
  setEstimatedAge,
  setEstimatedGender,
  setEstimatedHeight,
  setEstimatedSkin,
  setEstimatedShape,
  setEstimatedDimension,
  setErrorMessage,
} from "./popupDisplaySlice";
import {
  setLogedin,
  setFetchUser,
} from "../../utils/DisplayContainer/displayContainerSlice";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { NavLink } from "react-router-dom";
import api from "../../api/api";
//  * Date and Time Pickers
import dayjs from "dayjs";
import InputAdornment, {
  InputAdornmentProps,
} from "@mui/material/InputAdornment";
import PriorityHighIcon from "@mui/material/SvgIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const APP_API_URL = "https://kkaada.com";
// * My Appearance info hover imsges
const bustHelpHover =
  //  ? require(`${APP_API_URL}/images/appearance/measureA.jpg`)
  require(`../../assets/images/appearance/measureA.jpg`);
const waistHelpHover =
  //  ? require(`${APP_API_URL}/images/appearance/measureB.jpg`)
  require(`../../assets/images/appearance/measureB.jpg`);
const hipsHelpHover =
  //  ? require(`${APP_API_URL}/images/appearance/measureC.jpg`)
  require(`../../assets/images/appearance/measureC.jpg`);
const skinHelpHover =
  //  ? require(`${APP_API_URL}/images/appearance/skin.jpg`)
  require(`../../assets/images/appearance/skin.jpg`);

// * My Appearance body shape & dimensions imsges
const hourglassShape =
  //  ? require(`${APP_API_URL}/images/appearance/typeX.jpg`)
  require(`../../assets/images/appearance/typeX.jpg`);
const rectangleShape =
  //  ? require(`${APP_API_URL}/images/appearance/typeH.jpg`)
  require(`../../assets/images/appearance/typeH.jpg`);
const pearShape =
  //  ? require(`${APP_API_URL}/images/appearance/typeA.jpg`)
  require(`../../assets/images/appearance/typeA.jpg`);
const appleShape =
  //  ? require(`${APP_API_URL}/images/appearance/typeO.jpg`)
  require(`../../assets/images/appearance/typeO.jpg`);
const invertedTriangleShape =
  //  ? require(`${APP_API_URL}/images/appearance/typeV.jpg`)
  require(`../../assets/images/appearance/typeV.jpg`);
const obeseDimensions =
  //  ? require(`${APP_API_URL}/images/appearance/bmi5.jpg`)
  require(`../../assets/images/appearance/bmi5.jpg`);
const overweightDimensions =
  //  ? require(`${APP_API_URL}/images/appearance/bmi4.jpg`)
  require(`../../assets/images/appearance/bmi4.jpg`);
const averageDimensions =
  //  ? require(`${APP_API_URL}/images/appearance/bmi3.jpg`)
  require(`../../assets/images/appearance/bmi3.jpg`);
const leanDimensions =
  //  ? require(`${APP_API_URL}/images/appearance/bmi2.jpg`)
  require(`../../assets/images/appearance/bmi2.jpg`);
const underweightDimensions =
  //  ? require(`${APP_API_URL}/images/appearance/bmi1.jpg`)
  require(`../../assets/images/appearance/bmi1.jpg`);

// * Style Preferences outfits images
const casual1 =
  //  ? require(`${APP_API_URL}/images/preferences/1 casual.jpg`)
  require(`../../assets/images/preferences/1 casual.jpg`);
const casual2 =
  //  ? require(`${APP_API_URL}/images/preferences/4 casual.jpg`)
  require(`../../assets/images/preferences/4 casual.jpg`);
const casual3 =
  //  ? require(`${APP_API_URL}/images/preferences/9 casual.jpg`)
  require(`../../assets/images/preferences/9 casual.jpg`);
const casual4 =
  //  ? require(`${APP_API_URL}/images/preferences/11 casual.jpg`)
  require(`../../assets/images/preferences/11 casual.jpg`);
const casual5 =
  //  ? require(`${APP_API_URL}/images/preferences/18 casual.jpg`)
  require(`../../assets/images/preferences/18 casual.jpg`);
const elegant1 =
  //  ? require(`${APP_API_URL}/images/preferences/2 elegant.jpg`)
  require(`../../assets/images/preferences/2 elegant.jpg`);
const elegant2 =
  //  ? require(`${APP_API_URL}/images/preferences/7 elegant.jpg`)
  require(`../../assets/images/preferences/7 elegant.jpg`);
const elegant3 =
  //  ? require(`${APP_API_URL}/images/preferences/10 elegant.jpg`)
  require(`../../assets/images/preferences/10 elegant.jpg`);
const elegant4 =
  //  ? require(`${APP_API_URL}/images/preferences/13 elegant.jpg`)
  require(`../../assets/images/preferences/13 elegant.jpg`);
const elegant5 =
  //  ? require(`${APP_API_URL}/images/preferences/16 elegant.jpg`)
  require(`../../assets/images/preferences/16 elegant.jpg`);
const hipster1 =
  //  ? require(`${APP_API_URL}/images/preferences/3 hipster.jpg`)
  require(`../../assets/images/preferences/3 hipster.jpg`);
const hipster2 =
  //  ? require(`${APP_API_URL}/images/preferences/5 hipster.jpg`)
  require(`../../assets/images/preferences/5 hipster.jpg`);
const hipster3 =
  //  ? require(`${APP_API_URL}/images/preferences/17 hipster.jpg`)
  require(`../../assets/images/preferences/17 hipster.jpg`);
const hipster4 =
  //  ? require(`${APP_API_URL}/images/preferences/21 hipster.jpg`)
  require(`../../assets/images/preferences/21 hipster.jpg`);
const hipster5 =
  //  ? require(`${APP_API_URL}/images/preferences/23 hipster.jpg`)
  require(`../../assets/images/preferences/23 hipster.jpg`);
const rock1 =
  //  ? require(`${APP_API_URL}/images/preferences/6 rock.jpg`)
  require(`../../assets/images/preferences/6 rock.jpg`);
const rock2 =
  //  ? require(`${APP_API_URL}/images/preferences/12 rock.jpg`)
  require(`../../assets/images/preferences/12 rock.jpg`);
const rock3 =
  //  ? require(`${APP_API_URL}/images/preferences/14 rock.jpg`)
  require(`../../assets/images/preferences/14 rock.jpg`);
const rock4 =
  //  ? require(`${APP_API_URL}/images/preferences/19 rock.jpg`)
  require(`../../assets/images/preferences/19 rock.jpg`);
const rock5 =
  //  ? require(`${APP_API_URL}/images/preferences/25 rock.jpg`)
  require(`../../assets/images/preferences/25 rock.jpg`);
const sporty1 =
  //  ? require(`${APP_API_URL}/images/preferences/8 sporty.jpg`)
  require(`../../assets/images/preferences/8 sporty.jpg`);
const sporty2 =
  //  ? require(`${APP_API_URL}/images/preferences/15 sporty.jpg`)
  require(`../../assets/images/preferences/15 sporty.jpg`);
const sporty3 =
  //  ? require(`${APP_API_URL}/images/preferences/20 sporty.jpg`)
  require(`../../assets/images/preferences/20 sporty.jpg`);
const sporty4 =
  //  ? require(`${APP_API_URL}/images/preferences/22 sporty.jpg`)
  require(`../../assets/images/preferences/22 sporty.jpg`);
const sporty5 =
  //  ? require(`${APP_API_URL}/images/preferences/24 sporty.jpg`)
  require(`../../assets/images/preferences/24 sporty.jpg`);

// * Style Preferences var
let stylePreferences = [
  { no: "1", path: casual1, value: "casual" },
  { no: "1", path: elegant1, value: "elegant" },
  { no: "1", path: hipster1, value: "hipster" },
  { no: "2", path: casual2, value: "casual" },
  { no: "2", path: hipster2, value: "hipster" },
  { no: "1", path: rock1, value: "rock" },
  { no: "2", path: elegant2, value: "elegant" },
  { no: "1", path: sporty1, value: "sporty" },
  { no: "3", path: casual3, value: "casual" },
  { no: "3", path: elegant3, value: "elegant" },
  { no: "4", path: casual4, value: "casual" },
  { no: "2", path: rock2, value: "rock" },
  { no: "4", path: elegant4, value: "elegant" },
  { no: "3", path: rock3, value: "rock" },
  { no: "2", path: sporty2, value: "sporty" },
  { no: "5", path: elegant5, value: "elegant" },
  { no: "3", path: hipster3, value: "hipster" },
  { no: "5", path: casual5, value: "casual" },
  { no: "4", path: rock4, value: "rock" },
  { no: "3", path: sporty3, value: "sporty" },
  { no: "4", path: hipster4, value: "hipster" },
  { no: "4", path: sporty4, value: "sporty" },
  { no: "5", path: hipster5, value: "hipster" },
  { no: "5", path: sporty5, value: "sporty" },
  { no: "5", path: rock5, value: "rock" },
];

// * Date and Time
function CustomInputAdornment(props) {
  const { hasError, children, sx, ...other } = props;
  return (
    <InputAdornment {...other}>
      <PriorityHighIcon
        color="error"
        sx={{ marginLeft: 1, opacity: hasError ? 1 : 0 }}
      />
      {children}
    </InputAdornment>
  );
}

function PopupDisplay() {
  const dispatch = useDispatch();
  let fetchUser = useSelector((state) => state.displayContainer.fetchUser);
  const [currentUserFetched, setCurrentUserFetched] = useState(fetchUser);

  useEffect(() => {
    dispatch(setFetchUser(currentUserFetched));
  }, [currentUserFetched]);

  // * Saving temporary variables for viewing appearance and preferences with current variables
  const [accurateAgeTemporary, setAccurateAgeTemporary] = useState("");
  const [accurateGenderTemporary, setAccurateGenderTemporary] = useState("");
  const [accurateWeightTemporary, setAccurateWeightTemporary] = useState("");
  const [accurateWristTemporary, setAccurateWristTemporary] = useState("");
  const [accurateHeightTemporary, setAccurateHeightTemporary] = useState("");
  const [accurateBustTemporary, setAccurateBustTemporary] = useState("");
  const [accurateWaistTemporary, setAccurateWaistTemporary] = useState("");
  const [accurateHipsTemporary, setAccurateHipsTemporary] = useState("");
  const [accurateSkinTemporary, setAccurateSkinTemporary] = useState("");
  const [estimatedAgeTemporary, setEstimatedAgeTemporary] = useState("");
  const [estimatedGenderTemporary, setEstimatedGenderTemporary] = useState("");
  const [estimatedHeightTemporary, setEstimatedHeightTemporary] = useState("");
  const [estimatedSkinTemporary, setEstimatedSkinTemporary] = useState("");
  const [estimatedShapeTemporary, setEstimatedShapeTemporary] = useState("");
  const [estimatedDimensionTemporary, setEstimatedDimensionTemporary] =
    useState("");

  // * For style preference selections
  const stylesImages = useSelector((state) => state.popupDisplay.stylesImages);

  // * For Date and Time
  const [error, setError] = useState(null);
  const utc = require("dayjs/plugin/utc");
  const timezone = require("dayjs/plugin/timezone");
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const onLogout = () => {
    dispatch(setLogedin(false));
  };

  const handleLogout = () => {
    try {
      Cookies.remove("jwt");
    } catch (error) {}
    try {
      Cookies.remove("sessionCookie");
    } catch (error) {}
    try {
      Cookies.remove("adminFilter");
    } catch (error) {}
    try {
      Cookies.remove("userFilter");
    } catch (error) {}
    try {
      Cookies.remove("storeFilter");
    } catch (error) {}
    onLogout();
    handleCloseLogout();
    window.location = "/";
  };

  function toggleChecked(e, no, value) {
    const updatedStylesImages = stylesImages.map((style) => {
      if (style.no === no && style.value === value) {
        return {
          ...style,
          checked: !style.checked,
        };
      }
      return style;
    });
    dispatch(setStylesImages(updatedStylesImages));
  }

  let stylePreferencesMaped = stylePreferences.map((style) => {
    const isChecked = stylesImages.some(
      (s) => s.no === style.no && s.value === style.value && s.checked
    );

    return (
      <li className="li" key={style.no + style.value}>
        <label
          className={`label ${isChecked ? "checked" : ""}`}
          htmlFor={style.value + style.no}
          data-index={
            stylesImages.find(
              (s) => s.no === style.no && s.value === style.value
            ).id
          }
        >
          <input
            className="preferences"
            type="checkbox"
            checked={isChecked}
            id={style.value + style.no}
            onChange={(e) => toggleChecked(e, style.no, style.value)}
          />
          <img alt={"Style-preference-" + style.value} src={style.path} />
        </label>
      </li>
    );
  });

  // * Saving variables for exact measurements
  let accurateAge = useSelector((state) => state.popupDisplay.accurateAge);
  let accurateGender = useSelector(
    (state) => state.popupDisplay.accurateGender
  );
  let accurateWeight = useSelector(
    (state) => state.popupDisplay.accurateWeight
  );
  let accurateWrist = useSelector((state) => state.popupDisplay.accurateWrist);
  let accurateHeight = useSelector(
    (state) => state.popupDisplay.accurateHeight
  );
  let accurateBust = useSelector((state) => state.popupDisplay.accurateBust);
  let accurateWaist = useSelector((state) => state.popupDisplay.accurateWaist);
  let accurateHips = useSelector((state) => state.popupDisplay.accurateHips);
  let accurateSkin = useSelector((state) => state.popupDisplay.accurateSkin);
  // * Saving variables for approximate measurements
  let estimatedAge = useSelector((state) => state.popupDisplay.estimatedAge);
  let estimatedGender = useSelector(
    (state) => state.popupDisplay.estimatedGender
  );
  let estimatedHeight = useSelector(
    (state) => state.popupDisplay.estimatedHeight
  );
  let estimatedSkin = useSelector((state) => state.popupDisplay.estimatedSkin);
  let estimatedShape = useSelector(
    (state) => state.popupDisplay.estimatedShape
  );
  let estimatedDimension = useSelector(
    (state) => state.popupDisplay.estimatedDimension
  );

  // * Updating to user Appearance vars
  if (currentUserFetched) {
    if (currentUserFetched.appearance.accurate.age) {
      accurateAge = currentUserFetched.appearance.accurate.age;
    } else accurateAge = "";
    if (currentUserFetched.appearance.accurate.gender) {
      accurateGender = currentUserFetched.appearance.accurate.gender;
    } else accurateGender = estimatedGender === "" ? "female" : estimatedGender;
    if (currentUserFetched.appearance.accurate.weight) {
      accurateWeight = currentUserFetched.appearance.accurate.weight;
    } else accurateWeight = "";
    if (currentUserFetched.appearance.accurate.wrist) {
      accurateWrist = currentUserFetched.appearance.accurate.wrist;
    } else accurateWrist = "";
    if (currentUserFetched.appearance.accurate.height) {
      accurateHeight = currentUserFetched.appearance.accurate.height;
    } else accurateHeight = "";
    if (currentUserFetched.appearance.accurate.bust) {
      accurateBust = currentUserFetched.appearance.accurate.bust;
    } else accurateBust = "";
    if (currentUserFetched.appearance.accurate.waist) {
      accurateWaist = currentUserFetched.appearance.accurate.waist;
    } else accurateWaist = "";
    if (currentUserFetched.appearance.accurate.hips) {
      accurateHips = currentUserFetched.appearance.accurate.hips;
    } else accurateHips = "";
    if (currentUserFetched.appearance.accurate.skin) {
      accurateSkin = currentUserFetched.appearance.accurate.skin;
    } else accurateSkin = "1";
    if (currentUserFetched.appearance.estimated.age) {
      estimatedAge = currentUserFetched.appearance.estimated.age;
    } else estimatedAge = "19";
    if (currentUserFetched.appearance.estimated.gender) {
      estimatedGender = currentUserFetched.appearance.estimated.gender;
    } else estimatedGender = accurateGender === "" ? "female" : accurateGender;
    if (currentUserFetched.appearance.estimated.height) {
      estimatedHeight = currentUserFetched.appearance.estimated.height;
    } else estimatedHeight = "small";
    if (currentUserFetched.appearance.estimated.skin) {
      estimatedSkin = currentUserFetched.appearance.estimated.skin;
    } else estimatedSkin = "1";
    if (currentUserFetched.appearance.estimated.shape) {
      estimatedShape = currentUserFetched.appearance.estimated.shape;
    }
    if (currentUserFetched.appearance.estimated.dimension) {
      estimatedDimension = currentUserFetched.appearance.estimated.dimension;
    }
  }

  // * Updating to user stylesImages variable
  useEffect(() => {
    try {
      dispatch(setStylesImages(currentUserFetched.preferences));
    } catch (error) {
      window.location = "/";
    }
  }, []);

  const showLogout = useSelector((state) => state.popupDisplay.showLogout);
  const handleCloseLogout = () => {
    dispatch(setShowLogout(false));
  };
  const handleShowLogout = () => {
    dispatch(setShowLogout(true));
  };

  const showPreferences = useSelector(
    (state) => state.popupDisplay.showPreferences
  );
  const handleClosePreferences = (user) => {
    dispatch(setShowPreferences(false));
    dispatch(
      setStylesImages(user ? user.preferences : currentUserFetched.preferences)
    );
  };
  const handleShowPreferences = () => {
    dispatch(setShowPreferences(true));
  };

  const showAppearance = useSelector(
    (state) => state.popupDisplay.showAppearance
  );
  const handleCloseAppearance = () => {
    setRedFlag(false);
    dispatch(setShowAppearance(false));
    dispatch(setErrorMessage(""));
    setAccurateAgeTemporary("");
    setAccurateGenderTemporary("");
    setAccurateHeightTemporary("");
    setAccurateBustTemporary("");
    setAccurateWaistTemporary("");
    setAccurateHipsTemporary("");
    setAccurateSkinTemporary("");
    setEstimatedAgeTemporary("");
    setEstimatedGenderTemporary("");
    setEstimatedHeightTemporary("");
    setEstimatedSkinTemporary("");
    setEstimatedShapeTemporary("");
    setEstimatedDimensionTemporary("");
  };
  const handleShowAppearance = () => {
    // * Display initialization
    if (
      currentUserFetched.appearance.estimated.shape === "" &&
      currentUserFetched.appearance.estimated.dimension === ""
    ) {
      dispatch(setMetric("accurate"));
    } else dispatch(setMetric("estimated"));

    dispatch(setShowAppearance(true));
  };

  let age = [];
  for (let index = 18; index > 0; index--) {
    age.push(
      <option key={index} value={index}>
        {index}
      </option>
    );
  }
  let height = [];
  for (let index = 138; index < 199; index++) {
    height.push(
      <option key={index} value={index}>
        {index}
      </option>
    );
  }
  let bust = [];
  for (let index = 57; index < 139; index++) {
    bust.push(
      <option key={index} value={index}>
        {index}
      </option>
    );
  }
  let waist = [];
  for (let index = 39; index < 123; index++) {
    waist.push(
      <option key={index} value={index}>
        {index}
      </option>
    );
  }
  let hips = [];
  for (let index = 68; index < 146; index++) {
    hips.push(
      <option key={index} value={index}>
        {index}
      </option>
    );
  }

  const metric = useSelector((state) => state.popupDisplay.metric);
  const errorMessage = useSelector((state) => state.popupDisplay.errorMessage);

  // * Transition between pages - My Appearance
  function estimatedMode() {
    dispatch(setMetric("estimated"));
  }
  function accurateMode() {
    dispatch(setMetric("accurate"));
    if (errorMessage !== "") {
      dispatch(setErrorMessage(""));
    }
  }

  let [redFlag, setRedFlag] = useState(false);
  function handleSubmitCheck(e) {
    if (
      metric === "accurate" &&
      (accurateGender === "male" || accurateGenderTemporary === "male") &&
      (accurateBust || accurateBustTemporary) &&
      (accurateHeight || accurateHeightTemporary) &&
      (accurateHips || accurateHipsTemporary) &&
      (accurateWaist || accurateWaistTemporary) &&
      (accurateWeight || accurateWeightTemporary) &&
      (accurateWrist || accurateWristTemporary) &&
      (accurateAge || accurateAgeTemporary) &&
      !error
    ) {
      handleSubmitAppearance(e);
    } else if (
      metric === "accurate" &&
      (accurateBust || accurateBustTemporary) &&
      (accurateHeight || accurateHeightTemporary) &&
      (accurateHips || accurateHipsTemporary) &&
      (accurateWaist || accurateWaistTemporary) &&
      (accurateAge || accurateAgeTemporary) &&
      !error
    ) {
      handleSubmitAppearance(e);
    } else if (
      (estimatedDimension || estimatedDimensionTemporary) &&
      (estimatedShape || estimatedShapeTemporary) &&
      !error
    ) {
      handleSubmitAppearance(e);
    } else if (metric === "accurate") {
      setRedFlag(true);
      dispatch(setErrorMessage("Red-marked parameters must be completed"));
    } else if (metric !== "accurate") {
      if (
        estimatedShapeTemporary === "" &&
        estimatedDimensionTemporary === ""
      ) {
        dispatch(
          setErrorMessage("Please select body shape and body dimensions")
        );
        return;
      } else if (estimatedShapeTemporary === "") {
        dispatch(setErrorMessage("Please select body shape"));
        return;
      } else if (estimatedDimensionTemporary === "") {
        dispatch(setErrorMessage("Please select body dimension"));
        return;
      }
    }
  }

  async function handleSubmitAppearance(event) {
    event.preventDefault();
    if (metric === "accurate") {
      if (accurateAgeTemporary !== "") {
        accurateAge = accurateAgeTemporary;
      } else
        accurateAge = accurateAge ? accurateAge : "2004-05-31T21:00:00.000Z"; // In case there is no date (user bypassing front code)
      if (accurateGenderTemporary !== "") {
        accurateGender = accurateGenderTemporary;
      }
      if (accurateWeightTemporary !== "") {
        accurateWeight = accurateWeightTemporary;
      }
      if (accurateWristTemporary !== "") {
        accurateWrist = accurateWristTemporary;
      }
      if (accurateHeightTemporary !== "") {
        accurateHeight = accurateHeightTemporary;
      }
      if (accurateBustTemporary !== "") {
        accurateBust = accurateBustTemporary;
      }
      if (accurateWaistTemporary !== "") {
        accurateWaist = accurateWaistTemporary;
      }
      if (accurateHipsTemporary !== "") {
        accurateHips = accurateHipsTemporary;
      }
      if (accurateSkinTemporary !== "") {
        accurateSkin = accurateSkinTemporary;
      }
    }
    if (metric === "estimated") {
      if (estimatedAgeTemporary !== "") {
        estimatedAge = estimatedAgeTemporary;
      }
      if (estimatedGenderTemporary !== "") {
        estimatedGender = estimatedGenderTemporary;
      }
      if (estimatedHeightTemporary !== "") {
        estimatedHeight = estimatedHeightTemporary;
      }
      if (estimatedSkinTemporary !== "") {
        estimatedSkin = estimatedSkinTemporary;
      }
      if (estimatedShapeTemporary !== "") {
        estimatedShape = estimatedShapeTemporary;
      }
      if (estimatedDimensionTemporary !== "") {
        estimatedDimension = estimatedDimensionTemporary;
      }
    }
    const formData =
      metric === "accurate"
        ? {
            appearance: {
              accurate: {
                age: dayjs(accurateAge).tz("utc", true),
                gender: accurateGender,
                weight: accurateWeight,
                wrist: accurateWrist,
                height: accurateHeight,
                bust: accurateBust,
                waist: accurateWaist,
                hips: accurateHips,
                skin: accurateSkin,
              },
              estimated: {
                age: "",
                gender: "",
                height: "",
                skin: "",
                shape: "",
                dimension: "",
              },
            },
          }
        : {
            appearance: {
              accurate: {
                age: "",
                gender: "",
                weight: "",
                wrist: "",
                height: "",
                bust: "",
                waist: "",
                hips: "",
                skin: "",
              },
              estimated: {
                age: estimatedAge,
                gender: estimatedGender,
                height: estimatedHeight,
                skin: estimatedSkin,
                shape: estimatedShape,
                dimension: estimatedDimension,
              },
            },
          };
    if (metric !== "accurate") {
      if (
        formData.appearance.estimated.shape === "" &&
        formData.appearance.estimated.dimension === ""
      ) {
        dispatch(
          setErrorMessage("Please select body shape and body dimensions")
        );
        return;
      } else if (formData.appearance.estimated.shape === "") {
        dispatch(setErrorMessage("Please select body shape"));
        return;
      } else if (formData.appearance.estimated.dimension === "") {
        dispatch(setErrorMessage("Please select body dimension"));
        return;
      }
    }
    if (metric === "accurate") {
      if (formData.appearance.accurate.gender === "male") {
        if (
          formData.appearance.accurate.weight === "" ||
          formData.appearance.accurate.wrist === "" ||
          formData.appearance.accurate.bust === "" ||
          formData.appearance.accurate.hips === "" ||
          formData.appearance.accurate.waist === "" ||
          formData.appearance.accurate.age === "" ||
          formData.appearance.accurate.height === ""
        ) {
          setRedFlag(true);
          dispatch(setErrorMessage("Red-marked parameters must be completed"));
          return;
        }
      } else if (
        formData.appearance.accurate.bust === "" ||
        formData.appearance.accurate.hips === "" ||
        formData.appearance.accurate.waist === "" ||
        formData.appearance.accurate.age === "" ||
        formData.appearance.accurate.height === ""
      ) {
        setRedFlag(true);
        dispatch(setErrorMessage("Red-marked parameters must be completed"));
        return;
      }
    }

    dispatch(setErrorMessage(""));
    handleCloseAppearance();
    // * For the user and the list of outfits on the home page to be updated according to the user's change
    Cookies.set("sessionCookie", false);

    let updateUsersAppearance = formData;
    // * Reset for the display of the saved selections (and not the checked and unsaved ones)
    const response = await api.post("/updateUsersAppearance", {
      currentUserFetched,
      updateUsersAppearance,
    });
    const user = response.data;
    setCurrentUserFetched(user);
    dispatch(setFetchUser(user));
    dispatch(setAccurateAge(user.appearance.accurate.age));
    dispatch(setAccurateGender(user.appearance.accurate.gender));
    dispatch(setAccurateWeight(user.appearance.accurate.weight));
    dispatch(setAccurateWrist(user.appearance.accurate.wrist));
    dispatch(setAccurateHeight(user.appearance.accurate.height));
    dispatch(setAccurateBust(user.appearance.accurate.bust));
    dispatch(setAccurateWaist(user.appearance.accurate.waist));
    dispatch(setAccurateHips(user.appearance.accurate.hips));
    dispatch(setAccurateSkin(user.appearance.accurate.skin));
    dispatch(setEstimatedAge(user.appearance.estimated.age));
    dispatch(setEstimatedGender(user.appearance.estimated.gender));
    dispatch(setEstimatedHeight(user.appearance.estimated.height));
    dispatch(setEstimatedSkin(user.appearance.estimated.skin));
    dispatch(setEstimatedShape(user.appearance.estimated.shape));
    dispatch(setEstimatedDimension(user.appearance.estimated.dimension));
  }

  async function handleSubmitPreferences(event) {
    event.preventDefault();
    const checkedStyles = stylesImages.filter((style) => style.checked);
    if (checkedStyles.length < 3) {
      alert("Please choose at least 3 outfits");
      return;
    }

    let updateUsersStyles = stylesImages;
    // * Reset for the display of the saved selections (and not the checked and unsaved ones)
    const response = await api.post("/updateUsersStyles", {
      currentUserFetched,
      updateUsersStyles,
    });
    const user = response.data;
    setCurrentUserFetched(user);
    dispatch(setStylesImages(user.preferences));
    dispatch(setFetchUser(user));
    handleClosePreferences(user);
    // * For the user and the list of outfits on the home page to be updated according to the user's change
    Cookies.set("sessionCookie", false);
  }

  let maleVar =
    accurateGender === "male" || estimatedGender === "male" ? false : true;
  if (accurateGenderTemporary !== "female") {
    maleVar = false;
  } else maleVar = true;
  if (accurateGender === "female" && accurateGenderTemporary !== "male") {
    maleVar = true;
  }

  // * Determining variables for saving estimated parameters
  const [ageTest, setAgeTest] = useState("19");
  const [genderTest, setGenderTest] = useState("female");
  const height_thresholds = {
    male: {
      18: { high: 184, low: 169 },
      17: { high: 183, low: 167 },
      16: { high: 181, low: 165 },
      15: { high: 177, low: 161 },
      14: { high: 171, low: 155 },
      13: { high: 164, low: 148 },
      12: { high: 157, low: 142 },
      11: { high: 150, low: 136 },
      10: { high: 145, low: 132 },
      9: { high: 139, low: 127 },
      8: { high: 133, low: 122 },
      7: { high: 128, low: 117 },
      6: { high: 122, low: 111 },
      5: { high: 115, low: 105 },
      4: { high: 108, low: 99 },
      3: { high: 100, low: 92 },
      2: { high: 91, low: 84 },
      0: { high: 78, low: 73 },
    },
    female: {
      19: { high: 170, low: 157 },
      // The definition of women is intentionally with the age of 19 (18 and 17 are the same)
      17: { high: 170, low: 156 },
      16: { high: 170, low: 155 },
      15: { high: 168, low: 154 },
      14: { high: 167, low: 153 },
      13: { high: 163, low: 149 },
      12: { high: 158, low: 144 },
      11: { high: 152, low: 138 },
      10: { high: 145, low: 133 },
      9: { high: 138, low: 127 },
      8: { high: 133, low: 121 },
      7: { high: 127, low: 115 },
      6: { high: 121, low: 110 },
      5: { high: 114, low: 104 },
      4: { high: 107, low: 98 },
      3: { high: 99, low: 91 },
      2: { high: 90, low: 83 },
      0: { high: 77, low: 71 },
    },
  };
  useEffect(() => {
    if (estimatedAgeTemporary !== "") {
      setAgeTest(estimatedAgeTemporary);
    }
    if (estimatedGenderTemporary) {
      setGenderTest(estimatedGenderTemporary);
    }
    if (estimatedHeightTemporary !== "") {
      const newOptions = generateOptions(
        estimatedAgeTemporary || estimatedAge,
        estimatedGenderTemporary || estimatedGender
      );
      setEstimatedHeightTemporary(newOptions[0].key);
    }
  }, [estimatedAgeTemporary, estimatedGenderTemporary]);
  function findClosestThreshold(X, Y) {
    const thresholds = height_thresholds[Y];
    const closestKey = Object.keys(thresholds).reduce((prev, curr) => {
      return Math.abs(curr - X) < Math.abs(prev - X) ? curr : prev;
    });
    const { high, low } = thresholds[closestKey];
    return { high, low };
  }
  function generateOptions(X, Y) {
    const { high, low } = findClosestThreshold(X, Y);
    const mid = Math.floor((high + low) / 2);

    const options = [
      { key: "small", value: "small", text: `Up to ${low - 0.01}` },
      { key: "medium", value: "medium", text: `Between ${low} and ${high}` },
      { key: "large", value: "large", text: `${high + 0.01} and above` },
    ];
    return options.map((option) => (
      <option key={option.key} value={option.value}>
        {option.text}
      </option>
    ));
  }

  return (
    <div className="bakground">
      <div className="cont">
        <div
          style={{
            fontSize: "150%",
            position: "absolute",
            display: "contents",
          }}
        >
          {/* // * Popup for "Logout button" */}
          <Modal show={showLogout} onHide={handleCloseLogout}>
            <Modal.Header closeButton>
              <Modal.Title>Logout?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseLogout}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleLogout}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* // * Popup for "Style Preferences" containing a form for sending/updating user information */}
          <Modal
            backdrop="static"
            keyboard={false}
            show={showPreferences}
            onHide={() => handleClosePreferences(false)}
            contentClassName="preferencesModalContent widthPreferences"
            dialogClassName="modalResponsive preferencesModalDialog"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Style Preferences
                <h6>Select outfits to your taste (at least 3)</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="widthResponsive">
                <ul>{stylePreferencesMaped}</ul>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => handleClosePreferences(false)}
              >
                Cancel
              </Button>
              <Button
                variant="success"
                onClick={(event) => handleSubmitPreferences(event)}
              >
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* // * Popup for "My Appearance" containing a form for sending/updating user information */}
          <Modal
            show={showAppearance}
            onHide={handleCloseAppearance}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal modalResponsive"
          >
            <div>
              <Modal.Header closeButton>
                <Modal.Title>
                  My Appearance
                  <span style={{ marginLeft: "20px" }}>
                    <ButtonGroup aria-label="Basic example">
                      <OverlayTrigger
                        key="popover-accurate"
                        placement="bottom"
                        overlay={
                          <Tooltip id={`popover-accurate`}>Optimal</Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          active={metric === "accurate"}
                          onClick={accurateMode}
                        >
                          Accurate
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        key="popover-estimated"
                        placement="bottom"
                        overlay={
                          <Tooltip id={`popover-estimated`}>
                            Not optimal, use only if the exact measurements are
                            not available
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          active={metric === "estimated"}
                          onClick={estimatedMode}
                        >
                          Estimated
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* // * Contains a display for exact or approximate measurements */}
                {metric === "accurate" ? (
                  <Form>
                    <FormGroup id="age">
                      <InputGroup
                        id="age-input"
                        className="mb-3"
                        style={{ justifyContent: "space-between" }}
                      >
                        <InputGroup.Text id="age-txt" className="var-width">
                          Your age
                        </InputGroup.Text>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <span
                            style={
                              !accurateAge && !accurateAgeTemporary && redFlag
                                ? { border: "solid 1px red" }
                                : {}
                            }
                          >
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                format="DD/MM/YYYY"
                                label="Date of birth"
                                maxDate={dayjs(new Date())}
                                name={
                                  accurateAgeTemporary || accurateAge
                                    ? undefined
                                    : "startDateTime"
                                }
                                defaultValue={
                                  accurateAgeTemporary === "" && !accurateAge
                                    ? undefined
                                    : accurateAge
                                    ? dayjs(accurateAge)
                                    : accurateAgeTemporary
                                }
                                onChange={(e) => setAccurateAgeTemporary(e)}
                                onError={setError}
                                slots={{ inputAdornment: CustomInputAdornment }}
                                slotProps={{
                                  inputAdornment: { hasError: !!error },
                                }}
                              />
                            </DemoContainer>
                          </span>
                        </LocalizationProvider>
                        <OverlayTrigger
                          key="popover-age"
                          placement="bottom"
                          overlay={
                            <Tooltip id={`popover-age`}>
                              Your age determines how we consider your
                              measurements for the AI
                            </Tooltip>
                          }
                        >
                          <InputGroup.Text className="help-width">
                            <i className="fa-solid fa-calendar-day"></i>
                            &nbsp;?
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup id="gender">
                      <InputGroup id="gender-input" className="mb-3">
                        <InputGroup.Text id="gender-txt" className="var-width">
                          Your gender
                        </InputGroup.Text>
                        <Form.Select
                          value={
                            accurateGenderTemporary === ""
                              ? accurateGender
                              : accurateGenderTemporary
                          }
                          onChange={(e) =>
                            setAccurateGenderTemporary(e.target.value)
                          }
                          aria-label="Gender"
                        >
                          <option key="male" value="male">
                            Male
                          </option>
                          <option key="female" value="female">
                            Female
                          </option>
                        </Form.Select>
                        <OverlayTrigger
                          key="popover-gender"
                          placement="bottom"
                          overlay={
                            <Tooltip id={`popover-gender`}>
                              The gender of your birth determines how we
                              consider your measurements for the AI
                            </Tooltip>
                          }
                        >
                          <InputGroup.Text className="help-width">
                            <i className="fa-solid fa-venus-mars"></i>
                            &nbsp;?
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      id="weight"
                      style={{ display: maleVar ? "none" : "" }}
                    >
                      <InputGroup id="weight-input" className="mb-3">
                        <InputGroup.Text id="weight-txt" className="var-width">
                          Weight in kg
                        </InputGroup.Text>
                        <Form.Control
                          style={
                            !accurateWeight &&
                            !accurateWeightTemporary &&
                            redFlag
                              ? { border: "solid 1px red" }
                              : {}
                          }
                          aria-label="Weight"
                          onChange={(e) =>
                            setAccurateWeightTemporary(e.target.value)
                          }
                          value={
                            accurateWeightTemporary === ""
                              ? accurateWeight
                              : accurateWeightTemporary
                          }
                          placeholder="e.g. 63.3"
                          type="number"
                          step="0.1"
                          min="10"
                          max="200"
                        />
                        <OverlayTrigger
                          key="popover-weight"
                          placement="bottom"
                          overlay={
                            <Tooltip id={`popover-weight`}>
                              Your weight determines how we consider your
                              measurements for the AI
                            </Tooltip>
                          }
                        >
                          <InputGroup.Text className="help-width">
                            <i className="fa-solid fa-weight-scale"></i>&nbsp;?
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      id="wrist"
                      style={{ display: maleVar ? "none" : "" }}
                    >
                      <InputGroup id="wrist-input" className="mb-3">
                        <InputGroup.Text id="wrist-txt" className="var-width">
                          Wrist in cm
                        </InputGroup.Text>
                        <Form.Control
                          style={
                            !accurateWrist && !accurateWristTemporary && redFlag
                              ? { border: "solid 1px red" }
                              : {}
                          }
                          aria-label="Wrist"
                          onChange={(e) =>
                            setAccurateWristTemporary(e.target.value)
                          }
                          value={
                            accurateWristTemporary === ""
                              ? accurateWrist
                              : accurateWristTemporary
                          }
                          placeholder="e.g. 15.8"
                          type="number"
                          step="0.1"
                          min="5"
                          max="50"
                        />
                        <OverlayTrigger
                          key="popover-wrist"
                          placement="bottom"
                          overlay={
                            <Tooltip id={`popover-wrist`}>
                              Wrap the measuring tape around the wrist (about 1
                              cm below the joint) without making it tight or
                              loose
                            </Tooltip>
                          }
                        >
                          <InputGroup.Text className="help-width">
                            <i className="fa-solid fa-arrow-rotate-right"></i>
                            &nbsp;?
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup id="height">
                      <InputGroup id="height-input" className="mb-3">
                        <InputGroup.Text id="height-txt" className="var-width">
                          Height in cm
                        </InputGroup.Text>
                        <Form.Control
                          style={
                            !accurateHeight &&
                            !accurateHeightTemporary &&
                            redFlag
                              ? { border: "solid 1px red" }
                              : {}
                          }
                          value={
                            accurateHeightTemporary === ""
                              ? accurateHeight
                              : accurateHeightTemporary
                          }
                          onChange={(e) =>
                            setAccurateHeightTemporary(e.target.value)
                          }
                          aria-label="Height"
                          placeholder="e.g. 165"
                          type="number"
                          step="0.1"
                          min="50"
                          max="250"
                        ></Form.Control>
                        <OverlayTrigger
                          key="popover-height"
                          placement="bottom"
                          overlay={
                            <Tooltip id={`popover-height`}>
                              The representative height must be estimated
                              without considering the addition/height of the
                              shoes
                            </Tooltip>
                          }
                        >
                          <InputGroup.Text className="help-width">
                            <i className="fa-solid fa-up-down"></i>&nbsp;?
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup id="bust">
                      <InputGroup id="bust-input" className="mb-3">
                        <InputGroup.Text id="bust-txt" className="var-width">
                          Bust in cm
                        </InputGroup.Text>
                        <Form.Control
                          style={
                            !accurateBust && !accurateBustTemporary && redFlag
                              ? { border: "solid 1px red" }
                              : {}
                          }
                          value={
                            accurateBustTemporary === ""
                              ? accurateBust
                              : accurateBustTemporary
                          }
                          onChange={(e) =>
                            setAccurateBustTemporary(e.target.value)
                          }
                          aria-label="Bust"
                          placeholder="e.g. 90"
                          type="number"
                          step="0.1"
                          min="40"
                          max="150"
                        ></Form.Control>
                        <OverlayTrigger
                          key="popover-bust"
                          overlay={
                            <Popover
                              id="popover-bust"
                              bsPrefix="popoverposition4"
                            >
                              <img alt="How-to-measure" src={bustHelpHover} />
                            </Popover>
                          }
                        >
                          <InputGroup.Text className="help-width">
                            <i className="fa-solid fa-left-right"></i>&nbsp;?
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup id="waist">
                      <InputGroup id="waist-input" className="mb-3">
                        <InputGroup.Text id="waist-txt" className="var-width">
                          Waist in cm
                        </InputGroup.Text>
                        <Form.Control
                          style={
                            !accurateWaist && !accurateWaistTemporary && redFlag
                              ? { border: "solid 1px red" }
                              : {}
                          }
                          value={
                            accurateWaistTemporary === ""
                              ? accurateWaist
                              : accurateWaistTemporary
                          }
                          onChange={(e) =>
                            setAccurateWaistTemporary(e.target.value)
                          }
                          aria-label="Waist"
                          placeholder="e.g. 60"
                          type="number"
                          step="0.1"
                          min="40"
                          max="150"
                        ></Form.Control>
                        <OverlayTrigger
                          key="popover-waist"
                          overlay={
                            <Popover
                              id="popover-waist"
                              bsPrefix="popoverposition3"
                            >
                              <img alt="How-to-measure" src={waistHelpHover} />
                            </Popover>
                          }
                        >
                          <InputGroup.Text className="help-width">
                            <i className="fa-solid fa-left-right"></i>&nbsp;?
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup id="hips">
                      <InputGroup id="hips-input" className="mb-3">
                        <InputGroup.Text id="hips-txt" className="var-width">
                          Hips in cm
                        </InputGroup.Text>
                        <Form.Control
                          style={
                            !accurateHips && !accurateHipsTemporary && redFlag
                              ? { border: "solid 1px red" }
                              : {}
                          }
                          value={
                            accurateHipsTemporary === ""
                              ? accurateHips
                              : accurateHipsTemporary
                          }
                          onChange={(e) =>
                            setAccurateHipsTemporary(e.target.value)
                          }
                          aria-label="Hips"
                          placeholder="e.g. 90"
                          type="number"
                          step="0.1"
                          min="40"
                          max="160"
                        ></Form.Control>
                        <OverlayTrigger
                          key="popover-hips"
                          overlay={
                            <Popover
                              id="popover-hips"
                              bsPrefix="popoverposition2"
                            >
                              <img alt="How-to-measure" src={hipsHelpHover} />
                            </Popover>
                          }
                        >
                          <InputGroup.Text className="help-width">
                            <i className="fa-solid fa-left-right"></i>&nbsp;?
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup id="skin">
                      <InputGroup id="skin-input" className="mb-3">
                        <InputGroup.Text id="skin-txt" className="var-width">
                          Skin color
                        </InputGroup.Text>
                        <Form.Select
                          value={
                            accurateSkinTemporary === ""
                              ? accurateSkin
                              : accurateSkinTemporary
                          }
                          onChange={(e) =>
                            setAccurateSkinTemporary(e.target.value)
                          }
                          aria-label="Skin"
                        >
                          <option key="1" value="1">
                            Very light skin tone
                          </option>
                          <option key="2" value="2">
                            Light skin tone
                          </option>
                          <option key="3" value="3">
                            Medium skin tone
                          </option>
                          <option key="4" value="4">
                            Dark skin tone
                          </option>
                          <option key="5" value="5">
                            Very Dark skin tone
                          </option>
                        </Form.Select>
                        <OverlayTrigger
                          key="popover-skin"
                          overlay={
                            <Popover
                              id="popover-skin"
                              bsPrefix="popoverposition"
                            >
                              <img alt="How-to-measure" src={skinHelpHover} />
                            </Popover>
                          }
                        >
                          <InputGroup.Text className="help-width">
                            <i className="fa-solid fa-droplet"></i>&nbsp;?
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>
                  </Form>
                ) : (
                  <div className="widthResponsive widtForm">
                    <Form>
                      <FormGroup id="age">
                        <InputGroup id="age-input" className="mb-3">
                          <InputGroup.Text id="age-txt" className="var-width">
                            Your age
                          </InputGroup.Text>
                          <Form.Select
                            value={
                              estimatedAgeTemporary === ""
                                ? estimatedAge
                                : estimatedAgeTemporary
                            }
                            onChange={(e) =>
                              setEstimatedAgeTemporary(e.target.value)
                            }
                            aria-label="Age"
                          >
                            <option key="19 and above" value="19">
                              19 and above
                            </option>
                            {age}
                          </Form.Select>
                          <OverlayTrigger
                            key="popover-age"
                            placement="bottom"
                            overlay={
                              <Tooltip id={`popover-age`}>
                                Your age determines how we consider your
                                measurements for the AI
                              </Tooltip>
                            }
                          >
                            <InputGroup.Text className="help-width">
                              <i className="fa-solid fa-calendar-day"></i>
                              &nbsp;?
                            </InputGroup.Text>
                          </OverlayTrigger>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup id="gender">
                        <InputGroup id="gender-input" className="mb-3">
                          <InputGroup.Text
                            id="gender-txt"
                            className="var-width"
                          >
                            Your gender
                          </InputGroup.Text>
                          <Form.Select
                            value={
                              estimatedGenderTemporary === ""
                                ? estimatedGender
                                : estimatedGenderTemporary
                            }
                            onChange={(e) =>
                              setEstimatedGenderTemporary(e.target.value)
                            }
                            aria-label="Gender"
                          >
                            <option key="male" value="male">
                              Male
                            </option>
                            <option key="female" value="female">
                              Female
                            </option>
                          </Form.Select>
                          <OverlayTrigger
                            key="popover-height"
                            placement="bottom"
                            overlay={
                              <Tooltip id={`popover-height`}>
                                The gender of your birth determines how we
                                consider your measurements for the AI
                              </Tooltip>
                            }
                          >
                            <InputGroup.Text className="help-width">
                              <i className="fa-solid fa-venus-mars"></i>&nbsp;?
                            </InputGroup.Text>
                          </OverlayTrigger>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup id="height">
                        <InputGroup id="height-input" className="mb-3">
                          <InputGroup.Text
                            id="height-txt"
                            className="var-width"
                          >
                            Height in cm
                          </InputGroup.Text>
                          <Form.Select
                            value={
                              estimatedHeightTemporary === ""
                                ? estimatedHeight
                                : estimatedHeightTemporary
                            }
                            onChange={(e) =>
                              setEstimatedHeightTemporary(e.target.value)
                            }
                            aria-label="Height"
                          >
                            {generateOptions(ageTest, genderTest)}
                          </Form.Select>
                          <OverlayTrigger
                            key="popover-height"
                            placement="bottom"
                            overlay={
                              <Tooltip id={`popover-height`}>
                                The representative height must be estimated
                                without considering the addition/height of the
                                shoes
                              </Tooltip>
                            }
                          >
                            <InputGroup.Text className="help-width">
                              <i className="fa-solid fa-up-down"></i>&nbsp;?
                            </InputGroup.Text>
                          </OverlayTrigger>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup id="skin">
                        <InputGroup id="skin-input" className="mb-3">
                          <InputGroup.Text id="skin-txt" className="var-width">
                            Skin color
                          </InputGroup.Text>
                          <Form.Select
                            value={
                              estimatedSkinTemporary === ""
                                ? estimatedSkin
                                : estimatedSkinTemporary
                            }
                            onChange={(e) =>
                              setEstimatedSkinTemporary(e.target.value)
                            }
                            aria-label="Skin"
                          >
                            <option key="1" value="1">
                              Very light skin tone
                            </option>
                            <option key="2" value="2">
                              Light skin tone
                            </option>
                            <option key="3" value="3">
                              Medium skin tone
                            </option>
                            <option key="4" value="4">
                              Dark skin tone
                            </option>
                            <option key="5" value="5">
                              Very Dark skin tone
                            </option>
                          </Form.Select>
                          <OverlayTrigger
                            key="popover-skin"
                            overlay={
                              <Popover
                                id="popover-skin"
                                bsPrefix="popoverposition4"
                              >
                                <img alt="How-to-measure" src={skinHelpHover} />
                              </Popover>
                            }
                          >
                            <InputGroup.Text className="help-width">
                              <i className="fa-solid fa-droplet"></i>&nbsp;?
                            </InputGroup.Text>
                          </OverlayTrigger>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup id="shape">
                        <InputGroup
                          onChange={(e) =>
                            setEstimatedShapeTemporary(e.target.value)
                          }
                          id="shape-input"
                          className="mb-3"
                        >
                          <InputGroup.Text id="shape-txt" className="var-width">
                            Body shape
                          </InputGroup.Text>
                          <div style={{ marginLeft: " 10px" }}>
                            <label
                              type="radio"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="label"
                              htmlFor="hourglass"
                            >
                              <img
                                alt="Body-shape"
                                src={hourglassShape}
                                style={{ width: "100px", marginRight: " 10px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`hourglass`}
                              name="shape"
                              label={`Hourglass`}
                              value="1"
                              onClick={(e) =>
                                setEstimatedShapeTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedShapeTemporary === "1" ||
                                estimatedShape === "1"
                              }
                            />
                          </div>
                          <div>
                            <label
                              type="radio"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="label"
                              htmlFor="rectangle"
                            >
                              <img
                                alt="Body-shape"
                                src={rectangleShape}
                                style={{ width: "100px", marginRight: " 10px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`rectangle`}
                              name="shape"
                              label={`Rectangle`}
                              value="5"
                              onClick={(e) =>
                                setEstimatedShapeTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedShapeTemporary === "5" ||
                                estimatedShape === "5"
                              }
                            />
                          </div>
                          <div>
                            <label
                              type="radio"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="label"
                              htmlFor="pear"
                            >
                              <img
                                alt="Body-shape"
                                src={pearShape}
                                style={{ width: "100px", marginRight: " 10px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`pear`}
                              name="shape"
                              label={`Pear`}
                              value="2"
                              onClick={(e) =>
                                setEstimatedShapeTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedShapeTemporary === "2" ||
                                estimatedShape === "2"
                              }
                            />
                          </div>
                          <div>
                            <label
                              type="radio"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="label"
                              htmlFor="apple"
                            >
                              <img
                                alt="Body-shape"
                                src={appleShape}
                                style={{ width: "100px", marginRight: " 10px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`apple`}
                              name="shape"
                              label={`Apple`}
                              value="3"
                              onClick={(e) =>
                                setEstimatedShapeTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedShapeTemporary === "3" ||
                                estimatedShape === "3"
                              }
                            />
                          </div>
                          <div>
                            <label
                              className="label"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              htmlFor="inverted-triangle"
                            >
                              <img
                                alt="Body-shape"
                                src={invertedTriangleShape}
                                style={{ width: "100px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`inverted-triangle`}
                              name="shape"
                              label={`Inverted Triangle`}
                              value="4"
                              onClick={(e) =>
                                setEstimatedShapeTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedShapeTemporary === "4" ||
                                estimatedShape === "4"
                              }
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup id="dimensions">
                        <InputGroup
                          onChange={(e) =>
                            setEstimatedDimensionTemporary(e.target.value)
                          }
                          id="dimensions-input"
                          className="mb-3"
                        >
                          <InputGroup.Text
                            id="dimensions-txt"
                            className="var-width"
                          >
                            <div>Body</div> <div>dimensions</div>
                          </InputGroup.Text>
                          <div style={{ marginLeft: " 10px" }}>
                            <label
                              type="radio"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="label"
                              htmlFor="obese"
                            >
                              <img
                                alt="Body-dimensions"
                                src={obeseDimensions}
                                style={{ width: "100px", marginRight: " 10px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`obese`}
                              name="dimensions"
                              label={`Obese`}
                              value="5"
                              onClick={(e) =>
                                setEstimatedDimensionTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedDimensionTemporary === "5" ||
                                estimatedDimension === "5"
                              }
                            />
                          </div>
                          <div>
                            <label
                              type="radio"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="label"
                              htmlFor="overweight"
                            >
                              <img
                                alt="Body-dimensions"
                                src={overweightDimensions}
                                style={{ width: "100px", marginRight: " 10px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`overweight`}
                              name="dimensions"
                              label={`Overweight`}
                              value="4"
                              onClick={(e) =>
                                setEstimatedDimensionTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedDimensionTemporary === "4" ||
                                estimatedDimension === "4"
                              }
                            />
                          </div>
                          <div>
                            <label
                              type="radio"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="label"
                              htmlFor="average"
                            >
                              <img
                                alt="Body-dimensions"
                                src={averageDimensions}
                                style={{ width: "100px", marginRight: " 10px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`average`}
                              name="dimensions"
                              label={`Average`}
                              value="3"
                              onClick={(e) =>
                                setEstimatedDimensionTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedDimensionTemporary === "3" ||
                                estimatedDimension === "3"
                              }
                            />
                          </div>
                          <div>
                            <label
                              type="radio"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="label"
                              htmlFor="lean"
                            >
                              <img
                                alt="Body-dimensions"
                                src={leanDimensions}
                                style={{ width: "100px", marginRight: " 10px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`lean`}
                              name="dimensions"
                              label={`Lean`}
                              value="2"
                              onClick={(e) =>
                                setEstimatedDimensionTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedDimensionTemporary === "2" ||
                                estimatedDimension === "2"
                              }
                            />
                          </div>
                          <div>
                            <label
                              type="radio"
                              style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="label"
                              htmlFor="underweight"
                            >
                              <img
                                alt="Body-dimensions"
                                src={underweightDimensions}
                                style={{ width: "100px" }}
                              />
                            </label>
                            <Form.Check
                              type="radio"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                padding: "0px",
                              }}
                              id={`underweight`}
                              name="dimensions"
                              label={`Underweight`}
                              value="1"
                              onClick={(e) =>
                                setEstimatedDimensionTemporary(e.target.value)
                              }
                              defaultChecked={
                                estimatedDimensionTemporary === "1" ||
                                estimatedDimension === "1"
                              }
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                {errorMessage === "Red-marked parameters must be completed" &&
                metric === "accurate" ? (
                  <p style={{ color: "red" }}>{errorMessage}</p>
                ) : errorMessage &&
                  metric !== "accurate" &&
                  errorMessage !== "Red-marked parameters must be completed" ? (
                  <p style={{ color: "red" }}>{errorMessage}</p>
                ) : (
                  ""
                )}
                <Button variant="secondary" onClick={handleCloseAppearance}>
                  Cancel
                </Button>
                <Button
                  variant="success"
                  onClick={(event) => handleSubmitCheck(event)}
                >
                  Save changes
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
          <NavLink to="/">
            <Button
              style={{ position: "relative", top: "-3px" }}
              variant="outline-dark"
            >
              <i className="fa-solid fa-xmark"></i>
            </Button>
          </NavLink>
          <PopupButton isAdmin={false} userEmail={currentUserFetched?.email} />
        </div>
        <div className="buttonsGroupResponsive" style={{ float: "right" }}>
          <ButtonGroup vertical>
            <Button
              variant="primary"
              active
              style={{ display: "block", marginBottom: "1px" }}
            >
              My Wardrobe
            </Button>
            <Button
              variant="primary"
              style={{ display: "block", marginBottom: "1px" }}
              onClick={handleShowAppearance}
            >
              My Appearance
            </Button>
            <Button
              variant="primary"
              style={{ display: "block", marginBottom: "1px" }}
              onClick={handleShowPreferences}
            >
              Style Preferences
            </Button>
            <Button
              variant="outline-danger"
              style={{ display: "block", marginBottom: "1px" }}
              onClick={handleShowLogout}
            >
              Logout
            </Button>
          </ButtonGroup>
        </div>
        <div className="container overflow-auto">
          <Wardrobe userEmail={currentUserFetched?.email} />
        </div>
      </div>
    </div>
  );
}

export default PopupDisplay;
