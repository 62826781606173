import "./admin.css";
import Cookies from "js-cookie";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import StoreWardrobe from "../../comps/StoreWardrobe/StoreWardrobe";
import PopupButton from "../../comps/header/PopupButton/PopupButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowLogout,
  setShowUsers,
  setShowNewUser,
  setEmail,
  setPassword,
  setUserHeight,
  setUserBust,
  setUserWaist,
  setUserHips,
  setUserSkin,
  setAccount,
  setErrorMessage,
  setShowTrashcan,
  addChange,
  setChanges,
  fetchUsers,
} from "./adminSlice";
import { setLogedin } from "../../utils/DisplayContainer/displayContainerSlice";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { NavLink } from "react-router-dom";
import api from "../../api/api";

let height = [];
for (let index = 138; index < 199; index++) {
  height.push(
    <option key={index} value={index}>
      {index}
    </option>
  );
}
let bust = [];
for (let index = 57; index < 139; index++) {
  bust.push(
    <option key={index} value={index}>
      {index}
    </option>
  );
}
let waist = [];
for (let index = 39; index < 123; index++) {
  waist.push(
    <option key={index} value={index}>
      {index}
    </option>
  );
}
let hips = [];
for (let index = 68; index < 146; index++) {
  hips.push(
    <option key={index} value={index}>
      {index}
    </option>
  );
}

function Admin() {
  const dispatch = useDispatch();

  const [userEmail, setUserEmail] = useState(false);

  const showLogout = useSelector((state) => state.admin.showLogout);
  const handleCloseLogout = () => {
    dispatch(setShowLogout(false));
  };
  const handleShowLogout = () => {
    dispatch(setShowLogout(true));
  };

  const onLogout = () => {
    dispatch(setLogedin(false));
  };

  const handleLogout = () => {
    Cookies.remove("jwt");
    onLogout();
    handleCloseLogout();
    window.location = "/";
  };

  const showUsers = useSelector((state) => state.admin.showUsers);
  const handleCloseUsers = () => {
    dispatch(setShowUsers(false));
  };
  const handleShowUsers = () => {
    dispatch(fetchUsers());
    dispatch(setShowUsers(true));
  };

  const showNewUser = useSelector((state) => state.admin.showNewUser);
  const handleCloseNewUser = () => {
    handleShowUsers();
    dispatch(setShowNewUser(false));
  };
  const handleShowNewUser = () => {
    handleCloseUsers();
    dispatch(setShowNewUser(true));
  };

  const email = useSelector((state) => state.admin.email);
  const password = useSelector((state) => state.admin.password);
  const userHeight = useSelector((state) => state.admin.userHeight);
  const userBust = useSelector((state) => state.admin.userBust);
  const userWaist = useSelector((state) => state.admin.userWaist);
  const userHips = useSelector((state) => state.admin.userHips);
  const userSkin = useSelector((state) => state.admin.userSkin);
  const account = useSelector((state) => state.admin.account);
  const errorMessage = useSelector((state) => state.admin.errorMessage);

  // * For deleting a user
  const showTrashcan = useSelector((state) => state.admin.showTrashcan);
  const handleClose = () => {
    dispatch(setShowTrashcan(false));
    handleShowUsers();
  };
  const handleShow = (email) => {
    handleCloseUsers();
    dispatch(setShowTrashcan(true));
    setUserEmail(email);
  };
  const handleDelete = async () => {
    try {
      await api.delete(`/users/${userEmail}`);
      alert("User deleted successfully");
      handleClose();
      handleShowUsers();
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  // * For saving changes - user account type
  const usersList = useSelector((state) => state.admin.usersList);
  const changes = useSelector((state) => state.admin.changes);
  const handleChange = (event, user) => {
    const newChange = { value: event.target.value, user: user.id };
    dispatch(addChange(newChange));
  };

  const users = usersList.map((user) => (
    <li style={{ listStyle: "none" }} key={user.id}>
      <FormGroup id={"user:" + user.id}>
        <InputGroup id={"user:" + user.id + "-input"} className="mb-3">
          <Button style={{ display: "contents" }}>
            <OverlayTrigger
              key={"bottom:" + user.id}
              placement="bottom"
              overlay={
                <Tooltip id={`popover-height`}>
                  {user.email} ID:{user.id}
                </Tooltip>
              }
            >
              <InputGroup.Text
                id={"user:" + user.id + "-txt"}
                style={{
                  maxWidth: "300px",
                  overflow: "hidden",
                }}
                className="admin-var-width minWidth"
              >
                {user.email.slice(0, user.email.search(/@/g))}
                <i
                  className="fa-solid fa-up-right-from-square"
                  style={{
                    listStyle: "none",
                    position: "absolute",
                    right: "180px",
                  }}
                ></i>
              </InputGroup.Text>
            </OverlayTrigger>
          </Button>
          <Form.Select
            onChange={(event) => handleChange(event, user)}
            aria-label="Account"
          >
            <option key="Account0" value={user.account}>
              {user.account}
            </option>
            <option
              key="Account1"
              value={
                user.account === "free"
                  ? "paid"
                  : user.account === "paid"
                  ? "free"
                  : "free"
              }
            >
              {user.account === "free"
                ? "paid"
                : user.account === "paid"
                ? "free"
                : "free"}
            </option>
            <option
              key="Account2"
              value={
                user.account === "free"
                  ? "admin"
                  : user.account === "paid"
                  ? "admin"
                  : "paid"
              }
            >
              {user.account === "free"
                ? "admin"
                : user.account === "paid"
                ? "admin"
                : "paid"}
            </option>
          </Form.Select>
          <Button
            onClick={() => handleShow(user.email)}
            variant="outline-danger"
          >
            <i className="fa-solid fa-trash-can"></i>
          </Button>
        </InputGroup>
      </FormGroup>
    </li>
  ));

  function handleSubmit(event) {
    event.preventDefault();

    const filteredUsersList = usersList.filter((user) => {
      const matches = changes.filter((change) => {
        return change.user === user.id;
      });
      return matches.length > 0;
    });

    const updatedUsersList = filteredUsersList.map((user) => {
      const change = [...changes].reverse().find((c) => c.user === user.id);
      if (change) {
        return { ...user, account: change.value };
      }
      return user;
    });
    api.post("updateUsers", { updatedUsersList });
    dispatch(setChanges([]));
    handleCloseUsers();
  }

  function handleSubmitNewUser(event) {
    event.preventDefault();
    // * Check if email already exists in the system
    const existingUser = usersList.find((user) => user.email === email);
    if (existingUser) {
      dispatch(setErrorMessage("This email address is already registered."));
      return;
    } else {
      const formData = {
        activated: true,
        email: email,
        id: Date.now(),
        password: password,
        account: account,
        appearance: {
          accurate: {
            height: userHeight,
            bust: userBust,
            waist: userWaist,
            hips: userHips,
            skin: userSkin,
          },
          estimated: {
            height: "",
            skin: "",
            shape: "",
            dimension: "",
          },
        },
        preferences: [
          { id: "1", no: "1", checked: true, value: "casual" },
          { id: "2", no: "1", checked: true, value: "elegant" },
          { id: "3", no: "1", checked: true, value: "hipster" },
          { id: "4", no: "2", checked: false, value: "casual" },
          { id: "5", no: "2", checked: false, value: "hipster" },
          { id: "6", no: "1", checked: true, value: "rock" },
          { id: "7", no: "2", checked: false, value: "elegant" },
          { id: "8", no: "1", checked: true, value: "sporty" },
          { id: "9", no: "3", checked: false, value: "casual" },
          { id: "10", no: "3", checked: false, value: "elegant" },
          { id: "11", no: "4", checked: false, value: "casual" },
          { id: "12", no: "2", checked: false, value: "rock" },
          { id: "13", no: "4", checked: false, value: "elegant" },
          { id: "14", no: "3", checked: false, value: "rock" },
          { id: "15", no: "2", checked: false, value: "sporty" },
          { id: "16", no: "5", checked: false, value: "elegant" },
          { id: "17", no: "3", checked: false, value: "hipster" },
          { id: "18", no: "5", checked: false, value: "casual" },
          { id: "19", no: "4", checked: false, value: "rock" },
          { id: "20", no: "3", checked: false, value: "sporty" },
          { id: "21", no: "4", checked: false, value: "hipster" },
          { id: "22", no: "4", checked: false, value: "sporty" },
          { id: "23", no: "5", checked: false, value: "hipster" },
          { id: "24", no: "5", checked: false, value: "sporty" },
          { id: "25", no: "5", checked: false, value: "rock" },
        ],
        admin: true,
      };
      // * Clear form and error message
      dispatch(setEmail(""));
      dispatch(setPassword(""));
      dispatch(setErrorMessage(""));

      const addNewUser = async () => {
        try {
          await api.post("/user/register", formData);
        } catch (error) {
          console.error("Failed to add new user: ", error);
        }
      };
      addNewUser();
      setTimeout(handleCloseNewUser, 1000);
      return;
    }
  }

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <div className="bakground">
      <div className="cont">
        <div
          style={{
            fontSize: "150%",
            position: "absolute",
            display: "contents",
          }}
        >
          {/* // * popup for deleting user */}
          <Modal show={showTrashcan} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete User "{userEmail}"?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          {/* // * Popup for "Logout button" */}
          <Modal show={showLogout} onHide={handleCloseLogout}>
            <Modal.Header closeButton>
              <Modal.Title>Logout?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseLogout}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleLogout}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* // * Popup for "Users" containing a form for sending/updating user information */}
          <Modal
            show={showUsers}
            onHide={handleCloseUsers}
            dialogClassName="modalResponsive modal"
            backdrop="static"
            keyboard={false}
          >
            <div>
              <Modal.Header closeButton>
                <Modal.Title>
                  Users
                  <span style={{ marginLeft: "20px" }}>
                    <Button variant="primary" onClick={handleShowNewUser}>
                      New User
                    </Button>
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body bsPrefix="usersListHeight">{users}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseUsers}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleSubmit}>
                  Save changes
                </Button>
              </Modal.Footer>
            </div>
          </Modal>

          {/* // * Popup for "New user" containing a form for new user information */}
          <Modal
            show={showNewUser}
            onHide={handleCloseNewUser}
            dialogClassName="modal"
            backdrop="static"
            keyboard={false}
          >
            <Form onSubmit={handleSubmitNewUser}>
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>New User</Modal.Title>
                </Modal.Header>
                <Modal.Body bsPrefix="usersListHeight">
                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => dispatch(setEmail(e.target.value))}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => dispatch(setPassword(e.target.value))}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formAccount">
                    <Form.Label>Account</Form.Label>
                    <Form.Select
                      onChange={(e) => dispatch(setAccount(e.target.value))}
                      aria-label="Account"
                    >
                      <option key="Account0" value="free">
                        Free
                      </option>
                      <option key="Account1" value="paid">
                        Paid
                      </option>
                      <option key="Account2" value="admin">
                        Admin
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <br />
                  <FormGroup id="height">
                    <InputGroup id="height-input" className="mb-3">
                      <InputGroup.Text id="height-txt" className="var-width">
                        Height in cm
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Height"
                        onChange={(e) =>
                          dispatch(setUserHeight(e.target.value))
                        }
                      >
                        <option key="137" value="137">
                          137 and below
                        </option>
                        {height}
                        <option key="199" value="199">
                          199 and above
                        </option>
                      </Form.Select>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup id="bust">
                    <InputGroup id="bust-input" className="mb-3">
                      <InputGroup.Text id="bust-txt" className="var-width">
                        Bust in cm
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Bust"
                        onChange={(e) => dispatch(setUserBust(e.target.value))}
                      >
                        <option key="56" value="56">
                          56 and below
                        </option>
                        {bust}
                        <option key="139" value="139">
                          139 and above
                        </option>
                      </Form.Select>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup id="waist">
                    <InputGroup id="waist-input" className="mb-3">
                      <InputGroup.Text id="waist-txt" className="var-width">
                        Waist in cm
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Waist"
                        onChange={(e) => dispatch(setUserWaist(e.target.value))}
                      >
                        <option key="38" value="38">
                          38 and below
                        </option>
                        {waist}
                        <option key="123" value="123">
                          123 and above
                        </option>
                      </Form.Select>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup id="hips">
                    <InputGroup id="hips-input" className="mb-3">
                      <InputGroup.Text id="hips-txt" className="var-width">
                        Hips in cm
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Hips"
                        onChange={(e) => dispatch(setUserHips(e.target.value))}
                      >
                        <option key="67" value="67">
                          67 and below
                        </option>
                        {hips}
                        <option key="146" value="146">
                          146 and above
                        </option>
                      </Form.Select>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup id="skin">
                    <InputGroup id="skin-input" className="mb-3">
                      <InputGroup.Text id="skin-txt" className="var-width">
                        Skin color
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Skin"
                        onChange={(e) => dispatch(setUserSkin(e.target.value))}
                      >
                        <option key="1" value="1">
                          Very light skin tone
                        </option>
                        <option key="2" value="2">
                          Light skin tone
                        </option>
                        <option key="3" value="3">
                          Medium skin tone
                        </option>
                        <option key="4" value="4">
                          Dark skin tone
                        </option>
                        <option key="5" value="5">
                          Very Dark skin tone
                        </option>
                      </Form.Select>
                    </InputGroup>
                  </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseNewUser}>
                    Cancel
                  </Button>
                  <Button variant="success" type="submit">
                    Save changes
                  </Button>
                </Modal.Footer>
              </div>
              {errorMessage && (
                <p style={{ position: "relative", left: "10px", color: "red" }}>
                  {errorMessage}
                </p>
              )}
            </Form>
          </Modal>
          <NavLink to="/">
            <Button
              style={{ position: "relative", top: "-3px" }}
              variant="outline-dark"
            >
              <i className="fa-solid fa-xmark"></i>
            </Button>
          </NavLink>
          <PopupButton isAdmin={true} />
        </div>
        <div className="buttonsGroupResponsive" style={{ float: "right" }}>
          <ButtonGroup vertical>
            <Button
              variant="primary"
              active
              style={{ display: "block", marginBottom: "1px" }}
            >
              Store Wardrobe
            </Button>
            <Button
              variant="primary"
              style={{ display: "block", marginBottom: "1px" }}
              onClick={handleShowUsers}
            >
              Users
            </Button>
            <Button
              variant="outline-danger"
              style={{ display: "block", marginBottom: "1px" }}
              onClick={handleShowLogout}
            >
              Logout
            </Button>
          </ButtonGroup>
        </div>
        <div className="container overflow-auto">
          <StoreWardrobe isAdmin={true} />
        </div>
      </div>
    </div>
  );
}

export default Admin;
