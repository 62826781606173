import Login from "../../comps/Login/Login";
import Signup from "../../comps/Signup/Signup";
import Reset from "../../comps/Reset/Reset";

function SignInUpContainer(props) {
  return (
    <div>{props.signup ? <Signup /> : props.reset ? <Reset /> : <Login />}</div>
  );
}

export default SignInUpContainer;
