import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  targetValues: [
    { name: "upperBody", value: false, title: "Upper body" },
    { name: "lowerBody", value: false, title: "Lower body" },
    { name: "wholeBody", value: false, title: "Whole body" },
    { name: "shoes", value: false, title: "Shoes" },
    { name: "accessories", value: false, title: "Accessories" },
  ],
  colorValues: [
    { name: "black", value: false, title: "Black" },
    { name: "white", value: false, title: "White" },
    { name: "red", value: false, title: "Red" },
    { name: "blue", value: false, title: "Blue" },
    { name: "green", value: false, title: "Green" },
    { name: "yellow", value: false, title: "Yellow" },
    { name: "gray", value: false, title: "Gray" },
    { name: "beige", value: false, title: "Beige" },
    { name: "pink", value: false, title: "Pink" },
    { name: "purple", value: false, title: "Purple" },
    { name: "azure", value: false, title: "Azure" },
    { name: "turquoise", value: false, title: "Turquoise" },
    { name: "orange", value: false, title: "Orange" },
    { name: "brown", value: false, title: "Brown" },
    { name: "gold", value: false, title: "Gold" },
    { name: "silver", value: false, title: "Silver" },
    { name: "nude", value: false, title: "Nude" },
    { name: "transparent", value: false, title: "Transparent" },
  ],
  targetValuesStore: [
    { name: "upperBody", value: false, title: "Upper body" },
    { name: "lowerBody", value: false, title: "Lower body" },
    { name: "wholeBody", value: false, title: "Whole body" },
    { name: "shoes", value: false, title: "Shoes" },
    { name: "accessories", value: false, title: "Accessories" },
  ],
  colorValuesStore: [
    { name: "black", value: false, title: "Black" },
    { name: "white", value: false, title: "White" },
    { name: "red", value: false, title: "Red" },
    { name: "blue", value: false, title: "Blue" },
    { name: "green", value: false, title: "Green" },
    { name: "yellow", value: false, title: "Yellow" },
    { name: "gray", value: false, title: "Gray" },
    { name: "beige", value: false, title: "Beige" },
    { name: "pink", value: false, title: "Pink" },
    { name: "purple", value: false, title: "Purple" },
    { name: "azure", value: false, title: "Azure" },
    { name: "turquoise", value: false, title: "Turquoise" },
    { name: "orange", value: false, title: "Orange" },
    { name: "brown", value: false, title: "Brown" },
    { name: "gold", value: false, title: "Gold" },
    { name: "silver", value: false, title: "Silver" },
    { name: "nude", value: false, title: "Nude" },
    { name: "transparent", value: false, title: "Transparent" },
  ],
  targetValuesAdmin: [
    { name: "upperBody", value: false, title: "Upper body" },
    { name: "lowerBody", value: false, title: "Lower body" },
    { name: "wholeBody", value: false, title: "Whole body" },
    { name: "shoes", value: false, title: "Shoes" },
    { name: "accessories", value: false, title: "Accessories" },
  ],
  colorValuesAdmin: [
    { name: "black", value: false, title: "Black" },
    { name: "white", value: false, title: "White" },
    { name: "red", value: false, title: "Red" },
    { name: "blue", value: false, title: "Blue" },
    { name: "green", value: false, title: "Green" },
    { name: "yellow", value: false, title: "Yellow" },
    { name: "gray", value: false, title: "Gray" },
    { name: "beige", value: false, title: "Beige" },
    { name: "pink", value: false, title: "Pink" },
    { name: "purple", value: false, title: "Purple" },
    { name: "azure", value: false, title: "Azure" },
    { name: "turquoise", value: false, title: "Turquoise" },
    { name: "orange", value: false, title: "Orange" },
    { name: "brown", value: false, title: "Brown" },
    { name: "gold", value: false, title: "Gold" },
    { name: "silver", value: false, title: "Silver" },
    { name: "nude", value: false, title: "Nude" },
    { name: "transparent", value: false, title: "Transparent" },
  ],
  targetValuesChecked: [
    { name: "upperBody", value: false, title: "Upper body" },
    { name: "lowerBody", value: false, title: "Lower body" },
    { name: "wholeBody", value: false, title: "Whole body" },
    { name: "shoes", value: false, title: "Shoes" },
    { name: "accessories", value: false, title: "Accessories" },
  ],
  colorValuesChecked: [
    { name: "black", value: false, title: "Black" },
    { name: "white", value: false, title: "White" },
    { name: "red", value: false, title: "Red" },
    { name: "blue", value: false, title: "Blue" },
    { name: "green", value: false, title: "Green" },
    { name: "yellow", value: false, title: "Yellow" },
    { name: "gray", value: false, title: "Gray" },
    { name: "beige", value: false, title: "Beige" },
    { name: "pink", value: false, title: "Pink" },
    { name: "purple", value: false, title: "Purple" },
    { name: "azure", value: false, title: "Azure" },
    { name: "turquoise", value: false, title: "Turquoise" },
    { name: "orange", value: false, title: "Orange" },
    { name: "brown", value: false, title: "Brown" },
    { name: "gold", value: false, title: "Gold" },
    { name: "silver", value: false, title: "Silver" },
    { name: "nude", value: false, title: "Nude" },
    { name: "transparent", value: false, title: "Transparent" },
  ],
  targetValuesCheckedStore: [
    { name: "upperBody", value: false, title: "Upper body" },
    { name: "lowerBody", value: false, title: "Lower body" },
    { name: "wholeBody", value: false, title: "Whole body" },
    { name: "shoes", value: false, title: "Shoes" },
    { name: "accessories", value: false, title: "Accessories" },
  ],
  colorValuesCheckedStore: [
    { name: "black", value: false, title: "Black" },
    { name: "white", value: false, title: "White" },
    { name: "red", value: false, title: "Red" },
    { name: "blue", value: false, title: "Blue" },
    { name: "green", value: false, title: "Green" },
    { name: "yellow", value: false, title: "Yellow" },
    { name: "gray", value: false, title: "Gray" },
    { name: "beige", value: false, title: "Beige" },
    { name: "pink", value: false, title: "Pink" },
    { name: "purple", value: false, title: "Purple" },
    { name: "azure", value: false, title: "Azure" },
    { name: "turquoise", value: false, title: "Turquoise" },
    { name: "orange", value: false, title: "Orange" },
    { name: "brown", value: false, title: "Brown" },
    { name: "gold", value: false, title: "Gold" },
    { name: "silver", value: false, title: "Silver" },
    { name: "nude", value: false, title: "Nude" },
    { name: "transparent", value: false, title: "Transparent" },
  ],
  targetValuesCheckedAdmin: [
    { name: "upperBody", value: false, title: "Upper body" },
    { name: "lowerBody", value: false, title: "Lower body" },
    { name: "wholeBody", value: false, title: "Whole body" },
    { name: "shoes", value: false, title: "Shoes" },
    { name: "accessories", value: false, title: "Accessories" },
  ],
  colorValuesCheckedAdmin: [
    { name: "black", value: false, title: "Black" },
    { name: "white", value: false, title: "White" },
    { name: "red", value: false, title: "Red" },
    { name: "blue", value: false, title: "Blue" },
    { name: "green", value: false, title: "Green" },
    { name: "yellow", value: false, title: "Yellow" },
    { name: "gray", value: false, title: "Gray" },
    { name: "beige", value: false, title: "Beige" },
    { name: "pink", value: false, title: "Pink" },
    { name: "purple", value: false, title: "Purple" },
    { name: "azure", value: false, title: "Azure" },
    { name: "turquoise", value: false, title: "Turquoise" },
    { name: "orange", value: false, title: "Orange" },
    { name: "brown", value: false, title: "Brown" },
    { name: "gold", value: false, title: "Gold" },
    { name: "silver", value: false, title: "Silver" },
    { name: "nude", value: false, title: "Nude" },
    { name: "transparent", value: false, title: "Transparent" },
  ],
  isChecked: false,
  usedByStore: false,
  usedByUser: false,
  usedByAdmin: false,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    updateTargetValue: (state, action) => {
      const { name, checked } = action.payload;
      state.targetValues = state.targetValues.map((item) =>
        item.name === name ? { ...item, value: checked } : item
      );
    },
    updateColorValue: (state, action) => {
      const { name, checked } = action.payload;
      state.colorValues = state.colorValues.map((item) =>
        item.name === name ? { ...item, value: checked } : item
      );
    },
    updateTargetValueChecked: (state, action) => {
      const { name, checked } = action.payload;
      state.targetValuesChecked = state.targetValuesChecked.map((item) =>
        item.name === name ? { ...item, value: checked } : item
      );
    },
    updateColorValueChecked: (state, action) => {
      const { name, checked } = action.payload;
      state.colorValuesChecked = state.colorValuesChecked.map((item) =>
        item.name === name ? { ...item, value: checked } : item
      );
    },
    updateTargetValueCheckedStore: (state, action) => {
      const { name, checked } = action.payload;
      state.targetValuesCheckedStore = state.targetValuesCheckedStore.map(
        (item) => (item.name === name ? { ...item, value: checked } : item)
      );
    },
    updateColorValueCheckedStore: (state, action) => {
      const { name, checked } = action.payload;
      state.colorValuesCheckedStore = state.colorValuesCheckedStore.map(
        (item) => (item.name === name ? { ...item, value: checked } : item)
      );
    },
    updateTargetValueCheckedAdmin: (state, action) => {
      const { name, checked } = action.payload;
      state.targetValuesCheckedAdmin = state.targetValuesCheckedAdmin.map(
        (item) => (item.name === name ? { ...item, value: checked } : item)
      );
    },
    updateColorValueCheckedAdmin: (state, action) => {
      const { name, checked } = action.payload;
      state.colorValuesCheckedAdmin = state.colorValuesCheckedAdmin.map(
        (item) => (item.name === name ? { ...item, value: checked } : item)
      );
    },
    convertTargetValuesToChecked: (state) => {
      state.targetValues = state.targetValuesChecked.map((item) => ({
        ...item,
      }));
    },
    convertTargetValuesCheckedToTargetValues: (state) => {
      state.targetValuesChecked = state.targetValues.map((item) => ({
        ...item,
      }));
    },
    convertTargetValuesToCheckedStore: (state) => {
      state.targetValuesStore = state.targetValuesCheckedStore.map((item) => ({
        ...item,
      }));
    },
    convertTargetValuesCheckedStoreToTargetValuesStore: (state) => {
      state.targetValuesCheckedStore = state.targetValuesStore.map((item) => ({
        ...item,
      }));
    },
    convertTargetValuesToCheckedAdmin: (state) => {
      state.targetValuesAdmin = state.targetValuesCheckedAdmin.map((item) => ({
        ...item,
      }));
    },
    convertTargetValuesCheckedAdminToTargetValuesAdmin: (state) => {
      state.targetValuesCheckedAdmin = state.targetValuesAdmin.map((item) => ({
        ...item,
      }));
    },
    convertColorValuesToChecked: (state) => {
      state.colorValues = state.colorValuesChecked.map((item) => ({
        ...item,
      }));
    },
    convertColorValuesCheckedToColorValues: (state) => {
      state.colorValuesChecked = state.colorValues.map((item) => ({
        ...item,
      }));
    },
    convertColorValuesToCheckedStore: (state) => {
      state.colorValuesStore = state.colorValuesCheckedStore.map((item) => ({
        ...item,
      }));
    },
    convertColorValuesCheckedStoreToColorValuesStore: (state) => {
      state.colorValuesCheckedStore = state.colorValuesStore.map((item) => ({
        ...item,
      }));
    },
    convertColorValuesToCheckedAdmin: (state) => {
      state.colorValuesAdmin = state.colorValuesCheckedAdmin.map((item) => ({
        ...item,
      }));
    },
    convertColorValuesCheckedAdminToColorValuesAdmin: (state) => {
      state.colorValuesCheckedAdmin = state.colorValuesAdmin.map((item) => ({
        ...item,
      }));
    },
    resetTargetValues: (state) => {
      state.targetValues = [...initialState.targetValues];
    },
    resetColorValues: (state) => {
      state.colorValues = [...initialState.colorValues];
    },
    resetTargetValuesChecked: (state) => {
      state.targetValuesChecked = [...initialState.targetValuesChecked];
    },
    resetColorValuesChecked: (state) => {
      state.colorValuesChecked = [...initialState.colorValuesChecked];
    },
    resetTargetValuesCheckedStore: (state) => {
      state.targetValuesCheckedStore = [
        ...initialState.targetValuesCheckedStore,
      ];
    },
    resetColorValuesCheckedStore: (state) => {
      state.colorValuesCheckedStore = [...initialState.colorValuesCheckedStore];
    },
    resetTargetValuesCheckedAdmin: (state) => {
      state.targetValuesCheckedAdmin = [
        ...initialState.targetValuesCheckedAdmin,
      ];
    },
    resetColorValuesCheckedAdmin: (state) => {
      state.colorValuesCheckedAdmin = [...initialState.colorValuesCheckedAdmin];
    },
    setUsedByStore: (state, action) => {
      state.usedByStore = action.payload;
    },
    setUsedByUser: (state, action) => {
      state.usedByUser = action.payload;
    },
    setUsedByAdmin: (state, action) => {
      state.usedByAdmin = action.payload;
    },
  },
});

export const {
  updateTargetValue,
  updateColorValue,
  updateTargetValueChecked,
  updateColorValueChecked,
  updateTargetValueCheckedStore,
  updateColorValueCheckedStore,
  updateTargetValueCheckedAdmin,
  updateColorValueCheckedAdmin,
  convertTargetValuesToChecked,
  convertTargetValuesToCheckedStore,
  convertTargetValuesToCheckedAdmin,
  convertColorValuesToChecked,
  convertColorValuesToCheckedStore,
  convertColorValuesToCheckedAdmin,
  convertTargetValuesCheckedToTargetValues,
  convertTargetValuesCheckedStoreToTargetValuesStore,
  convertTargetValuesCheckedAdminToTargetValuesAdmin,
  convertColorValuesCheckedToColorValues,
  convertColorValuesCheckedStoreToColorValuesStore,
  convertColorValuesCheckedAdminToColorValuesAdmin,
  resetTargetValues,
  resetColorValues,
  resetTargetValuesChecked,
  resetColorValuesChecked,
  resetTargetValuesCheckedStore,
  resetColorValuesCheckedStore,
  resetTargetValuesCheckedAdmin,
  resetColorValuesCheckedAdmin,
  setUsedByStore,
  setUsedByUser,
  setUsedByAdmin,
} = filterSlice.actions;
export default filterSlice.reducer;
