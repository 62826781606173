import { useDispatch, useSelector } from "react-redux";
import {
  setShowAddNewItem,
  setShowFilter,
  setShowForNewUserItem,
  setShowFilterUserAddingItemToTheirWardrobe,
} from "./popupButtonSlice";
import {
  convertTargetValuesCheckedToTargetValues,
  convertTargetValuesCheckedStoreToTargetValuesStore,
  convertTargetValuesCheckedAdminToTargetValuesAdmin,
  convertColorValuesCheckedToColorValues,
  convertColorValuesCheckedStoreToColorValuesStore,
  convertColorValuesCheckedAdminToColorValuesAdmin,
} from "../../Filter/filterSlice";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Filter from "../../Filter/Filter";
import Modal from "react-bootstrap/Modal";
import "./popupButton.css";
import StoreWardrobe from "../../StoreWardrobe/StoreWardrobe";
import AddItemToStoreWardrobe from "../../Filter/AddItemToStoreWardrobe";

function PopupButton(props) {
  const dispatch = useDispatch();
  // * For adding a new item to the store
  const showAddNewItem = useSelector(
    (state) => state.popupButton.showAddNewItem
  );

  const showFilter = useSelector((state) => state.popupButton.showFilter);

  // * For adding a new item to a user's wardrobe
  const showForNewUserItem = useSelector(
    (state) => state.popupButton.showForNewUserItem
  );

  const showFilterUserAddingItemToTheirWardrobe = useSelector(
    (state) => state.popupButton.showFilterUserAddingItemToTheirWardrobe
  );

  const handleCloseForNewItem = () => dispatch(setShowAddNewItem(false));
  const handleShowForNewItem = () => dispatch(setShowAddNewItem(true));

  const handleClose = () => dispatch(setShowFilter(false));
  const handleShow = () => {
    dispatch(convertTargetValuesCheckedToTargetValues());
    dispatch(convertColorValuesCheckedToColorValues());
    dispatch(setShowFilter(true));
  };
  const handleShowAdmin = () => {
    dispatch(convertTargetValuesCheckedAdminToTargetValuesAdmin());
    dispatch(convertColorValuesCheckedAdminToColorValuesAdmin());
    dispatch(setShowFilter(true));
  };

  const handleCloseForNewUserItem = () =>
    dispatch(setShowForNewUserItem(false));
  const handleShowForNewUserItem = () => dispatch(setShowForNewUserItem(true));

  const handleCloseUserAddingItemToTheirWardrobe = () =>
    dispatch(setShowFilterUserAddingItemToTheirWardrobe(false));
  const handleShowUserAddingItemToTheirWardrobe = () => {
    dispatch(convertTargetValuesCheckedStoreToTargetValuesStore());
    dispatch(convertColorValuesCheckedStoreToColorValuesStore());
    dispatch(setShowFilterUserAddingItemToTheirWardrobe(true));
  };

  // * Popup for adding a new item to the store
  <Offcanvas
    show={showAddNewItem}
    onHide={handleCloseForNewItem}
    scroll={true}
    backdrop={false}
    style={{ zIndex: "10000" }}
  >
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Set new itme</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <AddItemToStoreWardrobe />
    </Offcanvas.Body>
  </Offcanvas>;

  let isAdmin = props.isAdmin;
  let btnCont = !isAdmin ? (
    <span>
      <Button
        variant="light"
        style={{ border: "black solid 1px" }}
        onClick={handleShowForNewUserItem}
      >
        <span className="responsiveDisplayNone">Add item</span>{" "}
        <i className="fa-solid fa-shirt"></i>
      </Button>
      {/* // * Popup for adding a new item to a user's wardrobe */}
      <Modal
        show={showForNewUserItem}
        onHide={handleCloseForNewUserItem}
        dialogClassName="modalResponsive"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Store items{" "}
            <Button
              variant="light"
              style={{ border: "black solid 1px" }}
              onClick={handleShowUserAddingItemToTheirWardrobe}
            >
              Filter <i className="fa-solid fa-filter"></i>
            </Button>
            <h6>Add items that are similar to those in your own wardrobe</h6>
            <Offcanvas
              show={showFilterUserAddingItemToTheirWardrobe}
              onHide={handleCloseUserAddingItemToTheirWardrobe}
              scroll={true}
              add="css"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filter</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Filter usedBy={"addFromStore"} />
              </Offcanvas.Body>
            </Offcanvas>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StoreWardrobe
            add={true}
            isAdmin={isAdmin}
            userEmail={props.userEmail}
          />
        </Modal.Body>
      </Modal>{" "}
      <Button
        variant="light"
        style={{ border: "black solid 1px" }}
        onClick={handleShow}
      >
        <span className="responsiveDisplayNone"> Filter</span>{" "}
        <i className="fa-solid fa-filter"></i>
      </Button>
      <Offcanvas
        show={showFilter}
        onHide={handleClose}
        scroll={true}
        backdrop={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Filter usedBy={"userWardrobe"} />
        </Offcanvas.Body>
      </Offcanvas>
    </span>
  ) : (
    <span>
      <Button
        variant="light"
        style={{ border: "black solid 1px" }}
        onClick={handleShowForNewItem}
      >
        <span className="responsiveDisplayNone">Add item</span>{" "}
        <i className="fa-solid fa-shirt"></i>
      </Button>
      {/* // * Popup for adding a new item to the store */}
      <Offcanvas
        show={showAddNewItem}
        onHide={handleCloseForNewItem}
        scroll={true}
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Set new itme</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddItemToStoreWardrobe />
        </Offcanvas.Body>
      </Offcanvas>{" "}
      <Button
        variant="light"
        style={{ border: "black solid 1px" }}
        onClick={handleShowAdmin}
      >
        <span className="responsiveDisplayNone"> Filter</span>{" "}
        <i className="fa-solid fa-filter"></i>
      </Button>
      <Offcanvas
        show={showFilter}
        onHide={handleClose}
        scroll={true}
        backdrop={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Filter usedBy={"adminWardrobe"} />
        </Offcanvas.Body>
      </Offcanvas>
    </span>
  );

  return <div className="popup-btn-pos">{btnCont}</div>;
}

export default PopupButton;
