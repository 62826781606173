import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterParams: {},
};

const filterContextSlice = createSlice({
  name: "filterContext",
  initialState,
  reducers: {
    setFilterParams: (state, action) => {
      state.filterParams = action.payload;
    },
  },
});

export const { setFilterParams } = filterContextSlice.actions;
export default filterContextSlice.reducer;
