import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // * עבור בחירות העדפות סטייל
  // TODO: אם קיים, למשוך מידע ממשתמש ולהציב במקום
  stylesImages: [
    { id: "1", no: "1", checked: false, value: "casual" },
    { id: "2", no: "1", checked: false, value: "elegant" },
    { id: "3", no: "1", checked: false, value: "hipster" },
    { id: "4", no: "2", checked: false, value: "casual" },
    { id: "5", no: "2", checked: false, value: "hipster" },
    { id: "6", no: "1", checked: false, value: "rock" },
    { id: "7", no: "2", checked: false, value: "elegant" },
    { id: "8", no: "1", checked: false, value: "sporty" },
    { id: "9", no: "3", checked: false, value: "casual" },
    { id: "10", no: "3", checked: false, value: "elegant" },
    { id: "11", no: "4", checked: false, value: "casual" },
    { id: "12", no: "2", checked: false, value: "rock" },
    { id: "13", no: "4", checked: false, value: "elegant" },
    { id: "14", no: "3", checked: false, value: "rock" },
    { id: "15", no: "2", checked: false, value: "sporty" },
    { id: "16", no: "5", checked: false, value: "elegant" },
    { id: "17", no: "3", checked: false, value: "hipster" },
    { id: "18", no: "5", checked: false, value: "casual" },
    { id: "19", no: "4", checked: false, value: "rock" },
    { id: "20", no: "3", checked: false, value: "sporty" },
    { id: "21", no: "4", checked: false, value: "hipster" },
    { id: "22", no: "4", checked: false, value: "sporty" },
    { id: "23", no: "5", checked: false, value: "hipster" },
    { id: "24", no: "5", checked: false, value: "sporty" },
    { id: "25", no: "5", checked: false, value: "rock" },
  ],
  showLogout: false,
  showPreferences: false,
  showAppearance: false,
  metric: "accurate",
  accurateAge: "",
  accurateGender: "female",
  accurateWeight: "",
  accurateWrist: "",
  accurateHeight: "",
  accurateBust: "",
  accurateWaist: "",
  accurateHips: "",
  accurateSkin: "1",
  estimatedAge: "19",
  estimatedGender: "female",
  estimatedHeight: "small",
  estimatedSkin: "1",
  estimatedShape: "",
  estimatedDimension: "",
  errorMessage: "",
  itemToHandle: "",
};

const popupDisplaySlice = createSlice({
  name: "popupDisplay",
  initialState,
  reducers: {
    setStylesImages: (state, action) => {
      state.stylesImages = action.payload;
    },
    setShowLogout: (state, action) => {
      state.showLogout = action.payload;
    },
    setShowPreferences: (state, action) => {
      state.showPreferences = action.payload;
    },
    setShowAppearance: (state, action) => {
      state.showAppearance = action.payload;
    },
    setMetric: (state, action) => {
      state.metric = action.payload;
    },
    setAccurateAge: (state, action) => {
      state.accurateAge = action.payload;
    },
    setAccurateGender: (state, action) => {
      state.accurateGender = action.payload;
    },
    setAccurateWeight: (state, action) => {
      state.accurateWeight = action.payload;
    },
    setAccurateWrist: (state, action) => {
      state.accurateWrist = action.payload;
    },
    setAccurateHeight: (state, action) => {
      state.accurateHeight = action.payload;
    },
    setAccurateBust: (state, action) => {
      state.accurateBust = action.payload;
    },
    setAccurateWaist: (state, action) => {
      state.accurateWaist = action.payload;
    },
    setAccurateHips: (state, action) => {
      state.accurateHips = action.payload;
    },
    setAccurateSkin: (state, action) => {
      state.accurateSkin = action.payload;
    },
    setEstimatedAge: (state, action) => {
      state.estimatedAge = action.payload;
    },
    setEstimatedGender: (state, action) => {
      state.estimatedGender = action.payload;
    },
    setEstimatedHeight: (state, action) => {
      state.estimatedHeight = action.payload;
    },
    setEstimatedSkin: (state, action) => {
      state.estimatedSkin = action.payload;
    },
    setEstimatedShape: (state, action) => {
      state.estimatedShape = action.payload;
    },
    setEstimatedDimension: (state, action) => {
      state.estimatedDimension = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setItemToHandle: (state, action) => {
      state.itemToHandle = action.payload;
    },
  },
});

export const {
  setStylesImages,
  setShowLogout,
  setShowPreferences,
  setShowAppearance,
  setMetric,
  setAccurateAge,
  setAccurateGender,
  setAccurateWeight,
  setAccurateWrist,
  setAccurateHeight,
  setAccurateBust,
  setAccurateWaist,
  setAccurateHips,
  setAccurateSkin,
  setEstimatedAge,
  setEstimatedGender,
  setEstimatedHeight,
  setEstimatedSkin,
  setEstimatedShape,
  setEstimatedDimension,
  setErrorMessage,
  setItemToHandle,
} = popupDisplaySlice.actions;

export default popupDisplaySlice.reducer;
