import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  doneUserMessageEnd: false,
  loaderVar: false,
  showFilter: false,
  showLogin: false,
  signup: false,
  StylesCopy: "",
  SeasonsCopy: "",
};

const loginButtonSlice = createSlice({
  name: "loginButton",
  initialState,
  reducers: {
    setDoneUserMessageEnd: (state, action) => {
      state.doneUserMessageEnd = action.payload;
    },
    setLoaderVar: (state, action) => {
      state.loaderVar = action.payload;
    },
    setShowFilter: (state, action) => {
      state.showFilter = action.payload;
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload;
    },
    setSignup: (state, action) => {
      state.signup = action.payload;
    },
    setStylesCopy: (state, action) => {
      state.StylesCopy = action.payload;
    },
    setSeasonsCopy: (state, action) => {
      state.SeasonsCopy = action.payload;
    },
  },
});

export const {
  setDoneUserMessageEnd,
  setLoaderVar,
  setShowFilter,
  setShowLogin,
  setSignup,
  setStylesCopy,
  setSeasonsCopy,
} = loginButtonSlice.actions;

export default loginButtonSlice.reducer;
