import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowTrashcan,
  setShowLookAlike,
  setshowTrashcanLookAlike,
  setFile,
  setFileName,
  setUserItemsArray,
  setCopyUserItemsArray,
  setAddedItem,
  setMainImageRenderView,
} from "./wardrobeSlice";
import { setItemToHandle } from "../../utils/PopupDisplay/popupDisplaySlice";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import DisplayBox from "../DisplayBox/DisplayBox";
import "./wardrobe.css";
import api from "../../api/api";

const apiUrl = process.env.REACT_APP_LOCAL
  ? "http://localhost:4000"
  : process.env.REACT_APP_STORAGE;
const imagesServerFolder = "4000";

function Wardrobe(props) {
  const dispatch = useDispatch();

  let userItemsArray = useSelector((state) => state.wardrobe.userItemsArray);
  // * Necessary to filter the display of filtered items
  let copyUserItemsArray = useSelector(
    (state) => state.wardrobe.copyUserItemsArray
  );

  // * For deleting an item
  const showTrashcan = useSelector((state) => state.wardrobe.showTrashcan);
  const handleClose = () => {
    dispatch(setShowTrashcan(false));
  };
  const handleShow = (imagePath) => {
    dispatch(setItemToHandle(imagePath));
    dispatch(setShowTrashcan(true));
  };
  async function handleDeleteItemFromWardrobe(itemToHandle) {
    let fileName = itemToHandle.substring(itemToHandle.lastIndexOf("/") + 1);
    try {
      await api.delete(
        `updateUserWardrobe/deleteItem/${props.userEmail}/${fileName}`
      );
      alert("Item deleted successfully");
      dispatch(setAddedItem(addedItem - 1));
      handleClose();
    } catch (error) {
      console.log("Error fetching file path:", error);
    }
    // * For the user and the list of outfits on the home page to be updated according to the user's change
    Cookies.set("sessionCookie", false);
  }

  // * For displaying a relevant image after clicking on an item
  const itemToHandle = useSelector((state) => state.popupDisplay.itemToHandle);

  // * For a look-alike item image
  const showLookAlike = useSelector((state) => state.wardrobe.showLookAlike);
  const handleCloseForLookAlike = () => {
    dispatch(setShowLookAlike(false));
    setFileInput(false);
    setCheckboxVar(false);
    if (file) {
      dispatch(setFile(""));
    }
  };
  // * For saving & rendering changes regarding look-alike images
  const mainImageRenderView = useSelector(
    (state) => state.wardrobe.mainImageRenderView
  );
  const [mainImage, setMainImage] = useState(false);
  const [mainImage2, setMainImage2] = useState(false);
  const handleShowForLookAlike = async (imagePath, fileName) => {
    setImagePath(imagePath);
    dispatch(setFileName(fileName));
    let lookalikefilepath = "";
    try {
      lookalikefilepath = await api.get(
        `updateUserWardrobe/lookalikefilepath/${props.userEmail}/${fileName}`
      );
    } catch (error) {
      console.log("Error fetching file path:", error);
    }
    setMainImage(
      lookalikefilepath.data.mainImage
        ? lookalikefilepath.data.mainImage
        : false
    );
    setMainImage2(
      lookalikefilepath.data.mainImage
        ? lookalikefilepath.data.mainImage
        : false
    );
    // let imageUrl = "";
    // try {
    // const checkUpload = lookalikefilepath.data.filePath.includes("uploads")
    //   ? lookalikefilepath.data.filePath.split("uploads")[1]
    //   : lookalikefilepath.data.filePath.split("upload")[1];
    const imageUrl = lookalikefilepath.data.filePath
      ? lookalikefilepath.data.filePath
      : `${apiUrl}/images/${fileName}.0.jpg`;
    // } catch (error) {}
    // dispatch(setItemToHandle(imageUrl ? imageUrl : imagePath));
    dispatch(setItemToHandle(imageUrl));
    dispatch(setShowLookAlike(true));
  };

  // * For look-alike item image deletion
  const [imagePath, setImagePath] = useState(false);
  const showTrashcanLookAlike = useSelector(
    (state) => state.wardrobe.showTrashcanLookAlike
  );
  const handleDeleteLookAlikeFile = async () => {
    try {
      await api.delete(
        `updateUserWardrobe/deleteLookalike/${props.userEmail}/${fileName}`
      );
      handleCloseForTrashcanLookAlike();
      handleCloseForLookAlike();
      handleShowForLookAlike(imagePath, fileName);
      dispatch(setMainImageRenderView(mainImageRenderView + 5));
    } catch (error) {
      console.log("Error fetching file path:", error);
    }
  };
  const handleCloseForTrashcanLookAlike = () => {
    dispatch(setshowTrashcanLookAlike(false));
  };
  const handleShowForTrashcanLookAlike = () => {
    dispatch(setshowTrashcanLookAlike(true));
  };

  // * Saving an uploaded image as a variable
  const file = useSelector((state) => state.wardrobe.file);
  // * Toolkit store is not recommended for saving a file type as a value in a var
  const [fileInput, setFileInput] = useState(false);
  const [checkboxVar, setCheckboxVar] = useState(false);
  const fileName = useSelector((state) => state.wardrobe.fileName);
  const handleUpload = (e) => {
    dispatch(setFile(URL.createObjectURL(e.target.files[0])));
    setFileInput(e.target.files[0]);
    setCheckboxVar(true);
  };
  const handleMainImageChange = () => {
    setMainImage(!mainImage);
  };

  const addedItem = useSelector((state) => state.wardrobe.addedItem);
  // * Saving a user wardrobe according to the filter
  const filterParams = useSelector((state) => state.filterContext.filterParams);
  useEffect(() => {
    if (Cookies.get("userFilter")) {
      let Filter = JSON.parse(Cookies.get("userFilter"));
      if (Filter.colors.length > 0 || Filter.targets.length > 0) {
        let filteredItems = [];
        const uniqueItems = new Set();
        copyUserItemsArray.forEach((item) => {
          const colorMatch =
            Filter.colors.length === 0 ||
            Filter.colors.some((color) => item.colors.includes(color));

          const targetMatch =
            Filter.targets.length === 0 || Filter.targets.includes(item.target);

          if (
            (Filter.colors.length > 0 &&
              Filter.targets.length > 0 &&
              colorMatch &&
              targetMatch) ||
            (Filter.colors.length > 0 &&
              Filter.targets.length === 0 &&
              colorMatch) ||
            (Filter.colors.length === 0 &&
              Filter.targets.length > 0 &&
              targetMatch)
          ) {
            if (!uniqueItems.has(item)) {
              filteredItems.push(item);
              uniqueItems.add(item);
            }
          }
        });
        dispatch(setUserItemsArray(filteredItems));
      } else dispatch(setUserItemsArray(copyUserItemsArray));
    } else dispatch(setUserItemsArray(copyUserItemsArray));
  }, [filterParams]);

  // * Save user wardrobe
  useEffect(() => {
    const getItemsFromWardrobe = async () => {
      try {
        // * Necessary because there's a delay in receiving the updated value from the database when the view has to show the item after changes
        await new Promise((resolve) => setTimeout(resolve, 10));
        let response = await api.get(
          `/updateUserWardrobe/items/${props.userEmail}`
        );
        // * Instead of delaying more time the first time, a second time is necessary, otherwise it will not render after after uploading an image.
        await new Promise((resolve) => setTimeout(resolve, 10));
        response = await api.get(
          `/updateUserWardrobe/items/${props.userEmail}`
        );
        dispatch(setUserItemsArray(response.data.items));
        dispatch(setCopyUserItemsArray(response.data.items));
      } catch (error) {
        console.error("Error getting items:", error);
      }
    };
    getItemsFromWardrobe();
  }, [props.userEmail, addedItem, mainImageRenderView]);
  // * For creating a display of clothing items in the user's wardrobe
  const [displayBoxes, setDisplayBoxes] = useState(null);

  useEffect(() => {
    async function renderDisplayBoxes() {
      const boxes = [];
      const itemsPerRow = 4;
      const numRows = Math.ceil(userItemsArray.length / itemsPerRow);
      let X = 0;

      for (let i = 0; i < numRows; i++) {
        const row = [];
        let Number = 0;
        for (let j = i * itemsPerRow; j < userItemsArray.length; j++) {
          let imagePath;
          let fileName;
          try {
            imagePath = userItemsArray[X].lookAlike.mainImage
              ? userItemsArray[X].lookAlike.filePath
              : userItemsArray[X].path;
            fileName = userItemsArray[X].name;
          } catch (error) {
            console.error("Error fetching image path:", error);
          }
          row.push(
            <div
              key={userItemsArray[X]._id}
              style={{ maxWidth: "25%" }}
              className="d-flex flex-row"
            >
              <Button
                variant="outline-danger"
                style={{
                  position: "absolute",
                  fontSize: "50%",
                  marginTop: "2px",
                  width: "0.5rem",
                  zIndex: "1",
                }}
                className="responsiveDisplayNone"
                onClick={() => handleShow(imagePath)}
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>
              <DisplayBox
                image={
                  imagePath.includes("media")
                    ? `${apiUrl}/images/${
                        imagePath.split("media/")[1].split(".")[0]
                      }.0.jpg`
                    : imagePath
                }
                hover="img2"
                handleShowForLookAlike={() =>
                  handleShowForLookAlike(imagePath, fileName)
                }
              />
            </div>
          );
          X++;
          Number++;
          if (Number === itemsPerRow) {
            break;
          }
        }

        if (Number !== itemsPerRow) {
          for (let k = 0; k < itemsPerRow - Number; k++) {
            row.push(
              <div className="empty-placeholder" key={`empty-${i}-${k}`}></div>
            );
          }
        }

        boxes.push(
          <div className="d-flex flex-row" key={`row-${i}`}>
            {row}
          </div>
        );
      }

      if (userItemsArray.length < 1) {
        setDisplayBoxes(
          <div>
            There are no items to display. Please add items to your virtual
            wardrobe
          </div>
        );
      } else {
        setDisplayBoxes(boxes);
      }
    }

    renderDisplayBoxes();
  }, [userItemsArray, mainImageRenderView]);

  // For a valid image alert to upload
  function checkPromiseState(promise) {
    return promise.then(() => "fulfilled").catch(() => "rejected");
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!fileInput) {
      if (mainImage === mainImage2) {
        alert("No changes were made");
        return;
      }
    }
    if (fileInput) {
      if (itemToHandle.includes(`/uploads/`)) {
        const X = async () => {
          switch (mainImage) {
            case true:
              if (mainImage !== mainImage2) {
                try {
                  await api.delete(
                    `updateUserWardrobe/deleteLookalike/${props.userEmail}/${fileName}`
                  );
                  dispatch(setMainImageRenderView(mainImageRenderView + 4));
                } catch (error) {
                  console.log("Error fetching file path:", error);
                }
              } else
                try {
                  await api.delete(
                    `updateUserWardrobe/deleteLookalike/${props.userEmail}/${fileName}`
                  );
                  // * To return the value of mainImage that was before the Look Alike item was changed (which is reset when the item is changed)
                  api.post(
                    `updateUserWardrobe/lookalikemainimage/${props.userEmail}/${fileName}`
                  );
                  dispatch(setMainImageRenderView(mainImageRenderView + 3));
                } catch (error) {
                  console.log("Error fetching file path:", error);
                }
              break;

            case false:
              try {
                await api.delete(
                  `updateUserWardrobe/deleteLookalike/${props.userEmail}/${fileName}`
                );
                dispatch(setMainImageRenderView(mainImageRenderView + 2));
              } catch (error) {
                console.log("Error fetching file path:", error);
              }
              break;
          }
        };
        X();
      }
      const formData = new FormData();
      formData.append("file", fileInput);
      const up = api.post(
        `updateUserWardrobe/upload/${props.userEmail}/${fileName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      checkPromiseState(up).then((state) => {
        if (state === "rejected") {
          alert("Please choose an image type file (up to 5MB)");
        }
      });
    }
    if (mainImage !== mainImage2) {
      api.post(
        `updateUserWardrobe/lookalikemainimage/${props.userEmail}/${fileName}`
      );
      setMainImage2(mainImage);
    }
    dispatch(setMainImageRenderView(mainImageRenderView + 1));
    handleCloseForLookAlike();
  }

  return (
    <div className="d-flex">
      <div>
        {/* // * Popup for deleting an item from the wardrobe */}
        <Modal
          show={showTrashcan}
          onHide={handleClose}
          dialogClassName="modalResponsive"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete the item from your wardrobe?</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "contents" }}>
            <img
              alt="Item-to-delete"
              style={{ alignSelf: "center", width: "95%" }}
              src={
                itemToHandle.includes("media")
                  ? `${apiUrl}/images/${
                      itemToHandle.split("media/")[1].split(".")[0]
                    }.0.jpg`
                  : itemToHandle
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDeleteItemFromWardrobe(itemToHandle)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* // * popup for look-alike item */}
        <Modal
          show={showLookAlike}
          onHide={handleCloseForLookAlike}
          dialogClassName="modalResponsive"
        >
          <Modal.Header closeButton>
            <Modal.Title>Associated item image</Modal.Title>
            <div>
              <br />
              <h6
                style={{
                  position: "absolute",
                  left: "0",
                  fontSize: "0.75rem",
                  padding: "5px",
                  paddingLeft: "17px",
                }}
                className="text-muted"
              >
                Assigning a look-alike image to an item
              </h6>
            </div>
          </Modal.Header>
          <Modal.Body style={{ display: "contents" }}>
            <div style={{ display: "contents" }}>
              <Button
                variant={
                  itemToHandle.includes("uploads")
                    ? "outline-danger"
                    : "outline-secondary"
                }
                style={{
                  fontSize: "50%",
                  margin: "15px 0px 0px 15px",
                  width: "0.5rem",
                }}
                onClick={
                  itemToHandle.includes("uploads")
                    ? handleShowForTrashcanLookAlike
                    : () => false
                }
              >
                <i
                  className="fa-solid fa-trash-can"
                  style={{
                    fontSize: "1rem",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></i>
              </Button>{" "}
              <img
                alt="Lookalike"
                style={{ alignSelf: "center", width: "76%" }}
                src={file ? file : itemToHandle}
              />
              <Form.Group controlId="formFile" className="mb-3">
                <div style={{ padding: "0 36px" }}>
                  <Form.Label>
                    Choose a look-alike image for this clothing item
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleUpload}
                  />
                  {itemToHandle.includes("uploads") || checkboxVar ? (
                    <label style={{ marginTop: "0.5rem" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={mainImage}
                        onChange={() => handleMainImageChange()}
                      />
                      &nbsp; Use look-alike image as main image
                    </label>
                  ) : (
                    <label style={{ marginTop: "0.5rem", color: "gainsboro" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled={true}
                        checked={false}
                      />
                      &nbsp; Use look-alike image as main image
                    </label>
                  )}
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseForLookAlike}>
              Cancel
            </Button>
            <Button variant="success" onClick={handleSubmit}>
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* // * popup for deleting a look-alike item */}
        <Modal
          show={showTrashcanLookAlike}
          onHide={handleCloseForTrashcanLookAlike}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete associated image?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseForTrashcanLookAlike}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteLookAlikeFile}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {displayBoxes ? displayBoxes : "Loading..."}
      </div>
    </div>
  );
}

export default Wardrobe;
