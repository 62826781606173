import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import api from "../../api/api";

export const loginAction = createAsyncThunk(
  "users/loginAction",
  async (formInfo, { rejectWithValue }) => {
    try {
      const response = await api.post("/user/login", formInfo, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = response.data;
      if (response.status !== 200) {
        return rejectWithValue(data);
      }
      const exp = formInfo.email === "demo@kkaada.com" ? undefined : 365;

      Cookies.set("jwt", data.token, { expires: exp, secure: true });

      return data;
    } catch (error) {
      return rejectWithValue({ error: error.message, token: null });
    }
  }
);

export default loginAction;
