import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  updateTargetValueChecked,
  updateColorValueChecked,
  updateTargetValueCheckedStore,
  updateColorValueCheckedStore,
  updateTargetValueCheckedAdmin,
  updateColorValueCheckedAdmin,
  convertTargetValuesToChecked,
  convertTargetValuesToCheckedStore,
  convertTargetValuesToCheckedAdmin,
  convertColorValuesToChecked,
  convertColorValuesToCheckedStore,
  convertColorValuesToCheckedAdmin,
  resetTargetValuesChecked,
  resetColorValuesChecked,
  resetTargetValuesCheckedStore,
  resetColorValuesCheckedStore,
  resetTargetValuesCheckedAdmin,
  resetColorValuesCheckedAdmin,
  setUsedByStore,
  setUsedByUser,
  setUsedByAdmin,
} from "./filterSlice";
import {
  setShowFilter,
  setShowFilterUserAddingItemToTheirWardrobe,
  setShowAddNewItem,
} from "../header/PopupButton/popupButtonSlice";
import { setFilterParams } from "../FilterContext/filterContextSlice";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";

const initialValuesForItemTarget = [
  { name: "upperBody", value: false, title: "Upper body" },
  { name: "lowerBody", value: false, title: "Lower body" },
  { name: "wholeBody", value: false, title: "Whole body" },
  { name: "shoes", value: false, title: "Shoes" },
  { name: "accessories", value: false, title: "Accessories" },
];

const initialValuesForItemColor = [
  { name: "black", value: false, title: "Black" },
  { name: "white", value: false, title: "White" },
  { name: "red", value: false, title: "Red" },
  { name: "blue", value: false, title: "Blue" },
  { name: "green", value: false, title: "Green" },
  { name: "yellow", value: false, title: "Yellow" },
  { name: "gray", value: false, title: "Gray" },
  { name: "beige", value: false, title: "Beige" },
  { name: "pink", value: false, title: "Pink" },
  { name: "purple", value: false, title: "Purple" },
  { name: "azure", value: false, title: "Azure" },
  { name: "turquoise", value: false, title: "Turquoise" },
  { name: "orange", value: false, title: "Orange" },
  { name: "brown", value: false, title: "Brown" },
  { name: "gold", value: false, title: "Gold" },
  { name: "silver", value: false, title: "Silver" },
  { name: "nude", value: false, title: "Nude" },
  { name: "transparent", value: false, title: "Transparent" },
];

let jsonString = "";

function Filter(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(setUsedByStore(JSON.parse(Cookies.get("storeFilter"))));
    } catch (err) {
      dispatch(setUsedByStore(false));
    }
    try {
      dispatch(setUsedByUser(JSON.parse(Cookies.get("userFilter"))));
    } catch (err) {
      dispatch(setUsedByUser(false));
    }
    try {
      dispatch(setUsedByAdmin(JSON.parse(Cookies.get("adminFilter"))));
    } catch (err) {
      dispatch(setUsedByAdmin(false));
    }
  }, []);

  const targetValuesChecked = useSelector(
    (state) => state.filter.targetValuesChecked
  );
  const colorValuesChecked = useSelector(
    (state) => state.filter.colorValuesChecked
  );
  const targetValuesCheckedStore = useSelector(
    (state) => state.filter.targetValuesCheckedStore
  );
  const colorValuesCheckedStore = useSelector(
    (state) => state.filter.colorValuesCheckedStore
  );
  const targetValuesCheckedAdmin = useSelector(
    (state) => state.filter.targetValuesCheckedAdmin
  );
  const colorValuesCheckedAdmin = useSelector(
    (state) => state.filter.colorValuesCheckedAdmin
  );

  const handleTargetInputChange = (e) => {
    const { name, checked } = e.target;
    if (props?.usedBy === "userWardrobe") {
      dispatch(updateTargetValueChecked({ name, checked }));
    }
    if (props?.usedBy === "adminWardrobe") {
      dispatch(updateTargetValueCheckedAdmin({ name, checked }));
    }
    if (props?.usedBy === "addFromStore") {
      dispatch(updateTargetValueCheckedStore({ name, checked }));
    }
  };

  const handleColorInputChange = (e) => {
    const { name, checked } = e.target;
    if (props?.usedBy === "userWardrobe") {
      dispatch(updateColorValueChecked({ name, checked }));
    }
    if (props?.usedBy === "addFromStore") {
      dispatch(updateColorValueCheckedStore({ name, checked }));
    }
    if (props?.usedBy === "adminWardrobe") {
      dispatch(updateColorValueCheckedAdmin({ name, checked }));
    }
  };

  let targetMap = false;
  let colorMap = false;
  switch (props.usedBy) {
    case "userWardrobe":
      targetMap = initialValuesForItemTarget.map((item) => (
        <div key={"target:" + item.title} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={
              targetValuesChecked.find((obj) => obj.name === item.name).value
            }
            name={item.name}
            id={item.name}
            onChange={handleTargetInputChange}
          />
          <label className="form-check-label" htmlFor={item.name}>
            {item.title}
          </label>
        </div>
      ));
      colorMap = initialValuesForItemColor.map((item, index) => (
        <div key={"color:" + item.title} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={
              colorValuesChecked.find((obj) => obj.name === item.name).value
            }
            name={item.name}
            // * בגלל ששמות צבעים הן מילים שמורות, יש הוספה של מספר ל- ID
            id={item.name + index}
            onChange={handleColorInputChange}
          />
          <label className="form-check-label" htmlFor={item.name + index}>
            {item.title}
          </label>
        </div>
      ));
      break;
    case "addFromStore":
      targetMap = initialValuesForItemTarget.map((item) => (
        <div key={"target:" + item.title} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={
              targetValuesCheckedStore.find((obj) => obj.name === item.name)
                .value
            }
            name={item.name}
            id={item.name + "addFromStore"}
            onChange={handleTargetInputChange}
          />
          <label
            className="form-check-label"
            htmlFor={item.name + "addFromStore"}
          >
            {item.title}
          </label>
        </div>
      ));
      colorMap = initialValuesForItemColor.map((item, index) => (
        <div key={"color:" + item.title} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={
              colorValuesCheckedStore.find((obj) => obj.name === item.name)
                .value
            }
            name={item.name}
            // * בגלל ששמות צבעים הן מילים שמורות, יש הוספה של מספר ל- ID
            id={item.name + index + "addFromStore"}
            onChange={handleColorInputChange}
          />
          <label
            className="form-check-label"
            htmlFor={item.name + index + "addFromStore"}
          >
            {item.title}
          </label>
        </div>
      ));
      break;
    case "adminWardrobe":
      targetMap = initialValuesForItemTarget.map((item) => (
        <div key={"target:" + item.title} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={
              targetValuesCheckedAdmin.find((obj) => obj.name === item.name)
                .value
            }
            name={item.name}
            id={item.name + "adminWardrobe"}
            onChange={handleTargetInputChange}
          />
          <label
            className="form-check-label"
            htmlFor={item.name + "adminWardrobe"}
          >
            {item.title}
          </label>
        </div>
      ));
      colorMap = initialValuesForItemColor.map((item, index) => (
        <div key={"color:" + item.title} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={
              colorValuesCheckedAdmin.find((obj) => obj.name === item.name)
                .value
            }
            name={item.name}
            // * בגלל ששמות צבעים הן מילים שמורות, יש הוספה של מספר ל- ID
            id={item.name + index + "adminWardrobe"}
            onChange={handleColorInputChange}
          />
          <label
            className="form-check-label"
            htmlFor={item.name + index + "adminWardrobe"}
          >
            {item.title}
          </label>
        </div>
      ));
      break;
    default:
      console.log(props.usedBy);
      break;
  }

  const handleResetAll = () => {
    if (props.usedBy === "adminWardrobe") {
      Cookies.remove("adminFilter");
      dispatch(setUsedByAdmin(false));
      dispatch(resetTargetValuesCheckedAdmin());
      dispatch(resetColorValuesCheckedAdmin());
      dispatch(convertTargetValuesToCheckedAdmin());
      dispatch(convertColorValuesToCheckedAdmin());
    }
    if (props.usedBy === "userWardrobe") {
      Cookies.remove("userFilter");
      dispatch(setUsedByUser(false));
      dispatch(resetTargetValuesChecked());
      dispatch(resetColorValuesChecked());
      dispatch(convertTargetValuesToChecked());
      dispatch(convertColorValuesToChecked());
    }
    if (props.usedBy === "addFromStore") {
      Cookies.remove("storeFilter");
      dispatch(setUsedByStore(false));
      dispatch(resetTargetValuesCheckedStore());
      dispatch(resetColorValuesCheckedStore());
      dispatch(convertTargetValuesToCheckedStore());
      dispatch(convertColorValuesToCheckedStore());
    }
    dispatch(setFilterParams());
  };

  function handleSubmit(event) {
    event.preventDefault();
    let newTargetList = [];
    let cookieTargetList = [];
    let newColorList = [];
    if (props.usedBy === "adminWardrobe") {
      dispatch(convertTargetValuesToCheckedAdmin());
      dispatch(convertColorValuesToCheckedAdmin());
      targetValuesCheckedAdmin.forEach((target) => {
        if (target.value) {
          switch (target.name) {
            case "upperBody":
              newTargetList.push("'upper_body'");
              cookieTargetList.push(target.name);
              break;
            case "lowerBody":
              newTargetList.push("'lower_body'");
              cookieTargetList.push(target.name);
              break;
            case "wholeBody":
              newTargetList.push("'whole_body'");
              cookieTargetList.push(target.name);
              break;
            case "shoes":
              newTargetList.push("'shoes'");
              cookieTargetList.push(target.name);
              break;
            case "accessories":
              newTargetList.push("'accessories'");
              cookieTargetList.push(target.name);
              break;
            default:
              console.log(target.name);
              break;
          }
        }
      });
      colorValuesCheckedAdmin.forEach((color) => {
        if (color.value) {
          newColorList.push(color.name);
        }
      });
      const formData = {
        targets: newTargetList,
        colors: newColorList,
        targetFilter: cookieTargetList,
      };
      jsonString = JSON.stringify(formData);
      Cookies.set("adminFilter", jsonString);
      if (formData.targets.length < 1 && formData.colors.length < 1) {
        Cookies.remove("adminFilter");
      }
      try {
        dispatch(setUsedByAdmin(Cookies.get("adminFilter")));
      } catch (err) {
        dispatch(setUsedByAdmin(false));
      }
      dispatch(setFilterParams(jsonString));
      dispatch(setShowAddNewItem(false));
    }
    if (props.usedBy === "userWardrobe") {
      dispatch(convertTargetValuesToChecked());
      dispatch(convertColorValuesToChecked());
      targetValuesChecked.forEach((target) => {
        if (target.value) {
          switch (target.name) {
            case "upperBody":
              newTargetList.push("'upper_body'");
              cookieTargetList.push(target.name);
              break;
            case "lowerBody":
              newTargetList.push("'lower_body'");
              cookieTargetList.push(target.name);
              break;
            case "wholeBody":
              newTargetList.push("'whole_body'");
              cookieTargetList.push(target.name);
              break;
            case "shoes":
              newTargetList.push("'shoes'");
              cookieTargetList.push(target.name);
              break;
            case "accessories":
              newTargetList.push("'accessories'");
              cookieTargetList.push(target.name);
              break;
            default:
              console.log(target.name);
              break;
          }
        }
      });
      colorValuesChecked.forEach((color) => {
        if (color.value) {
          newColorList.push(color.name);
        }
      });
      const formData = {
        targets: newTargetList,
        colors: newColorList,
        targetFilter: cookieTargetList,
      };
      jsonString = JSON.stringify(formData);
      Cookies.set("userFilter", jsonString);
      if (formData.targets.length < 1 && formData.colors.length < 1) {
        Cookies.remove("userFilter");
      }
      try {
        dispatch(setUsedByUser(Cookies.get("userFilter")));
      } catch (err) {
        dispatch(setUsedByUser(false));
      }
      dispatch(setFilterParams(jsonString));
      dispatch(setShowFilter(false));
    }
    if (props.usedBy === "addFromStore") {
      dispatch(convertTargetValuesToCheckedStore());
      dispatch(convertColorValuesToCheckedStore());
      targetValuesCheckedStore.forEach((target) => {
        if (target.value) {
          switch (target.name) {
            case "upperBody":
              newTargetList.push("'upper_body'");
              cookieTargetList.push(target.name);
              break;
            case "lowerBody":
              newTargetList.push("'lower_body'");
              cookieTargetList.push(target.name);
              break;
            case "wholeBody":
              newTargetList.push("'whole_body'");
              cookieTargetList.push(target.name);
              break;
            case "shoes":
              newTargetList.push("'shoes'");
              cookieTargetList.push(target.name);
              break;
            case "accessories":
              newTargetList.push("'accessories'");
              cookieTargetList.push(target.name);
              break;
            default:
              console.log(target.name);
              break;
          }
        }
      });
      colorValuesCheckedStore.forEach((color) => {
        if (color.value) {
          newColorList.push(color.name);
        }
      });
      const formData = {
        targets: newTargetList,
        colors: newColorList,
        targetFilter: cookieTargetList,
      };
      jsonString = JSON.stringify(formData);
      Cookies.set("storeFilter", jsonString);
      if (formData.targets.length < 1 && formData.colors.length < 1) {
        Cookies.remove("storeFilter");
      }
      try {
        dispatch(setUsedByStore(Cookies.get("storeFilter")));
      } catch (err) {
        dispatch(setUsedByStore(false));
      }
      dispatch(setFilterParams(jsonString));
      dispatch(setShowFilterUserAddingItemToTheirWardrobe(false));
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <h6>Item target</h6>
      {targetMap} <br />
      <hr />
      <br />
      <h6>Item color</h6>
      {colorMap}
      <br />
      <hr />
      <br />
      <Button variant="secondary" onClick={handleResetAll}>
        Reset all
      </Button>{" "}
      <Button variant="success" type="submit">
        Submit
      </Button>{" "}
    </form>
  );
}
export default Filter;
