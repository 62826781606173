import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setPassword,
  setRePassword,
  setErrorMessage,
  resetErrorMessage,
  fetchUsers,
} from "./signupSlice";
import {
  setShowLogin,
  setSignup,
} from "../header/LoginButton/loginButtonSlice";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import api from "../../api/api";

let emailCheck = false;

function Signup() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const usersList = useSelector((state) => state.signup.usersList);
  const email = useSelector((state) => state.signup.email);
  const password = useSelector((state) => state.signup.password);
  const rePassword = useSelector((state) => state.signup.rePassword);
  const errorMessage = useSelector((state) => state.signup.errorMessage);
  const handleShowLogin = () => {
    dispatch(setSignup(false));
    dispatch(setShowLogin(true));
  };

  const isPasswordValid = { valid: false };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // * Email validation with regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      dispatch(setErrorMessage("Please enter a valid email address."));
      return;
    }

    // * Remove spaces and dots and "+" sigen (if "gamil.com" after "@") before the "@" sign
    const emailParts = email.split("@");
    let username = "";
    if (emailParts[1] === "gmail.com") {
      const usernameParts = emailParts[0].split("+");
      username = usernameParts[0].replace(/\s/g, "").replace(/\./g, "");
    } else {
      username = emailParts[0].replace(/\s/g, "");
    }

    // * Lowercase the email address
    const formattedEmail = username + "@" + emailParts[1];
    const formattedEmailLowerCase = formattedEmail.toLowerCase();

    // * Check if email already exists in the system
    const existingUser = usersList.find(
      (user) => user.email === formattedEmailLowerCase
    );
    if (existingUser) {
      dispatch(setErrorMessage("This email address is already registered."));
      emailCheck = false;
      return;
    } else {
      emailCheck = true;
    }

    // * Check if password is valid
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
    if (!passwordRegex.test(password)) {
      dispatch(
        setErrorMessage(
          "Password must contain at least one letter, one symbol, and be at least 6 characters long."
        )
      );
      isPasswordValid.valid = false;
      return;
    }

    // * Check if passwords match
    if (password !== rePassword) {
      dispatch(setErrorMessage("Passwords don't match."));
      isPasswordValid.valid = false;
      return;
    } else isPasswordValid.valid = true;

    // * Register new user
    if (isPasswordValid.valid && emailCheck) {
      const newUser = {
        activated: true,
        // * Must be replaced when using a production version
        // activated: false,
        email: formattedEmailLowerCase,
        id: Date.now(),
        password: password,
        account: "free",
        appearance: {
          accurate: { height: "", bust: "", waist: "", hips: "", skin: "" },
          estimated: { height: "", skin: "", shape: "", dimension: "" },
        },
        preferences: [
          { id: "1", no: "1", checked: false, value: "casual" },
          { id: "2", no: "1", checked: false, value: "elegant" },
          { id: "3", no: "1", checked: false, value: "hipster" },
          { id: "4", no: "2", checked: false, value: "casual" },
          { id: "5", no: "2", checked: false, value: "hipster" },
          { id: "6", no: "1", checked: false, value: "rock" },
          { id: "7", no: "2", checked: false, value: "elegant" },
          { id: "8", no: "1", checked: false, value: "sporty" },
          { id: "9", no: "3", checked: false, value: "casual" },
          { id: "10", no: "3", checked: false, value: "elegant" },
          { id: "11", no: "4", checked: false, value: "casual" },
          { id: "12", no: "2", checked: false, value: "rock" },
          { id: "13", no: "4", checked: false, value: "elegant" },
          { id: "14", no: "3", checked: false, value: "rock" },
          { id: "15", no: "2", checked: false, value: "sporty" },
          { id: "16", no: "5", checked: false, value: "elegant" },
          { id: "17", no: "3", checked: false, value: "hipster" },
          { id: "18", no: "5", checked: false, value: "casual" },
          { id: "19", no: "4", checked: false, value: "rock" },
          { id: "20", no: "3", checked: false, value: "sporty" },
          { id: "21", no: "4", checked: false, value: "hipster" },
          { id: "22", no: "4", checked: false, value: "sporty" },
          { id: "23", no: "5", checked: false, value: "hipster" },
          { id: "24", no: "5", checked: false, value: "sporty" },
          { id: "25", no: "5", checked: false, value: "rock" },
        ],
        admin: false,
      };

      try {
        await api.post("/user/register", newUser);
      } catch (error) {
        console.error("Failed to add new user:", error);
      }

      // * Clear form and error message
      dispatch(setEmail(""));
      dispatch(setPassword(""));
      dispatch(setRePassword(""));
      dispatch(resetErrorMessage());
      handleShowLogin();
      // * Must be updated when using a production version
      // alert(
      //   "Before logging in for the first time, click on the activation link that was sent to the email address you registered with"
      // );
      return;
    } else {
      alert(
        "One or more of the details you entered is invalid, please try again."
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => dispatch(setEmail(e.target.value))}
        />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => dispatch(setPassword(e.target.value))}
        />
        <Form.Text className="text-muted">
          One letter, one symbol, and at least 6 characters.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formRePassword">
        <Form.Label>Repeat Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={rePassword}
          onChange={(e) => dispatch(setRePassword(e.target.value))}
        />
        {password !== rePassword && (
          <p style={{ color: "red" }}>Passwords don't match.</p>
        )}
      </Form.Group>

      <hr />
      <Button style={{ float: "right" }} variant="success" type="submit">
        Submit
      </Button>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </Form>
  );
}

export default Signup;
