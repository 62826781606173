import React, { useState, useEffect, useRef } from "react";
import "./displayBox.css";

function DisplayBox({
  classs,
  color,
  text,
  image,
  hover,
  handleShowForLookAlike,
  notifyImageLoaded,
  isItemNew,
}) {
  const [loading, setLoading] = useState(true);
  const imageRef = useRef(null);
  const loadedSrc = useRef("");

  useEffect(() => {
    if (imageRef.current) {
      if (
        imageRef.current.complete &&
        imageRef.current.src === loadedSrc.current
      ) {
        setLoading(false);
        if (notifyImageLoaded && !isItemNew) {
          notifyImageLoaded();
        } // To cause an update even if the new outfit has the same items from the previous outfit
      } else {
        setLoading(true);
      }
    }
  }, [image, notifyImageLoaded]);

  const handleImageLoad = () => {
    loadedSrc.current = imageRef.current.src;
    setLoading(false);
    if (notifyImageLoaded) {
      notifyImageLoaded();
    } // Notify parent when loaded
  };

  const handleImageError = () => {
    loadedSrc.current = imageRef.current.src;
    setLoading(false);
  };

  return (
    <div className={`${classs} col text-center mx-auto position-relative`}>
      <h5 style={{ color: color, position: "absolute" }}>{text}</h5>
      {loading && (
        <div
          className="spinner-container"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="spinner">
            <div className="spinner">
              <div className="spinner">
                <div className="spinner">
                  <div className="spinner">
                    <div className="spinner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <img
        ref={imageRef}
        alt="Item image"
        src={image}
        className={`img ${hover}`}
        onClick={handleShowForLookAlike}
        onLoad={handleImageLoad}
        onError={handleImageError}
        style={{ visibility: loading ? "hidden" : "visible" }}
      />
    </div>
  );
}

export default DisplayBox;
