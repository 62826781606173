import { useSelector, useDispatch } from "react-redux";
import {
  setCheckedList,
  setStylesList,
  setSeasonsList,
  setCheckedListCopy,
  setStylesListCopy,
  setSeasonsListCopy,
  resetCheckedList,
  resetStylesList,
  resetSeasonsList,
  resetCheckedListCopy,
  resetStylesListCopy,
  resetSeasonsListCopy,
  setErrorMessage,
  setSeasons,
  setStyles,
} from "./filter2Slice";
import { setShowFilter } from "../header/LoginButton/loginButtonSlice";
import Button from "react-bootstrap/Button";

const seasons = [
  { id: "summer", name: "Summer", checked: false },
  { id: "spring", name: "Spring", checked: false },
  { id: "autumn", name: "Autumn", checked: false },
  { id: "winter", name: "Winter", checked: false },
];

function styleToggle(styles, id) {
  return styles.map((style) =>
    style.id === id ? { ...style, checked: !style.checked } : style
  );
}
function seasonsToggle(seasons, id) {
  return seasons.map((season) =>
    season.id === id ? { ...season, checked: !season.checked } : season
  );
}
function seasonsToggle2(seasons, id) {
  return seasons.map((season) =>
    id.includes(season.id)
      ? { ...season, checked: true }
      : { ...season, checked: false }
  );
}

function toggleEvent(events, id, checked) {
  return events.map((event) =>
    event.id === id ? { ...event, checked } : { ...event, checked: false }
  );
}

function Filter() {
  const errorMessage = useSelector((state) => state.filter2.errorMessage);
  const sqlSeason = useSelector((state) => state.displayContainer.sqlSeason);
  const checkedList = useSelector((state) => state.filter2.checkedList);
  const stylesList = useSelector((state) => state.filter2.stylesList);
  const seasonsList = useSelector((state) => state.filter2.seasonsList);
  const dispatch = useDispatch();

  const handleEventChange = (id, checked) => {
    dispatch(setCheckedList(toggleEvent(checkedList, id, checked)));

    if (id === "workSchool" && checked) {
      dispatch(
        setStylesList(
          stylesList.map((style) =>
            ["casual", "hipster", "rock"].includes(style.id)
              ? { ...style, checked: true }
              : { ...style, checked: false }
          )
        )
      );
    } else if (id === "ceremonial" && checked) {
      dispatch(
        setStylesList(
          stylesList.map((style) =>
            ["elegant", "hipster", "rock"].includes(style.id)
              ? { ...style, checked: true }
              : { ...style, checked: false }
          )
        )
      );
    } else if (id === "out" && checked) {
      dispatch(
        setStylesList(
          stylesList.map((style) =>
            ["casual", "hipster", "rock"].includes(style.id)
              ? { ...style, checked: true }
              : { ...style, checked: false }
          )
        )
      );
    }
    dispatch(setSeasonsList(seasonsToggle2(seasons, sqlSeason)));
  };

  const handleStyleChange = (id) => {
    dispatch(setStylesList(styleToggle(stylesList, id)));
  };

  const handleSeasonsChange = (id) => {
    dispatch(setSeasonsList(seasonsToggle(seasonsList, id)));
  };

  const handleReset = () => {
    dispatch(setSeasons(""));
    dispatch(setStyles(""));
    dispatch(resetCheckedList());
    dispatch(resetStylesList());
    dispatch(resetSeasonsList());
    dispatch(resetCheckedListCopy());
    dispatch(resetSeasonsListCopy());
    dispatch(resetStylesListCopy());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let newStylesList = [];
    stylesList.forEach((style) => {
      if (style.checked) {
        newStylesList.push(style.id);
      }
    });
    switch (newStylesList.length) {
      case 1:
        newStylesList = [
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
        ];
        break;
      case 2:
        newStylesList = [
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
          ...newStylesList,
        ];
        break;
      case 3:
        newStylesList = [...newStylesList, ...newStylesList, ...newStylesList];
        break;
      case 4:
        newStylesList = [...newStylesList, ...newStylesList, ...newStylesList];
        break;
      case 5:
        newStylesList = [...newStylesList, ...newStylesList];
        break;
    }
    let newSeasonsList = [];
    seasonsList.forEach((season) => {
      if (season.checked) {
        newSeasonsList.push(season.id);
      }
    });
    if (newStylesList.length < 1 || newSeasonsList.length < 1) {
      if (newStylesList.length < 1 && newSeasonsList.length < 1) {
        dispatch(setErrorMessage("Please select styles and seasons"));
      } else if (newSeasonsList.length < 1) {
        dispatch(setErrorMessage("Please select seasons"));
      } else if (newStylesList.length < 1) {
        dispatch(setErrorMessage("Please select styles"));
      }
    } else {
      dispatch(setSeasons(newSeasonsList));
      dispatch(setStyles(newStylesList));
      dispatch(setErrorMessage(""));
      dispatch(setCheckedListCopy());
      dispatch(setStylesListCopy());
      dispatch(setSeasonsListCopy());
      dispatch(setShowFilter(false));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h6>Events</h6>
      {checkedList.map(({ id, name, checked }) => (
        <div className="form-check" key={id}>
          <label>
            <input
              className="form-check-input"
              type="checkbox"
              checked={checked}
              onChange={(e) => handleEventChange(id, e.target.checked)}
            />
            {name}
          </label>
        </div>
      ))}{" "}
      <br />
      <hr />
      <br />
      <h6>Styles</h6>
      {stylesList.map(({ id, name, checked }) => (
        <div className="form-check" key={id}>
          <label>
            <input
              className="form-check-input"
              type="checkbox"
              checked={checked}
              onChange={() => handleStyleChange(id)}
            />
            {name}
          </label>
        </div>
      ))}
      <br />
      <hr />
      <br />
      <h6>Seasons</h6>
      {seasonsList.map(({ id, name, checked }) => (
        <div className="form-check" key={id}>
          <label>
            <input
              className="form-check-input"
              type="checkbox"
              checked={checked}
              onChange={() => handleSeasonsChange(id)}
            />
            {name}
          </label>
        </div>
      ))}
      <br />
      <hr />
      {!errorMessage ? <br /> : ""}
      <p style={{ color: "red" }}>{errorMessage}</p>
      <Button variant="secondary" onClick={handleReset}>
        Reset all
      </Button>{" "}
      <Button variant="success" type="submit">
        Submit
      </Button>{" "}
    </form>
  );
}
export default Filter;
