import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchItemsJson = createAsyncThunk(
  "items/fetchItemsJson",
  async () => {
    const response = await api.get("/items-json");
    return response.data;
  }
);

const initialState = {
  allItemsStoreString: "",
  status: "idle",
  error: null,
  showLookAlike: false,
  showTrashcanLookAlike: false,
  file: "",
  logedin: "",
  sqlUser: "",
  sqlSeason: ["winter", "summer", "autumn", "spring"],
  sqlStyle: "",
  fetchUser: null,
  itemNames: "",
  copyItemNames: "",
  totalItems: "",
  sessionVar: false,
  mainImage3: "",
  displayList: [
    {
      scenario: ["acc1"],
      result: {
        1: ["heightBox800", "acc1"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2"],
      result: {
        1: ["heightBox400", "acc1"],
        2: ["heightBox400", "acc2"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "acc1"],
        2: ["heightBox400", "acc2"],
        3: ["heightBox400", "acc3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "acc1"],
        2: ["heightBox400", "acc2"],
        3: ["heightBox400", "acc3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc4"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "acc1"],
        2: ["heightBox400", "acc2"],
        3: ["heightBox400", "acc3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc4"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc5"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox400", "acc1"],
        2: ["heightBox400", "acc2"],
        3: ["heightBox400", "acc3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc4"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc5"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc6"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes"],
      result: {
        1: ["heightBox800", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1"],
      result: {
        1: ["heightBox800", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox800", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "none"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["lower"],
      result: {
        1: ["heightBox800", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1"],
      result: {
        1: ["heightBox800", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox800", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["heightBox400", "shoes"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "lower"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1"],
      result: {
        1: ["heightBox800", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1"],
      result: {
        1: ["heightBox800", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox800", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "lower"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "none"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "whole"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "shoes"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc3"],
        14: ["heightBox200", "acc4"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["heightBox200", "acc3"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["heightBox200", "acc2"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "lower"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "shoes"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "top3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top2"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "shoes"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "shoes"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2acc4"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["heightBox200", "acc2"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top2"],
      result: {
        1: ["heightBox800", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1"],
      result: {
        1: ["heightBox800", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox800", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top2", "lower"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "none"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "top3"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3", "whole"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "shoes"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc3"],
        14: ["heightBox200", "acc4"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["heightBox200", "acc3"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["heightBox200", "acc2"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3", "lower"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "acc1"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "shoes"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top2"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top3"],
      result: {
        1: ["heightBox800", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1"],
      result: {
        1: ["heightBox800", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox800", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "shoes"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top3", "lower"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "none"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top3"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top3"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "top3"],
        3: ["heightBox400", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "shoes"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3", "whole"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "shoes"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc3"],
        14: ["heightBox200", "acc4"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["heightBox200", "acc3"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox800", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox400", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["heightBox200", "acc2"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3", "lower"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "shoes"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox400", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox400", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["heightBox200", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "top1"],
        2: ["heightBox400", "lower"],
        3: ["heightBox400", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox400", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox400", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox400", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["heightBox200", "acc4"],
        13: ["heightBox200", "acc5"],
        14: ["heightBox200", "acc6"],
      },
    },
  ],
  displayList2: [
    {
      scenario: ["acc1"],
      result: {
        1: ["heightBox400", "acc1"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2"],
      result: {
        1: ["heightBox200", "acc1"],
        2: ["heightBox200", "acc2"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "acc1"],
        2: ["heightBox200", "acc2"],
        3: ["heightBox200", "acc3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "acc1"],
        2: ["heightBox200", "acc2"],
        3: ["heightBox200", "acc3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc4"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "acc1"],
        2: ["heightBox200", "acc2"],
        3: ["heightBox200", "acc3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc4"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc5"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox200", "acc1"],
        2: ["heightBox200", "acc2"],
        3: ["heightBox200", "acc3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc4"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc5"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc6"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes"],
      result: {
        1: ["heightBox400", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1"],
      result: {
        1: ["heightBox400", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["shoes", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox400", "shoes"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["whole", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["lower"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox400", "lower"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes"],
      result: {
        1: ["heightBox200", "lower"],
        2: ["heightBox200", "shoes"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "lower"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "lower"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "lower"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "lower"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["lower", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "lower"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "lower"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox400", "top1"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "whole", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "lower"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "lower", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "whole"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "shoes"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc3"],
        14: ["heightBox100", "acc4"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc2"],
        12: ["heightBox100", "acc3"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox100", "acc1"],
        10: ["heightBox100", "acc2"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "lower"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "shoes"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "top3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top2"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "shoes"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "shoes"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top2", "top3", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2acc4"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox100", "acc1"],
        10: ["heightBox100", "acc2"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top2"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox400", "top2"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "whole", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top2",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top2", "lower"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "lower", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "top3"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3", "whole"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "shoes"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc3"],
        14: ["heightBox100", "acc4"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc2"],
        12: ["heightBox100", "acc3"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top2"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox100", "acc1"],
        10: ["heightBox100", "acc2"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3", "lower"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "acc1"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "shoes"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top2", "top3", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top2",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top2"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top3"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "acc1", "acc2", "acc3", "acc4", "acc5", "acc6"],
      result: {
        1: ["heightBox400", "top3"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "shoes"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "shoes", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "whole", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top3", "lower"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top3", "lower", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top3"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top3"],
        3: ["displayNoneBox", "none"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "acc1", "acc2", "acc3", "acc4", "acc5"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "top3"],
        3: ["heightBox200", "acc1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc2"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc4"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc5"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc6"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "shoes", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "shoes"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3", "whole"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "shoes"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "shoes", "acc1"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "whole", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc3"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc2"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc3"],
        14: ["heightBox100", "acc4"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc1"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc2"],
        12: ["heightBox100", "acc3"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "whole",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox400", "whole"],
        2: ["displayNoneBox", "none"],
        3: ["heightBox200", "top1"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "top3"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox100", "acc1"],
        10: ["heightBox100", "acc2"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3", "lower"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["displayNoneBox", "none"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "acc1", "acc2", "acc3", "acc4"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc5"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "acc1"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc2"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc3"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc4"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "shoes"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["displayNoneBox", "none"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "shoes", "acc1"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["displayNoneBox", "none"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "shoes", "acc1", "acc2"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["displayNoneBox", "none"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: ["top1", "top3", "lower", "shoes", "acc1", "acc2", "acc3"],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["displayNoneBox", "none"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox200", "acc4"],
        14: ["displayNoneBox", "none"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox200", "acc3"],
        12: ["displayNoneBox", "none"],
        13: ["heightBox100", "acc4"],
        14: ["heightBox100", "acc5"],
      },
    },
    {
      scenario: [
        "top1",
        "top3",
        "lower",
        "shoes",
        "acc1",
        "acc2",
        "acc3",
        "acc4",
        "acc5",
        "acc6",
      ],
      result: {
        1: ["heightBox200", "top1"],
        2: ["heightBox200", "lower"],
        3: ["heightBox200", "top3"],
        4: ["displayNoneBox", "none"],
        5: ["heightBox200", "shoes"],
        6: ["displayNoneBox", "none"],
        7: ["heightBox200", "acc1"],
        8: ["displayNoneBox", "none"],
        9: ["heightBox200", "acc2"],
        10: ["displayNoneBox", "none"],
        11: ["heightBox100", "acc3"],
        12: ["heightBox100", "acc4"],
        13: ["heightBox100", "acc5"],
        14: ["heightBox100", "acc6"],
      },
    },
  ],
  outfitsObjList: [],
  outfitsList: [],
  outfitToShow: "",
  counter: 0,
  itemToShow: 0,
  maxAttempts: false,
  allItemsUser: {
    accessories: [],
    shoes: [],
    whole: [],
    lower: [],
    tops: [],
  },
  done: false,
  imagesLoaded: 0,
  totalImages: 0,
};

const displayContainerSlice = createSlice({
  name: "displayContainer",
  initialState,
  reducers: {
    setAllItemsStoreString: (state, action) => {
      state.allItemsStoreString = action.payload;
    },
    setShowLookAlike: (state, action) => {
      state.showLookAlike = action.payload;
    },
    setShowTrashcanLookAlike: (state, action) => {
      state.showTrashcanLookAlike = action.payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setLogedin: (state, action) => {
      state.logedin = action.payload;
    },
    setSqlUser: (state, action) => {
      state.sqlUser = action.payload;
    },
    setSqlSeason: (state, action) => {
      state.sqlSeason = action.payload;
    },
    setSqlStyle: (state, action) => {
      state.sqlStyle = action.payload;
    },
    setFetchUser: (state, action) => {
      state.fetchUser = action.payload;
    },
    setItemNames: (state, action) => {
      state.itemNames = action.payload;
    },
    setCopyItemNames: (state, action) => {
      state.copyItemNames = action.payload;
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    setSessionVar: (state, action) => {
      state.sessionVar = action.payload;
    },
    setOutfitsObjList: (state, action) => {
      state.outfitsObjList.push(action.payload);
    },
    setOutfitsListPush: (state, action) => {
      state.outfitsList.push(action.payload);
    },
    updatOutfitsList: (state, action) => {
      const { index, value } = action.payload;
      state.outfitsList[index] = value;
    },
    resetOutfitsList: (state) => {
      state.outfitsList = [];
    },
    setOutfitToShow: (state, action) => {
      state.outfitToShow = action.payload;
    },
    setCounter: (state, action) => {
      state.counter = action.payload;
    },
    setItemToShow: (state, action) => {
      state.itemToShow = action.payload;
    },
    setDisplayList: (state, action) => {
      state.displayList = action.payload;
    },
    setDisplayList2: (state, action) => {
      state.displayList2 = action.payload;
    },
    setMaxAttempts: (state, action) => {
      state.maxAttempts = action.payload;
    },
    setAllItemsUser: (state, action) => {
      state.allItemsUser = action.payload;
    },
    setMainImage3: (state, action) => {
      state.mainImage3 = action.payload;
    },
    setDone: (state, action) => {
      state.done = action.payload;
    },
    setImagesLoaded: (state, action) => {
      state.imagesLoaded = action.payload;
    },
    setTotalImages: (state, action) => {
      state.totalImages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchItemsJson.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchItemsJson.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allItemsStoreString = action.payload;
      })
      .addCase(fetchItemsJson.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setAllItemsStoreString,
  setDisplayList,
  setDisplayList2,
  setShowLookAlike,
  setShowTrashcanLookAlike,
  setFile,
  setLogedin,
  setFetchUser,
  setItemNames,
  setCopyItemNames,
  setTotalItems,
  setSessionVar,
  setOutfitsObjList,
  setOutfitsListPush,
  setOutfitToShow,
  resetOutfitsList,
  setCounter,
  setItemToShow,
  setSqlStyle,
  setSqlUser,
  setSqlSeason,
  setMaxAttempts,
  setAllItemsUser,
  updatOutfitsList,
  setMainImage3,
  setDone,
  setImagesLoaded,
  setTotalImages,
} = displayContainerSlice.actions;

export default displayContainerSlice.reducer;
